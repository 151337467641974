import { FC, Ref } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Typography } from 'src/components/common';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';

import './editBusinessModelCompetitors.scss';

import { TIdeaBusinessModelCompetitorsData } from '../../ideaHub.types';
import { TEditIdeaFormValues } from '../IdeaDetails';

export type TEditBusinessModelCompetitorsFormValues = TIdeaBusinessModelCompetitorsData;

export type TEditBusinessModelCompetitorsProps = TIdeaBusinessModelCompetitorsData & {
  submitButtonRef: Ref<FormikProps<TEditIdeaFormValues>>;
  updateFormValues: ({ values }: { values: TEditIdeaFormValues }) => void;
};

const EditBusinessModelCompetitors: FC<TEditBusinessModelCompetitorsProps> = ({
  majorRevenueStream,
  // scaliblityOfIdea,
  competitors,
  targetAudience,
  // keyDifferentiatingFactor,
  submitButtonRef,
  updateFormValues
}: TEditBusinessModelCompetitorsProps) => {
  const initialValues: TEditBusinessModelCompetitorsFormValues = {
    majorRevenueStream,
    // scaliblityOfIdea,
    competitors,
    targetAudience
    // keyDifferentiatingFactor
  };

  const handleSubmit = (values: TEditBusinessModelCompetitorsFormValues) => {
    updateFormValues({ values });
  };

  return (
    <div className="edit-business-model-competitors">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="edit-idea-edit-details__heading"
      >
        Business Model & Competitors
      </Typography>

      {/* Problems and solutions form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={submitButtonRef}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="edit-business-model-competitors__form">
              {/* Major Revenue Stream */}
              <TextAreaField
                label="Major Revenue Stream"
                id="majorRevenueStream"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Specify the primary source through which your business generates revenue"
                onChange={(e) => {
                  setFieldValue('majorRevenueStream', e.target.value);
                  updateFormValues({ values: { majorRevenueStream: e.target.value } });
                }}
                value={values.majorRevenueStream}
              />

              {/* Scalability of the Idea */}
              {/* <TextAreaField
                label="Scalability of the Idea"
                id="scaliblityOfIdea"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Describe the potential for your idea to grow and expand"
                onChange={(e) => {
                  setFieldValue('scaliblityOfIdea', e.target.value);
                  updateFormValues({ values: { scaliblityOfIdea: e.target.value } });
                }}
                value={values.scaliblityOfIdea}
              /> */}

              {/* Competitors */}
              <TextAreaField
                label="Competitors"
                id="competitors"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="List and analyze the key competitors in your idea."
                onChange={(e) => {
                  setFieldValue('competitors', e.target.value);
                  updateFormValues({ values: { competitors: e.target.value } });
                }}
                value={values.competitors}
              />

              {/* Key Differentiating Factor */}
              {/* <TextAreaField
                label="Key Differentiating Factor"
                id="keyDifferentiatingFactor"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Highlight the unique feature or aspect that sets your idea apart from competitors."
                onChange={(e) => {
                  setFieldValue('keyDifferentiatingFactor', e.target.value);
                  updateFormValues({ values: { keyDifferentiatingFactor: e.target.value } });
                }}
                value={values.keyDifferentiatingFactor}
              /> */}
              <TextAreaField
                label="Target Market"
                id="targetAudience"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Describe the specific demographic or group your idea."
                onChange={(e) => {
                  setFieldValue('targetAudience', e.target.value);
                  updateFormValues({ values: { targetAudience: e.target.value } });
                }}
                value={values.targetAudience}
              />

            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditBusinessModelCompetitors.defaultProps = {
  majorRevenueStream: '',
  // scaliblityOfIdea: '',
  competitors: '',
  targetAudience: ''
  // keyDifferentiatingFactor: ''
};

export default EditBusinessModelCompetitors;
