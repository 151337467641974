import React, { useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import cx from './upcomingEvents.module.scss'
import { optionsSlider1 } from 'src/constants/OwlCarouselConstant';
import EventCard from 'src/components/MarketPlace/EventCard/EventCard';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getEventSuccess, getMarketPlaceEvent } from 'src/store/marketplace/marketplace.slice';
import { Loader } from 'src/components/common';
import { useNavigate } from 'react-router-dom';

export default function UpcomingEvents({ gb }: any) {
    const { eventLoading, topEvent } = useAppSelector((state) => state.marketPlace);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/all?dataType=top-Event')
    }
    useEffect(() => {
        dispatch(getMarketPlaceEvent({ dataType: 'top-Event', pageSize: 11, page: 1 }))
        return () => {
            dispatch(getEventSuccess([]))
        }
    }, [dispatch])

    if (eventLoading) {
        return <Loader />
    }
    return (
        <div className={`${gb.section}`}>
            <div className={`${gb.headBox}`}>
                <h5 className={`${gb.sectionHeading}`}>Upcoming Events</h5>
                {topEvent?.length > 10 && <span onClick={handleNavigate}>See All</span>}
            </div>
            <div className={`${cx.upcomingEvents}`}>
                {!eventLoading && <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                    {topEvent?.map((event, e: number) => {
                        const { id, organizerName, title, thumbnailUrl, description, mediaUrl } = event
                        return (
                            <EventCard key={e} isAction={true} id={id} organizer={organizerName || ''} url={mediaUrl} logo={thumbnailUrl || ''} name={title} description={description} />
                        )
                    })}
                </OwlCarousel>}
                {!eventLoading && topEvent?.length === 0 && <p className={`${cx.globalUnknonwn}`}>No Events Found</p>}
            </div>
        </div>
    )
}
