import { useMemo } from 'react';
import { Typography } from 'src/components/common';

import { keyLabelMapping } from './keyLabelMapping';
import './IdeaDetailsSectionView.scss';

import IdeaLabelValue, { TIdeaLabelValue } from '../ideaLabelValue/IdeaLabelValue';

export type TIdeaDetailsSectionViewProps = {
  sectionTitle: string;
  data: Record<string, string>;
};

const IdeaDetailsSectionView = ({ sectionTitle, data }: TIdeaDetailsSectionViewProps) => {
  // console.log('IdeaDetailsSectionView', data);
  // if (data.validationReport) {
  // function hasOnlyValidationReportField(data) {
  //   // Check if the object has only one key and that key is 'validationReport'
  //   if (Object.keys(data).length === 1 && Object.prototype.hasOwnProperty.call(data, 'validationReport')) {
  //     return true;
  //   }
  //   return false;
  // }
  // const checkField = hasOnlyValidationReportField(data);
  // if (checkField) {
  //   function formatText(inputText) {
  //     // Define the phrases to be bolded
  //     const boldPhrases = [
  //       "1. Market Feasibility",
  //       "2. Ideal Customer and Profile",
  //       "3. Competitive Analysis",
  //       "4. Market Size and Growth Potential",
  //       "5. Go-to-Market Strategy",
  //       "6. Risks and Mitigation Strategies",
  //       "7. Financial Viability",
  //       "8. One HighValue Recommendation",
  //       "Conclusion:" 
  //     ];
  //     // Replace '###' with <br> tag
  //     let formattedText = inputText.replace(/###/g, '<br>');

  //     formattedText = formattedText.replace(/-/g, '');

  //     formattedText = formattedText.replace(/<br> <br>/g, '<br>');

  //     formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '$1');

  //     formattedText = formattedText.replace(/#/g, '');
    
  //     // Loop through the boldPhrases and replace them with <b> tag wrapped phrases
  //     boldPhrases.forEach(phrase => {
  //       const boldTagPhrase = `<br> <b>${phrase}</b> <br>`;
  //       formattedText = formattedText.replace(new RegExp(phrase, 'g'), boldTagPhrase);
  //     });
  //     return formattedText;
  //   }
  //   data.validationReport = formatText(data.validationReport);
  // }
  // } else if (data.validationReport === null) { data.validationReport = 'No report found'; }
  // console.log('IdeaDetailsSectionView', data);
  if (data===null || data === undefined) {
    console.log('IdeaDetailsSectionView', data);
    data = { validationReport: 'No report found' };
  }
  const fields: TIdeaLabelValue[] = useMemo(() => {
    try {
      const keys: string[] = Object.keys(data);

      return keys.map((key) => {
        const label: string = keyLabelMapping[key as keyof typeof keyLabelMapping] || '';
        const value: string = data[key as keyof typeof data];

        return { label, value };
      });
    } catch {
      return [];
    }
  }, [JSON.stringify(data), sectionTitle]);

  return (
    <div className="idea-details-section-view">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        {sectionTitle}
      </Typography>
      <div className="idea-details-section-view__fields">
        {fields.map((field, index) => {
          return <IdeaLabelValue key={index} {...field} />;
        })}
      </div>
    </div>
  );
};

export default IdeaDetailsSectionView;
