/* eslint-disable no-unneeded-ternary */

import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useNavigate } from 'react-router-dom';
import Header from 'src/components/header/Header';
import {
  clearCompanySuccess,
  // getServices,
  getViewCompany,
  incrementKeyMembersPage
} from 'src/store/company/company.slice';
import { TGetUserStatsRequest } from 'src/services/apiEndpoint.types.ts';
// import { About, Activities, KeyMembers, Programs, Services } from 'src/components/company';
import { About, Activities, KeyMembers, Programs } from 'src/components/company';
import { useCopyToClipboard, useGetIdentity, useUrlParamValue, useGoBack } from 'src/hooks';
import {
  BackNavigation,
  InfiniteScroll,
  TDropdownOptions,
  TTabItem,
  Tabs
} from 'src/components/common';
import CompanyProfileCard from 'src/components/company/companyCard/CompanyProfileCard';
import { getUserStats, incrementNewsFeedPage } from 'src/store/socialMedia/socialMedia.slice';
import { incrementProgramsPage } from 'src/store/program/program.slice';
import { InviteNetwork, StartupNewsResources } from 'src/components/cards';

import './viewCompany.scss';
import CompanyServices from 'src/components/company/CompanyServices/CompanyServices';
import CompanyProduct from 'src/components/company/CompanyProduct/CompanyProduct';
import CompanyPortfolio from 'src/components/company/CompanyPortfolio/CompanyPortfolio';
import CompanyEvents from 'src/components/company/CompanyEvents/CompanyEvents';

export type TMyCompanyTabs = 'about' | 'services' | 'programs' | 'key-members' | 'activities';

const ViewCompany = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyToClipboard();
  const { getIdentities } = useGetIdentity();
  const handleGoBack = useGoBack();

  const prevRoute = paramValue({ paramName: 'prev' });
  const currentTab = paramValue({ paramName: 'tab' }) || 'about';
  const viewCompanyId = paramValue({ paramName: 'id' }) as unknown as string;

  const { loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { viewCompany, viewCompanyProfilePic, keyMembersLoading, keyMembersMoreItemsLeft } =
    useAppSelector((store) => store.company);
  const { newsFeedMoreItemsLeft } = useAppSelector((store) => store.socialMedia);
  const { settings } = useAppSelector((store) => store.marketPlace);
  const { moreProgramsLeft } = useAppSelector((store) => store.program);

  const { loggedInUserId, loggedInCompanyId, loggedInUserType } = getIdentities();
  const isViewerProgramCreator = loggedInCompanyId === viewCompanyId;
  
  // const fetchServices = () => {
  //   dispatch(getServices({ id: viewCompanyId }));
  // };

  const loadMoreItems = () => {
    switch (currentTab) {
      case 'activities': {
        if (newsFeedMoreItemsLeft && !loading) {
          dispatch(incrementNewsFeedPage(1));
        }
        break;
      }
      case 'key-members': {
        if (!keyMembersLoading && keyMembersMoreItemsLeft) {
          dispatch(incrementKeyMembersPage(1));
        }
        break;
      }
      case 'programs': {
        if (!loading && moreProgramsLeft) {
          dispatch(incrementProgramsPage(1));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const InfiniteScrollLoading =
    currentTab === 'activities' ? loading : currentTab === 'programs' ? loading : keyMembersLoading;
  const moreItemsLeft =
    currentTab === 'activities'
      ? newsFeedMoreItemsLeft
      : currentTab === 'key-members'
        ? keyMembersMoreItemsLeft
        : currentTab === 'programs'
          ? moreProgramsLeft
          : false;
  // Footer is not required when there is no infinite scrolling
  const showFooter =
    currentTab === 'activities' || currentTab === 'key-members' || currentTab === 'programs'
      ? true
      : false;

  const {
    name,
    type,
    about,
    website,
    introduction,
    foundedYear,
    industry,
    contactNumber,
    companyEmailAddress,
    contactUsUrl,
    address,
    linkedin,
    facebook,
    twitter,
    instagram,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    zipCode
  } = viewCompany;

  console.log(viewCompany, 'viewCompany', settings);

  const tabItems: TTabItem[] = useMemo(() => {
    const tabs = [
      {
        key: 'about',
        text: 'About',
        disabled: false,
        component: (
          <About
            about={about}
            foundedYear={foundedYear}
            industry={industry}
            address={address}
            email={companyEmailAddress}
            linkedin={linkedin}
            facebook={facebook}
            twitter={twitter}
            instagram={instagram}
            contactNumber={contactNumber}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            state={state}
            country={country}
            zipCode={zipCode}
            type={type}
          />
        )
      },
      // {
      //   key: 'services',
      //   text: 'Services',
      //   disabled: false,
      //   component: <Services fetchServices={fetchServices} />
      // },

      {
        key: 'activities',
        text: 'Activities',
        disabled: false,
        component: (
          <Activities
            viewCompanyId={viewCompanyId}
            viewerId={loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId}
            viewerType={loggedInUserType}
          />
        )
      }
    ];

    if (viewCompany.type === 'Incubator' || viewCompany.type === 'Accelerator') {
      tabs.splice(
        2,
        0,
        {
          key: 'programs',
          text: 'Programs',
          disabled: false,
          component: <Programs companyId={viewCompanyId} isViewerProgramCreator={isViewerProgramCreator} />
        },
        {
          key: 'key-members',
          text: 'Key Members',
          disabled: false,
          component: <KeyMembers companyIdToFetch={viewCompanyId} isViewerProgramCreator={isViewerProgramCreator} />
        }
      );
    }
    const tabArr = [
      {
        key: 'services',
        text: 'Service',
        disabled: false,
        component: <CompanyServices isViewerProgramCreator={isViewerProgramCreator}/>
      },
      {
        key: 'product',
        text: 'Product',
        disabled: false,
        component: <CompanyProduct isViewerProgramCreator={isViewerProgramCreator}/>
      },
      {
        key: 'portfolio',
        text: 'Portfolio',
        disabled: false,
        component: <CompanyPortfolio isViewerProgramCreator={isViewerProgramCreator}/>
      },
      {
        key: 'events',
        text: 'Event',
        disabled: false,
        component: <CompanyEvents isViewerProgramCreator={isViewerProgramCreator}/>
      }
    ].filter((tab: TTabItem) => {
      return settings[`isMarketPlace${tab.text}TabEnabled`]
    })

    if (viewCompany?.type === 'Service Provider') {
      tabArr.forEach((tab, index: number) => {
        tabs.splice(
          index + 1,
          0,
          tab
        )
      })
    }

    return tabs;
  }, [viewCompany.type, JSON.stringify(viewCompany), JSON.stringify(settings)]);

  const copyProfileLink = () => {
    copyToClipboard({ text: `${window.location.host}/view-company?id=${viewCompanyId}` });
  };

  const changeMyCompanyTab = (value: string) => {
    navigate(`/view-company?id=${viewCompanyId}&tab=${value as TMyCompanyTabs}&prev=${prevRoute}`);
  };

  const companyCardDropdownItems: TDropdownOptions[] = [
    { label: 'Copy profile link', onClick: copyProfileLink }
  ];

  const handleGetUserStats = ({ id }: { id: string }) => {
    const payload: TGetUserStatsRequest = {
      userId: id,
      userType: 'Company',
      requestorId: loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId,
      requestorType: loggedInUserType
    };

    dispatch(getUserStats(payload));
  };

  useEffect(() => {
    const id = viewCompanyId;
    if (id) {
      dispatch(getViewCompany({ id, ...(loggedInUserId && { userId: loggedInUserId }) }));

      handleGetUserStats({ id });

      return () => {
        dispatch(clearCompanySuccess())
      }
    }
  }, [viewCompanyId]);

  return (
    <InfiniteScroll
      className="view-company"
      onScrollEnd={loadMoreItems}
      loading={InfiniteScrollLoading}
      showLoader={currentTab === 'key-members' ? false : true}
      moreItemsLeft={moreItemsLeft}
      showFooter={showFooter}
    >
      <Header />
      <div className="view-company__body">
        {<BackNavigation text="Back" route={prevRoute || '/dashboard'} onClick={handleGoBack} />}

        <div className="view-company__body__content">
          <div className="view-company__body__content__body">
            <CompanyProfileCard
              viewCompanyId={viewCompanyId}
              name={name}
              type={type}
              introduction={introduction}
              website={website}
              contactUsUrl={contactUsUrl}
              profilePic={viewCompanyProfilePic}
              dropdownItems={companyCardDropdownItems}
              getUserStats={handleGetUserStats}
            />
            <Tabs activeItem={currentTab} onTabChange={changeMyCompanyTab} items={tabItems} />
          </div>
          <div className="view-company__body__content__cards">
            <InviteNetwork />
            <StartupNewsResources />
          </div>
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default ViewCompany;
