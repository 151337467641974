import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal } from 'src/components/common';
import './contactUsModal.scss';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import {
  TFindCreateConversationRequest,
  TGetCompany,
  TReceiveMessageResponse,
  TSendDirectMessageRequest
} from 'src/services/apiEndpoint.types.ts';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { contactUsModalStateChange } from 'src/store/company/company.slice';
import {
  findCreateConversation,
  receiveMessageSuccess,
  sendDirectMessage
} from 'src/store/chat/chat.slice';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity } from 'src/hooks';
import { socketService } from 'src/services/socket.api.service';

const globalServiceProviderValidationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  description: yup.string().required('Description is required')
});
const initalValue = {
  subject: '',
  description: ''
};
const ContactUsModal = ({ viewCompanyId }: { viewCompanyId: string }) => {
  const {
    myCompany,
    contactUsModalState
  }: {
    myCompany: TGetCompany;
    contactUsModalState?: { state: boolean };
  } = useAppSelector((store) => store.company);
  const navigate = useNavigate();
  // states
  const [contactData, setContactData] = useState(initalValue);
  const [loading, setLoading] = useState(false);
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  // Constants
  const { authenticatedId, loggedInUserType, loggedInCompanyId } = getIdentities();

  const closeModal = () => {
    dispatch(contactUsModalStateChange({ state: false }));
  };
  function handleSubmit(values) {
    setLoading(true);
    const payload: TFindCreateConversationRequest = {
      senderUserId: authenticatedId,
      senderUserType: loggedInUserType,
      receiverUserId: viewCompanyId,
      receiverUserType: 'Company',
      moduleType: 'ServiceProvider'
    };

    dispatch(findCreateConversation(payload))
      .then((data) => {
        const conversationId = data?.conversation?.id;
        if (conversationId) {
          const payload: TSendDirectMessageRequest = {
            userId: authenticatedId,
            userType: loggedInUserType,
            conversationId,
            eventName: 'conversation',
            message: `Subject : ${values.subject} ,\n Message :  ${values.description}`,
            messageId: uuidv4(),
            files: []
          };
          // socketService.emit('join', {
          //     userId: authenticatedId,
          //     conversationId
          // });
          // const onSocketConversations = (event: TReceiveMessageResponse) => {
          //     dispatch(receiveMessageSuccess(event));
          //     socketService.emit('get-conversation-unread-message-count', {
          //         userId: authenticatedId,
          //         userType: loggedInUserType
          //     });
          // };
          closeModal();
          // socketService.on('conversation', onSocketConversations);
          dispatch(sendDirectMessage(payload));
          navigate(
            `/chat?conversationId=${conversationId}&userId=${viewCompanyId}&userType=Company`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // const onSocketConnection = () => {
  //     console.log('Socket connected');
  // };
  // useEffect(() => {
  //     socketService.connect();

  //     socketService.on('connect', onSocketConnection);
  //     return () => {
  //         socketService.off('connect', onSocketConnection);
  //         socketService.disconnect();
  //     }
  // }, [])

  return (
    <Modal
      isModalOpen={contactUsModalState.state}
      onModalClose={closeModal}
      title="Contact Us"
      className="add__global-modal"
    >
      <div className="add__global">
        {/* Form starts */}
        <Formik
          initialValues={contactData}
          onSubmit={handleSubmit}
          validationSchema={globalServiceProviderValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form className="add__global__form" noValidate>
                <div className="add__global__form__crate-form">
                  <Row>
                    <Col md={12} className="mb-3">
                      <InputField
                        required
                        label="Subject"
                        id="subject"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Subject"
                        type="text"
                      />
                    </Col>

                    <Col md={12}>
                      <h5 className="typography__caption typography__semiBold mb-2">Description</h5>
                      <TextArea
                        id="programObjective"
                        placeholder="Description"
                        required={true}
                        value={values?.description}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setFieldValue('description', e.target.value)
                        }
                        textAreaStyle={{ width: '100%' }}
                      />
                      {touched.description && errors.description && (
                        <ErrorMessage message="Description is required" />
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="add__global__form__buttons">
                  <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                    Cancel
                  </Button>

                  <Button variant="primary" type="submit" size="small" loading={loading}>
                    {loading ? <Loader /> : 'Submit'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
