import { useEffect } from 'react';
import { socketService } from 'src/services/socket.api.service';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TDiscussionTypes } from 'src/components/chat/chat.type';
import {
  TDeleteIdeaMessageResponse,
  TDislikeConversationMessageResponse,
  TDislikeConversationMessageSuccess,
  TEditIdeaMessageResponse,
  TGetIdeaConversationMessagesRequest,
  TGetProgramConversationMessagesRequest,
  TLikeConversationMessageResponse,
  TLikeConversationMessageSuccess,
  TReceiveMessageResponse,
  TGetOneToOneConversationMessagesRequest,
  TClearDirectMessageChatResponse
} from 'src/services/apiEndpoint.types.ts';
import {
  deleteIdeaMessageSuccess,
  dislikeMessageSuccess,
  editIdeaMessageSuccess,
  getIdeaConversationMessages,
  getProgramConversationMessages,
  incrementOneToOneMessagesPageNumber,
  likeConversationMessageSuccess,
  receiveMessageSuccess,
  getOneToOneConversationMessages,
  resetMessages
} from 'src/store/chat/chat.slice';
import { ScrollTopPagination } from 'src/components/common';

import { TChatMessagesBodyProps } from './chatMessagesBody.types';
import './chatMessagesBody.scss';

import ChatMessage from '../chatMessage/ChatMessage';

const ChatMessagesBody = ({
  groupId,
  updateMessageSettings,
  groupMessageContext
}: TChatMessagesBodyProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { itemListLoader } = useAppSelector((store) => store.common);
  const {
    messages,
    // oneToOneChatMessagesPageNumber,
    // oneToOneChatMessagesItemsPerPage,
    // ideaChatMessagesMoreItemsLeft,
    oneToOneChatMessagesPageNumber,
    oneToOneChatMessagesItemsPerPage,
    ideaChatMessagesMoreItemsLeft
    // clearDirectMessageSuccess
  } = useAppSelector((store) => store.chat);

  const chatMessages = messages[groupId] ? messages[groupId] : [];

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const chatType = paramValue({ paramName: 'chatType' }) as unknown as TDiscussionTypes;

  const onSocketConnection = () => {
    console.log(`${groupMessageContext} chat connected`);
  };

  const onSocketConversations = (event: TReceiveMessageResponse) => {
    dispatch(receiveMessageSuccess(event));
  };

  const editLocalMessageState = (event: TEditIdeaMessageResponse) => {
    dispatch(editIdeaMessageSuccess(event));
  };

  const deleteLocalMessageState = (event: TDeleteIdeaMessageResponse) => {
    dispatch(deleteIdeaMessageSuccess(event));
  };

  const likeLocalMessageState = (event: TLikeConversationMessageResponse) => {
    const payload: TLikeConversationMessageSuccess = {
      conversationId: groupId,
      messageId: event?.messageId
    };
    dispatch(likeConversationMessageSuccess(payload));
  };

  const dislikeLocalMessageState = (event: TDislikeConversationMessageResponse) => {
    const payload: TDislikeConversationMessageSuccess = {
      conversationId: groupId,
      messageId: event?.messageId
    };
    dispatch(dislikeMessageSuccess(payload));
  };

  const getIdeaChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetIdeaConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId: groupId,
      limit: oneToOneChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : oneToOneChatMessagesPageNumber
    };

    dispatch(getIdeaConversationMessages(payload));
  };
  const getChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetOneToOneConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId: groupId,
      limit: oneToOneChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : oneToOneChatMessagesPageNumber
    };

    dispatch(getOneToOneConversationMessages(payload));
  };
  const getProgramChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetProgramConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId: groupId,
      limit: oneToOneChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : oneToOneChatMessagesPageNumber
    };
    // <htting

    dispatch(getProgramConversationMessages(payload));
  };

  const loadMoreItems = () => {
    if (ideaChatMessagesMoreItemsLeft && !itemListLoader) {
      dispatch(incrementOneToOneMessagesPageNumber(1));
    }
  };
  const clearLocalChat = (event: TClearDirectMessageChatResponse) => {
    // dispatch(clearDirectMessageSuccess(event));
  };
  // console.info('idea message offset', oneToOneChatMessagesPageNumber);

  useEffect(() => {
    if (groupId) {
      socketService.connect();

      socketService.on('connect', onSocketConnection);

      socketService.emit('join', {
        userId: authenticatedId,
        conversationId: groupId
      });

      socketService.on('idea-conversation', onSocketConversations);
      socketService.on('edit-idea-conversation-message', editLocalMessageState);
      socketService.on('delete-idea-conversation-message', deleteLocalMessageState);

      socketService.on('like-idea-conversation-message', likeLocalMessageState);
      socketService.on('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.on('like-program-conversation-message', likeLocalMessageState);
      socketService.on('dislike-program-conversation-message', dislikeLocalMessageState);

      socketService.on('program-conversation', onSocketConversations);
      socketService.on('edit-program-conversation-message', editLocalMessageState);
      socketService.on('delete-program-conversation-message', deleteLocalMessageState);
    }

    return () => {
      socketService.off('connect', onSocketConnection);

      socketService.off('idea-conversation', onSocketConversations);
      socketService.off('edit-idea-conversation-message', editLocalMessageState);
      socketService.off('delete-idea-conversation-message', deleteLocalMessageState);

      socketService.off('like-idea-conversation-message', likeLocalMessageState);
      socketService.off('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.off('like-program-conversation-message', likeLocalMessageState);
      socketService.off('dislike-program-conversation-message', dislikeLocalMessageState);

      socketService.off('program-conversation', onSocketConversations);
      socketService.off('edit-program-conversation-message', editLocalMessageState);
      socketService.off('delete-program-conversation-message', deleteLocalMessageState);
      socketService.disconnect();
    };
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      socketService.connect();

      socketService.on('connect', onSocketConnection);

      socketService.emit('join', {
        userId: authenticatedId,
        conversationId: groupId
      });

      socketService.on('conversation', onSocketConversations);
      socketService.on('edit-conversation-message', editLocalMessageState);
      socketService.on('delete-conversation-message', deleteLocalMessageState);
      socketService.on('clear-conversation', clearLocalChat);

      socketService.on('idea-conversation', onSocketConversations);
      socketService.on('edit-idea-conversation-message', editLocalMessageState);
      socketService.on('delete-idea-conversation-message', deleteLocalMessageState);
      socketService.on('clear-idea-conversation', clearLocalChat);
      socketService.on('like-conversation-message', likeLocalMessageState);
      socketService.on('dislike-conversation-message', dislikeLocalMessageState);
      socketService.on('like-idea-conversation-message', likeLocalMessageState);
      socketService.on('dislike-idea-conversation-message', dislikeLocalMessageState);
    }

    return () => {
      socketService.off('connect', onSocketConnection);
      socketService.off('conversation', onSocketConversations);
      socketService.off('edit-conversation-message', editLocalMessageState);
      socketService.off('delete-conversation-message', deleteLocalMessageState);
      socketService.off('clear-conversation', clearLocalChat);
      socketService.off('idea-conversation', onSocketConversations);
      socketService.off('edit-idea-conversation-message', editLocalMessageState);
      socketService.off('delete-idea-conversation-message', deleteLocalMessageState);
      socketService.off('clear-idea-conversation', clearLocalChat);
      socketService.off('like-conversation-message', likeLocalMessageState);
      socketService.off('dislike-conversation-message', dislikeLocalMessageState);
      socketService.off('like-idea-conversation-message', likeLocalMessageState);
      socketService.off('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.disconnect();
    };
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      // if (chatType === 'ideaGroup') {
      //   getIdeaChatMessages({ resetMessages: true });
      // } else if (chatType === 'programGroup' || chatType === 'programDiscussion') {
      //   getProgramChatMessages({ resetMessages: true });
      // }
      getChatMessages({ resetMessages: true });
    }
  }, [groupId]);

  useEffect(() => {
    if (oneToOneChatMessagesPageNumber) {
      // if (chatType === 'ideaGroup') {
      //   getIdeaChatMessages({ resetMessages: false });
      // } else if (chatType === 'programGroup' || chatType === 'programDiscussion') {
      //   getProgramChatMessages({ resetMessages: false });
      // }
      getChatMessages({ resetMessages: false });
    }
  }, [oneToOneChatMessagesPageNumber]);

  useEffect(() => {
    return () => {
      dispatch(resetMessages());
    };
  }, [groupId]);

  console.log(oneToOneChatMessagesPageNumber);

  return (
    <ScrollTopPagination
      id={groupId}
      className="chat-messages-body"
      onScrollTop={loadMoreItems}
      loading={itemListLoader}
      moreItemsLeft={ideaChatMessagesMoreItemsLeft}
      scrollToBottomOnLoad={true}
      offset={oneToOneChatMessagesPageNumber}
    >
      {chatMessages.map((message, index) => {
        return (
          <ChatMessage
            key={index}
            {...message}
            loggedInUserId={authenticatedId}
            loggedInUserType={loggedInUserType}
            groupId={groupId}
            updateMessageSettings={updateMessageSettings}
            groupMessageContext={groupMessageContext}
          />
        );
      })}
    </ScrollTopPagination>
  );
};

export default ChatMessagesBody;
