import React, { useEffect, useMemo, useRef, useState } from 'react'
import { InfiniteScroll, Loader, Tabs, TTabItem } from 'src/components/common'
import Header from 'src/components/header/Header';
import MarketPlaceSection from './MarketPlaceSection';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import HoverTab from 'src/components/common/hoverTab/hoverTab';

import { clearGlobalServices, getMarketPlaceGlobalServices } from 'src/store/marketplace/marketplace.slice';
import { truncateByDomain } from 'recharts/types/util/ChartUtils';

export default function MarketPlace() {
    const { globalServices, globalLoading } = useAppSelector((state) => state.marketPlace)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getMarketPlaceGlobalServices())

        return () => {
            dispatch(clearGlobalServices())
        }
    }, []);

    const tabItems = useMemo(() => {
        return globalServices?.map((item) => {
            return (
                {
                    key: item.title,
                    text: item.title,
                    catId: item.id,
                    disabled: true,
                    item: item.Services?.map((service) => ({ text: service?.title, id: service.id }))
                }
            )
        })
    }, [globalServices])
    console.log(globalServices, 'globalServices');
    
    return (
        <InfiniteScroll
            className="view-program"
            onScrollEnd={() => { }}
            loading={false}
            moreItemsLeft={false}
        >
            <Header />
            {globalLoading !== 'service-loading' && <div className={'view-program__body'}>
                <div>
                    <HoverTab items={tabItems} />
                </div>
                <MarketPlaceSection />
            </div>}
            {globalLoading === 'service-loading' && <Loader />}
        </InfiniteScroll>
    )
}
