/* eslint-disable */

import { FC } from 'react';
import {
  // ActionIcon,
  TDropdown,
  ThreeDotsDropdown,
  Tooltip,
  Typography,
  TDropdownOptions
} from 'src/components/common';
import { colors } from 'src/constants';
import { textEllipsis } from 'src/utils';
import { useAppSelector } from 'src/store/hooks';
import { useToggleCreateEditGroupModal, useToggleManageGroupModalModal } from 'src/components/chat';
import { useGetIdentity, useToggleOpenModal } from 'src/hooks';
import './ideaDiscussionGroupHeader.scss';
import { TIdeaDiscussionGroupHeaderProps } from './ideaDiscussionGroupHeader.types';
import useToggleOpenDiscussionMembersModal from './useToggleOpenDiscussionMembersModal';

import EditIdeaGroup from '../../editIdeaGroup/EditIdeaGroup';
import { TIdeaSharedWithMe } from 'src/components/ideaHub/ideaHub.types';

const IdeaDiscussionGroupHeader: FC<TIdeaDiscussionGroupHeaderProps> = ({
  discussionType,
  groupId,
  ideaId
  // isViewerOwner
}: TIdeaDiscussionGroupHeaderProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const { Groups, GroupParticipants } = useAppSelector((store) => store.chat);
  const deleteProgramGroupConfirmModalId = `deleteProgramGroupConfirmModal-${groupId}`;
  const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
    useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { toggleManageGroupModalModal } = useToggleManageGroupModalModal();
  const toggleOpenModal = useToggleOpenModal();
  const toggleOpenDeleteGroupModal = () => {
    toggleOpenModal({ modalId: deleteProgramGroupConfirmModalId });
  };
  const { authenticatedId, loggedInUserType } = getIdentities();

  let headerDropdownOptions: TDropdownOptions[] = [];
  // console.log(isViewerOwner, "isViewerOwnerisViewerOwner")
  // Get program groups
  if (GroupParticipants?.[0]?.ownerId === authenticatedId) {
    headerDropdownOptions =
      // { label: 'Manage Members', onClick: toggleManageGroupModalModal },
      [
        { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
        { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
      ];
  } else {
    headerDropdownOptions =
      // { label: 'Manage Members', onClick: toggleManageGroupModalModal },
      // { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
      [{ label: 'Leave Group', onClick: toggleOpenDeleteGroupModal }];
  }
  const ideaGroupParticipants: any = useAppSelector((store) => store.chat.GroupParticipants);
  const ideasSharedWithMe: TIdeaSharedWithMe[] = useAppSelector(
    (store) => store.ideaHub.ideasSharedWithMe
  );

  const { toggleOpenOpenDiscussionMembersModal } = useToggleOpenDiscussionMembersModal();

  // Constants
  // const participants: any = [
  //   ...ideaGroupParticipants?.[0]?.Users,
  //   ...ideaGroupParticipants?.[0]?.Companies
  // ];
  const groupName = ideaGroupParticipants?.[0]?.groupName;
  const participants = ideaGroupParticipants?.[0]?.Users;

  const ownerName: any =
    ideasSharedWithMe?.find(
      (item: TIdeaSharedWithMe) => item?.idea?.id === ideaGroupParticipants?.[0]?.ideaId
    )?.idea?.owner || '';
  const ownerNameWitEllipsis = textEllipsis({ text: ownerName?.name, charLimit: 12 });

  const dropdownOptionsProps: TDropdown = {
    id: `chat-messages-header-${groupId}`,
    size: 'large',
    alignment: 'center',
    items: headerDropdownOptions
  };
  console.log(participants, 'partication');
  const filterParticipant = participants
    // ?.filter((item: any) => item?.id !== ownerName?.id)
    ?.map((item: any) => item?.name);
  let membersToRender: string[] = [];
  const remainingMembers: number = filterParticipant?.length - 7;
  try {
    if (participants?.length > 7) {
      for (let i = 1; i < 7; i++) {
        const member = filterParticipant?.[i];
        console.log(member, 'memberrr');
        membersToRender.push(member);
      }
    } else {
      membersToRender = filterParticipant?.slice(0)?.map((member: any) => member);
    }
  } catch {}
  const openMembersModal = () => {
    if (discussionType === 'programDiscussion') {
      toggleOpenOpenDiscussionMembersModal();
    }
  };

  console.log(membersToRender, 'Miiiiiii');

  return (
    <div className="discussion-messages-header">
      <div className="discussion-messages-header__left">
        <Typography
          as="p"
          variant="body-1"
          fontWeight="semiBold"
          style={{ color: colors?.neutral?.[1100] }}
          className="discussion-messages-header__left__heading"
        >
          {discussionType === 'programDiscussion' ? 'Program Discussion' : groupName}
        </Typography>

        <div className="discussion-messages-header__left__members">
          {ownerNameWitEllipsis?.text && (
            <Tooltip
              key={'owner'}
              text={ownerName?.name}
              conditionToDisplay={ownerNameWitEllipsis?.hasEllipsis}
              limitWidth
            >
              <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                style={{ color: colors?.neutral?.[1100] }}
              >
                {ownerNameWitEllipsis?.text}&nbsp;(Owner),&nbsp;
              </Typography>
            </Tooltip>
          )}
          {membersToRender &&
            membersToRender?.length > 0 &&
            membersToRender.map((member, index) => {
              const nameWitEllipsis = textEllipsis({ text: member, charLimit: 12 });
              return (
                <Tooltip
                  key={index}
                  text={member}
                  conditionToDisplay={nameWitEllipsis?.hasEllipsis}
                  limitWidth
                >
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    style={{ color: colors?.neutral?.[1100], whiteSpace: 'nowrap' }}
                  >
                    {nameWitEllipsis?.text},&nbsp;
                  </Typography>
                </Tooltip>
              );
            })}
          {remainingMembers > 0 && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              style={{ color: colors?.neutral?.[1100] }}
              className="discussion-messages-header__left__members__more-users"
              onClick={openMembersModal}
            >
              +{remainingMembers}&nbsp;more users
            </Typography>
          )}
        </div>
      </div>
      {/* {isViewerOwner && ( */}
      <div className="discussion-messages-header__right">
        <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />
      </div>
      {/* )} */}

      {isToggleCreateEditGroupModalOpen && (
        <EditIdeaGroup
          mode="Edit"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          groupId={groupId}
          userId={authenticatedId}
          userType={loggedInUserType}
          groupMembers={ideaGroupParticipants?.[0]}
          ideaId={ideaId}
          groupName={groupName || ''}
        />
      )}
    </div>
  );
};

export default IdeaDiscussionGroupHeader;
