import React, { useState } from 'react';
import classNames from 'classnames';
import { TTabs } from '../tabs/Tabs';
import cx from './hoverTab.module.scss'
import HoverTabItem from './HoverTabItem';

export default function HoverTab({ items, onTabChange, activeItem, className, onTouchMoveCapture, onLeave, onHover, ...rest }: TTabs) {
    return (
        <div className={cx.tabContainer} {...rest}>
            <ul>
                {items?.map(({ key, text, disabled = false, item, catId }, i: number) => {
                    return (
                        <HoverTabItem
                            key={i}
                            text={text}
                            index={i}
                            item={item}
                            isLast={i === items.length - 1}
                            catId={catId}
                        />
                    )
                })}
            </ul>
        </div>
    )
}
