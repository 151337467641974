import { FC } from 'react';
import { Typography } from 'src/components/common';
import MarkdownRenderer from 'react-markdown-renderer';

import './ideaLabelValue.scss';

export type TIdeaLabelValue = {
  label: string;
  value: string;
};

const processMarkdown = (markdown: string) => {
  const limitedHeadings = markdown.replace(/^(#{2,})(\s)/gm, '###### ')
    .replace(/^(#{1,})(\s)/gm, '###### ')
    .replace(/^(#{3,})(\s)/gm, '###### ');
  const formattedMarkdown = limitedHeadings.replace(/\n/g, '  \n');
  return formattedMarkdown;
};

const IdeaLabelValue: FC<TIdeaLabelValue> = ({ label, value }: TIdeaLabelValue) => {
  const processedValue = processMarkdown(value);

  const containsSpecialMarkdown = value.includes('###') || value.includes('***');
  const containerClass = containsSpecialMarkdown
    ? 'idea-label-value special-markdown'
    : 'idea-label-value regular-markdown';

  return (
    <div className={`idea-details ${containerClass}`}>
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        {label}
      </Typography>
      <Typography as="p" variant="body-2" fontWeight="regular">
        <MarkdownRenderer markdown={processedValue} />
      </Typography>
    </div>
  );
};

IdeaLabelValue.defaultProps = {
  label: '',
  value: ''
};

export default IdeaLabelValue;
