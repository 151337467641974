import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store/hooks';
import Header from 'src/components/header/Header';
import IdeaSkeleton from 'src/components/ideaHub/ideaSkeleton/IdeaSkeleton';
import { TBreadcrumb } from 'src/components/common';
import { TIdeaSkeltonTabs, TIdeaCollaborationType } from 'src/components/ideaHub';
import { TGetIdea } from 'src/components/ideaHub/ideaHub.types';

import './viewEmptyIdea.scss';

const ViewEmptyIdea = () => {
  // Hooks
  const navigate = useNavigate();
  const { id, name, profilePic, email, role } = useAppSelector((store) => store.profile.profile);

  const ideaCollaborationType: TIdeaCollaborationType = 'Edit';

  const breadcrumbs: TBreadcrumb[] = [
    { label: 'Idea Hub', url: '/idea-hub?tab=myIdea' },
    { label: 'Create New Idea', url: '' }
  ];

  const handleChangeTab = (value: string) => {
    navigate(`/view-empty-idea?tab=${value as TIdeaSkeltonTabs}`);
  };

  const ideaData: TGetIdea = {
    idea: {
      id: '',
      ownerId: '',
      title: 'No information added. Kindly provide idea title.',
      domain: 'No information added. Kindly provide idea domain.',
      description: 'No information added. Kindly provide idea description.',
      // marketSize: 'No information added. Kindly provide market size.',
      targetAudience: 'No information added. Kindly provide your target audience.',
      // marketTrends: 'No information added. Kindly provide the market trends.',
      identifiedProblem: 'No information added. Kindly provide details of the identified problem.',
      solutionEffectiveness:
        'No information added. Kindly provide details of your solution effectiveness.',
      uniqueValueProposition: 'No information added. Kindly provide your unique value proposition',
      majorRevenueStream:
        'No information added. Kindly provide details of your major revenue stream.',
      // scaliblityOfIdea: 'No information added. Kindly provide details of your idea’s scalability.',
      competitors: 'No information added. Kindly provide details of your competitors.',
      validationReport: 'No information added. Kindly provide the validation report.',
      // keyDifferentiatingFactor: 'No information added. Kindly provide the differentiating factor.',
      status: 'Draft',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
      owner: {
        id,
        name,
        email,
        profilePic,
        role
      }
    },
    collaborators: [],
    collaboratorsCount: 0
  };

  return (
    <div className="view-empty-idea">
      <Header />
      <div className="view-empty-idea__body">
        <IdeaSkeleton
          viewType="emptyIdea"
          breadCrumbs={breadcrumbs}
          changeTab={handleChangeTab}
          ideaStatus="Draft"
          ideaCollaborationType={ideaCollaborationType}
          ideaData={ideaData}
          documents={[]}
          ratings={[]}
        />
      </div>
    </div>
  );
};

export default ViewEmptyIdea;
