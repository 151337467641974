import { AxiosError } from 'axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { parseInt } from 'lodash';
import { errorMessages, errorMessagesEnum } from 'src/constants';
import {
  TClearDirectMessageChatRequest,
  TClearDirectMessageChatResponse,
  TCreateIdeaGroupActionRequest,
  TCreateIdeaGroupRequest,
  TCreateIdeaGroupResponse,
  TCreateGroupRequest,
  TCreateGroupResponse,
  TCreateGroupActionRequest,
  TCreateProgramGroupActionRequest,
  TCreateProgramGroupRequest,
  TCreateProgramGroupResponse,
  TDeleteDirectMessageRequest,
  TDeleteDirectMessageResponse,
  TDeleteIdeaGroupActionRequest,
  TDeleteIdeaGroupRequest,
  TDeleteIdeaGroupResponse,
  TDeleteIdeaMessageRequest,
  TDeleteIdeaMessageResponse,
  TDeleteProgramGroupActionRequest,
  TDeleteProgramGroupRequest,
  TDeleteProgramGroupResponse,
  TDislikeConversationMessageRequest,
  TDislikeConversationMessageSuccess,
  TDislikeIdeaConversationMessageRequest,
  TDislikeIdeaConversationMessageResponse,
  TDownloadChatFileRequest,
  TDownloadChatFileResponse,
  TEditDirectMessageRequest,
  TEditDirectMessageResponse,
  TEditIdeaGroupActionRequest,
  TEditIdeaGroupRequest,
  TEditIdeaGroupResponse,
  TEditIdeaMessageRequest,
  TEditIdeaMessageResponse,
  TEditProgramGroupActionRequest,
  TFindCreateConversationData,
  TFindCreateConversationRequest,
  TFindCreateConversationResponse,
  TGetChatFilePresignedUrlActionRequest,
  TGetChatFilePresignedUrlResponse,
  TGetIdeaConversationMessagesRequest,
  TGetIdeaConversationMessagesResponse,
  TGetIdeaConversationMessagesResponseMessage,
  TGetIdeaConversationParticipantsRequest,
  TGetIdeaConversationParticipantsResponse,
  TGetIdeaConversationParticipantsResponseData,
  TGetConversationParticipantsRequest,
  TGetConversationParticipantsResponse,
  TGetIdeaGroup,
  TGetIdeaGroupsRequest,
  TGetIdeaGroupsResponse,
  TGetGroupsRequest,
  TGetGroup,
  TGetGroupsResponse,
  TGetOneToOneConversationMessagesRequest,
  TGetOneToOneConversationMessagesResponse,
  TGetOneToOneConversationMessagesResponseMessage,
  TGetProgramConversationMessagesRequest,
  TGetProgramConversationMessagesResponse,
  TGetProgramConversationMessagesResponseMessage,
  TGetProgramGroup,
  TGetProgramGroupParticipantsRequest,
  TGetProgramGroupParticipantsResponse,
  TGetConversationParticipantsResponseData,
  TGetProgramGroupsRequest,
  TGetProgramGroupsResponse,
  TLikeConversationMessageRequest,
  TLikeConversationMessageSuccess,
  TLikeIdeaConversationMessageRequest,
  TLikeIdeaConversationMessageResponse,
  TListOneToOneChatsRequest,
  TListOneToOneChatsResponse,
  TListOneToOneChatsResponseData,
  TReceiveMessageResponse,
  TSearchUsersToAddIdeaGroupRequest,
  TSearchUsersToAddIdeaGroupResponse,
  TSearchUsersToAddIdeaGroupResponseData,
  TSearchUsersToAddIdeaGroupUser,
  TSearchUsersToAddProgramGroupRequest,
  TSearchUsersToAddProgramGroupResponse,
  TSearchUsersToAddProgramGroupResponseData,
  TSearchUsersToAddProgramGroupUser,
  TSendDirectMessageRequest,
  TSendIdeaGroupMessageRequest,
  TUploadChatFileToS3ActionRequest,
  TUploadChatFileToS3Request,
  TUploadChatFileToS3Response
} from 'src/services/apiEndpoint.types.ts';
import {
  TListIdeaChatsRequest,
  TListIdeaChatsResponse,
  TListIdeaChatsResponseData
} from 'src/services/apiEndpoint.types.ts/chat/listIdeaChats.api.endpoint.types';
import { socketService } from 'src/services/socket.api.service';
import { APIResponse } from 'src/services/base.api.service';
import { ChatAPIService } from 'src/services/chat.api.service';
import { TUploadChatFileData } from 'src/services/apiEndpoint.types.ts/chat/getChatFilePresignedUrl.api.endpoint.types';

import { initialState } from './chat.slice.initialState';

import { AppDispatch } from '../store';
import {
  openPopup,
  startItemListLoader,
  startLoading,
  stopItemListLoader,
  stopLoading
} from '../common/common.slice';
export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // Send direct message success
    sendDirectMessageSuccess: () => {},
    // Send direct message success
    receiveMessageSuccess: (state, action: PayloadAction<TReceiveMessageResponse>) => {
      try {
        const { conversationId, ...rest } = action.payload;

        const currentConversationMessages = state?.messages?.[conversationId];

        const currentOneToOneChatLists: TListOneToOneChatsResponseData[] = JSON.parse(
          JSON.stringify(state.oneToOneChatLists)
        );

        const oneToOneChatListItem = currentOneToOneChatLists.find(
          (item) => item?.id === conversationId
        );
        const oneToOneChatListItemIndex = currentOneToOneChatLists.findIndex(
          (item) => item?.id === conversationId
        );

        const conversationMessages: TReceiveMessageResponse[] = currentConversationMessages
          ? JSON.parse(JSON.stringify(currentConversationMessages))
          : [];

        conversationMessages.push({ conversationId, ...rest });

        state.messages[conversationId] = conversationMessages;

        // Reorder chat list
        if (oneToOneChatListItemIndex > -1) {
          currentOneToOneChatLists.splice(oneToOneChatListItemIndex, 1);
        }

        if (oneToOneChatListItem) {
          oneToOneChatListItem.updatedAt = new Date().toISOString();
          currentOneToOneChatLists.unshift(oneToOneChatListItem);
        }

        state.oneToOneChatLists = currentOneToOneChatLists;
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Edit direct message success
     */
    editDirectMessageSuccess: (state, action: PayloadAction<TEditDirectMessageResponse>) => {
      try {
        const updatedMessage = action.payload;
        const conversationId: string = updatedMessage?.conversationId;
        const messageId: string = updatedMessage?.id;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages] = updatedMessage;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Delete direct message success
     */
    deleteDirectMessageSuccess: (state, action: PayloadAction<TDeleteDirectMessageResponse>) => {
      try {
        const deletedMessage = action.payload;
        const conversationId: string = deletedMessage?.conversationId;
        const messageId: string = deletedMessage?.id;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages.splice(indexInMessages, 1);
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Clear direct message success
     */
    clearDirectMessageSuccess: (state, action: PayloadAction<TClearDirectMessageChatResponse>) => {
      try {
        const clearedChat = action.payload;
        const conversationId: string = clearedChat?.conversationId;

        const currentMessages = state?.messages;

        if (currentMessages?.[conversationId]) {
          delete currentMessages[conversationId];
        }

        state.messages = currentMessages;
      } catch (err) {
        console.log(err);
      }
    },

    // Find create conversation
    findCreateConversationSuccess: (state, action: PayloadAction<TFindCreateConversationData>) => {
      const conversationData: TFindCreateConversationData = action.payload;

      state.startCreateConversation = conversationData;
    },
    // List one to one chats
    startListOneToOneChatsLoader: (state) => {
      state.listOneToOneChatsLoader = true;
    },
    stopListOneToOneChatsLoader: (state) => {
      state.listOneToOneChatsLoader = false;
    },
    listOneToOneChatsSuccess: (
      state,
      action: PayloadAction<{
        offset: number;
        filterString?: string;
        chats: TListOneToOneChatsResponseData[];
      }>
    ) => {
      const { chats, offset, filterString } = action.payload;

      state.oneToOneChatLists = chats;

      if (!filterString && offset === 1 && chats?.length === 0) {
        state.isDirectChatNotInitiated = true;
      }

      if (chats?.length > 0) {
        state.isDirectChatNotInitiated = false;
      }
    },
    /*
     * Clear chat lists
     */
    clearChatLists: (state) => {
      state.oneToOneChatLists = [];
      state.listIdeaChats = [];
    },
    // One to one increment page

    listIdeaChatsSuccess: (state, action: PayloadAction<TListIdeaChatsResponseData[]>) => {
      const ideaChats: TListIdeaChatsResponseData[] = action.payload;

      if (ideaChats?.length > 0) {
        state.isIdeaDirectChatNotInitiated = false;
      }

      state.listIdeaChats = ideaChats;
    },
    updateOneToOneRecipientUser: (
      state,
      action: PayloadAction<{ id: string; name: string; role: string; profilePic: string }>
    ) => {
      const user = action.payload;
      state.currentOneToOneRecipientUser = user;
    },
    // One to one conversations
    getOneToOneConversationMessagesSuccess: (
      state,
      action: PayloadAction<{
        messages: TGetOneToOneConversationMessagesResponseMessage[];
        conversationId: string;
        offset: number;
      }>
    ) => {
      try {
        const { conversationId, messages, offset } = action.payload;
        const currentConversationMessages = state?.messages?.[conversationId];

        if (offset === 1) {
          state.messages[conversationId] = messages?.reverse();
        } else {
          const conversationMessages: TReceiveMessageResponse[] = currentConversationMessages
            ? JSON.parse(JSON.stringify(currentConversationMessages))
            : [];

          messages.forEach((message) => {
            const indexInExistingMessages: number = conversationMessages.findIndex(
              (messageF) => messageF?.id === message?.id
            );

            if (indexInExistingMessages < 0) {
              conversationMessages.unshift(message);
            }
          });

          state.messages[conversationId] = conversationMessages;
        }

        if (messages?.length === 0) {
          state.oneToOneChatMessagesMoreItemsLeft = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Increment one to one chats page numer
     */
    incrementOneToOneMessagesPageNumber: (state, action: PayloadAction<number>) => {
      state.oneToOneChatMessagesPageNumber = state.oneToOneChatMessagesPageNumber + action.payload;
    },
    /*
     *
     */
    resetMessages: (state) => {
      state.oneToOneChatMessagesPageNumber = 1;
      state.ideaChatMessagesPageNumber = 1;
      state.oneToOneChatMessagesMoreItemsLeft = true;
      state.ideaChatMessagesMoreItemsLeft = true;
      state.messages = {};
    },

    // Group chats
    getIdeaGroupsSuccess: (state, action: PayloadAction<TGetIdeaGroup[]>) => {
      const groups = action.payload;

      state.ideaGroups = groups;
    },
    getGroupsSuccess: (state, action: PayloadAction<TGetGroup[]>) => {
      const groups = action.payload;

      state.Groups = groups;
    },
    resetIdeaGroups: (state) => {
      state.ideaGroups = [];
    },
    getIdeaGroupsParticipantsSuccess: (
      state,
      action: PayloadAction<TGetIdeaConversationParticipantsResponseData>
    ) => {
      const ideaGroups = action.payload;

      state.ideaGroupParticipants = ideaGroups?.users || [];
    },
    getGroupsParticipantsSuccess: (
      state,
      action: PayloadAction<TGetConversationParticipantsResponseData>
    ) => {
      const Groups = action.payload;

      state.GroupParticipants = Groups?.users || [];
    },
    resetIdeaGroupsParticipants: (state) => {
      state.ideaGroupParticipants = [];
    },
    // Idea conversations
    getIdeaConversationMessagesSuccess: (
      state,
      action: PayloadAction<{
        messages: TGetIdeaConversationMessagesResponseMessage[];
        conversationId: string;
        offset: number;
      }>
    ) => {
      try {
        const { conversationId, messages, offset } = action.payload;
        const currentConversationMessages = state?.messages?.[conversationId];

        if (offset === 1) {
          state.messages[conversationId] = messages?.reverse();
        } else {
          const conversationMessages: TReceiveMessageResponse[] = currentConversationMessages
            ? JSON.parse(JSON.stringify(currentConversationMessages))
            : [];

          messages.forEach((message) => {
            const indexInExistingMessages: number = conversationMessages.findIndex(
              (messageF) => messageF?.id === message?.id
            );

            if (indexInExistingMessages < 0) {
              conversationMessages.unshift(message);
            }
          });

          state.messages[conversationId] = conversationMessages;
        }

        if (messages?.length === 0) {
          state.ideaChatMessagesMoreItemsLeft = false;
          state.oneToOneChatMessagesMoreItemsLeft = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Increment idea chat page number
     */
    incrementIdeaChatMessagesPageNumber: (state, action: PayloadAction<number>) => {
      state.ideaChatMessagesPageNumber = state.ideaChatMessagesPageNumber + action.payload;
    },
    /*
     * Edit idea message success
     */
    editIdeaMessageSuccess: (state, action: PayloadAction<TEditIdeaMessageResponse>) => {
      try {
        const updatedMessage = action.payload;
        const conversationId: string = updatedMessage?.conversationId;
        const messageId: string = updatedMessage?.id;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages] = updatedMessage;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch (err) {
        console.log(err);
      }
    },
    /*
     * Delete idea message success
     */
    deleteIdeaMessageSuccess: (state, action: PayloadAction<TDeleteIdeaMessageResponse>) => {
      try {
        const deletedMessage = action.payload;
        const conversationId: string = deletedMessage?.conversationId;
        const messageId: string = deletedMessage?.id;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages.splice(indexInMessages, 1);
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch (err) {
        console.log(err);
      }
    },
    // Idea groups
    searchUsersToAddIdeaGroupSuccess: (
      state,
      action: PayloadAction<TSearchUsersToAddIdeaGroupResponseData>
    ) => {
      try {
        const { users, currentPage, totalPages } = action.payload;

        if (parseInt(currentPage) === 1) {
          state.searchUsersToAddIdeaGroupList = users;
        } else {
          const currentUsers: TSearchUsersToAddIdeaGroupUser[] = JSON.parse(
            JSON.stringify(state.searchUsersToAddIdeaGroupList)
          );

          users.forEach((user) => {
            const indexInCurrentUsers = currentUsers.findIndex(
              (cUser) => cUser?.userId === user?.userId
            );

            if (indexInCurrentUsers < 0) {
              currentUsers.push(user);
            }
          });

          state.searchUsersToAddIdeaGroupList = currentUsers;
        }

        state.searchUsersToAddIdeaGroupMoreItemsLeft = parseInt(currentPage) < totalPages;
      } catch {}
    },
    incrementSearchUsersToAddIdeaGroupList: (state, action: PayloadAction<number>) => {
      state.searchUsersToAddIdeaGroupPageNumber =
        state.searchUsersToAddIdeaGroupPageNumber + action.payload;
    },
    resetSearchUsersToAddIdeaGroupSuccess: (state) => {
      state.searchUsersToAddIdeaGroupList = [];
      state.searchUsersToAddIdeaGroupPageNumber = 1;
      state.searchUsersToAddIdeaGroupMoreItemsLeft = true;
    },
    /**
     * Program groups
     **/
    searchUsersToAddProgramGroupSuccess: (
      state,
      action: PayloadAction<TSearchUsersToAddProgramGroupResponseData>
    ) => {
      try {
        const { users, currentPage, totalPages } = action.payload;

        if (currentPage === 1) {
          state.searchUsersToAddProgramGroupList = users;
        } else {
          const currentUsers: TSearchUsersToAddProgramGroupUser[] = JSON.parse(
            JSON.stringify(state.searchUsersToAddProgramGroupList)
          );

          users.forEach((user) => {
            const indexInCurrentUsers = currentUsers.findIndex(
              (cUser) => cUser?.userId === user?.userId
            );

            if (indexInCurrentUsers < 0) {
              currentUsers.push(user);
            }
          });

          state.searchUsersToAddProgramGroupList = currentUsers;
        }

        state.searchUsersToAddProgramGroupMoreItemsLeft = currentPage < totalPages;
      } catch {}
    },
    resetSearchUsersToAddProgramGroupSuccess: (state) => {
      state.searchUsersToAddProgramGroupList = [];
      state.searchUsersToAddProgramGroupPageNumber = 1;
      state.searchUsersToAddProgramGroupMoreItemsLeft = true;
    },

    // Program conversations
    getProgramGroupsSuccess: (
      state,
      action: PayloadAction<{
        programConversations: TGetProgramGroup[];
        programDiscussion: TGetProgramGroup;
      }>
    ) => {
      const { programConversations, programDiscussion } = action.payload;

      state.programGroups = programConversations;
      if (programDiscussion) {
        state.programDiscussion = programDiscussion;
      }
    },
    getProgramGroupsParticipantsSuccess: (
      state,
      action: PayloadAction<TGetProgramGroupParticipantsResponse>
    ) => {
      const { users } = action.payload;

      state.programGroupParticipants = users;
    },
    getProgramConversationMessagesSuccess: (
      state,
      action: PayloadAction<{
        messages: TGetProgramConversationMessagesResponseMessage[];
        conversationId: string;
      }>
    ) => {
      try {
        const { conversationId, messages } = action.payload;
        const currentConversationMessages = state?.messages?.[conversationId];
        const conversationMessages: TReceiveMessageResponse[] = currentConversationMessages
          ? JSON.parse(JSON.stringify(currentConversationMessages))
          : [];

        messages.forEach((message) => {
          const indexInExistingMessages: number = conversationMessages.findIndex(
            (messageF) => messageF?.id === message?.id
          );

          if (indexInExistingMessages < 0) {
            conversationMessages.unshift(message);
          }
        });

        state.messages[conversationId] = conversationMessages;

        if (messages?.length === 0) {
          state.ideaChatMessagesMoreItemsLeft = false;
          state.oneToOneChatMessagesMoreItemsLeft = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    likeConversationMessageSuccess: (
      state,
      action: PayloadAction<TLikeConversationMessageSuccess>
    ) => {
      try {
        const { conversationId, messageId } = action.payload;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );
        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages].likeCount =
            (currentConversationMessages[indexInMessages].likeCount || 0) + 1;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch {}
    },
    likeMessageSelf: (state, action: PayloadAction<TLikeConversationMessageSuccess>) => {
      try {
        const { conversationId, messageId } = action.payload;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );
        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages].isLiked = true;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch {}
    },
    dislikeMessageSuccess: (state, action: PayloadAction<TDislikeConversationMessageSuccess>) => {
      try {
        const { conversationId, messageId } = action.payload;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages].likeCount--;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch {}
    },
    dislikeMessageSelf: (state, action: PayloadAction<TDislikeConversationMessageSuccess>) => {
      try {
        const { conversationId, messageId } = action.payload;

        const currentConversationMessages = state?.messages?.[conversationId];

        const indexInMessages: number = currentConversationMessages.findIndex(
          (item) => item.id === messageId
        );

        if (indexInMessages > -1) {
          currentConversationMessages[indexInMessages].isLiked = false;
        }

        state.messages[conversationId] = currentConversationMessages;
      } catch {}
    },
    uploadChatFileSuccess: (state, action: PayloadAction<TUploadChatFileData>) => {
      const file = action.payload;
      try {
        const currentFiles: { id: string; fileName: string }[] = JSON.parse(
          JSON.stringify(state.filesToUpload)
        );
        currentFiles.push(file);

        state.filesToUpload = currentFiles;
      } catch {}
    },
    removeChatFile: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      try {
        const currentFiles: { id: string; fileName: string }[] = JSON.parse(
          JSON.stringify(state.filesToUpload)
        );

        const indexInCurrentFiles = currentFiles.findIndex((file) => file.id === id);

        if (indexInCurrentFiles > -1) {
          currentFiles.splice(indexInCurrentFiles, 1);
        }

        state.filesToUpload = currentFiles;
      } catch {}
    },
    clearChatFiles: (state) => {
      state.filesToUpload = [];
    },
    // Loaders
    startChatGroupsLoader: (state) => {
      state.getChatGroupsLoader = true;
    },
    stopChatGroupsLoader: (state) => {
      state.getChatGroupsLoader = false;
    },
    startChatsLoader: (state) => {
      state.chatLoader = true;
    },
    stopChatsLoader: (state) => {
      state.chatLoader = false;
    },
    setNotificationNumber: (state, action: PayloadAction<number>) => {
      state.chatNotificationNumbers = action.payload;
    }
  }
});

export const {
  sendDirectMessageSuccess,
  receiveMessageSuccess,
  editDirectMessageSuccess,
  deleteDirectMessageSuccess,
  clearDirectMessageSuccess,
  listOneToOneChatsSuccess,
  clearChatLists,
  listIdeaChatsSuccess,
  startListOneToOneChatsLoader,
  stopListOneToOneChatsLoader,
  findCreateConversationSuccess,
  getOneToOneConversationMessagesSuccess,
  incrementOneToOneMessagesPageNumber,
  resetMessages,
  updateOneToOneRecipientUser,
  getIdeaGroupsSuccess,
  getGroupsSuccess,
  resetIdeaGroups,
  getIdeaGroupsParticipantsSuccess,
  getGroupsParticipantsSuccess,
  resetIdeaGroupsParticipants,
  getIdeaConversationMessagesSuccess,
  incrementIdeaChatMessagesPageNumber,
  getProgramGroupsSuccess,
  getProgramConversationMessagesSuccess,
  searchUsersToAddIdeaGroupSuccess,
  searchUsersToAddProgramGroupSuccess,
  resetSearchUsersToAddProgramGroupSuccess,
  incrementSearchUsersToAddIdeaGroupList,
  resetSearchUsersToAddIdeaGroupSuccess,
  editIdeaMessageSuccess,
  deleteIdeaMessageSuccess,
  likeConversationMessageSuccess,
  likeMessageSelf,
  dislikeMessageSuccess,
  dislikeMessageSelf,
  uploadChatFileSuccess,
  removeChatFile,
  clearChatFiles,
  startChatGroupsLoader,
  stopChatGroupsLoader,
  startChatsLoader,
  stopChatsLoader,
  getProgramGroupsParticipantsSuccess,
  setNotificationNumber
} = chatSlice.actions;

export default chatSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error',
      ...(message === errorMessagesEnum.USER_ALREADY_EXISTS && {
        navigateTo: '/login?prev=/sign-up'
      })
    })
  );
};

export const sendDirectMessage =
  (payload: TSendDirectMessageRequest) => async (dispatch: AppDispatch) => {
    const { eventName, ...messagePayload } = payload;
    console.log(eventName, "response")
    try {
      socketService.emit(eventName, messagePayload, (response: { success: boolean; error?: string; data?: string }) => {
       if (!response.success) {
        dispatch(
          openPopup({
            popupMessage:
              errorMessages[response.error as keyof typeof errorMessages] ||
              response.error ||
              errorMessages.unknownError,
            popupType: 'error'
            // ...(response.error === errorMessagesEnum.USER_ALREADY_EXISTS && {
            //   navigateTo: '/login?prev=/sign-up'
            // })
          })
        );
       }
      });
      dispatch(clearChatFiles());
    } catch (err) {
      console.log(err);
    }
  };
export const editDirectMessage =
  (payload: TEditDirectMessageRequest) => async (dispatch: AppDispatch) => {
    const { eventName, ...messagePayload } = payload;
    try {
      socketService.emit(eventName, messagePayload);
      dispatch(clearChatFiles());
    } catch (err) {
      console.log(err);
    }
  };

export const editIdeaMessage =
  (payload: TEditIdeaMessageRequest) => async (dispatch: AppDispatch) => {
    const { eventName, ...messagePayload } = payload;
    try {
      socketService.emit(eventName, messagePayload);
      dispatch(clearChatFiles());
    } catch (err) {
      console.log(err);
    }
  };

export const deleteDirectMessage =
  (payload: TDeleteDirectMessageRequest) => async (dispatch: AppDispatch) => {
    try {
      const { eventName, ...messagePayload } = payload;
      socketService.emit(eventName, messagePayload);
    } catch (err) {
      console.log(err);
    }
  };

export const deleteIdeaMessage =
  (payload: TDeleteIdeaMessageRequest) => async (dispatch: AppDispatch) => {
    const { eventName, ...messagePayload } = payload;
    try {
      socketService.emit(eventName, messagePayload);
    } catch (err) {
      console.log(err);
    }
  };

export const clearDirectMessageChat =
  (payload: TClearDirectMessageChatRequest) => async (dispatch: AppDispatch) => {
    try {
      socketService.emit('clear-conversation', payload);
      dispatch(clearDirectMessageSuccess({ conversationId: payload?.conversationId }));
    } catch (err) {
      console.log(err);
    }
  };

export const listOneToOneChats =
  (payload: TListOneToOneChatsRequest) => async (dispatch: AppDispatch) => {
    dispatch(startListOneToOneChatsLoader());
    try {
      const { status, data }: APIResponse<TListOneToOneChatsResponse> =
        await new ChatAPIService().listOneToOneChats(payload);

      if (status === 200 && data) {
        dispatch(
          listOneToOneChatsSuccess({
            chats: data?.conversations,
            offset: payload?.offset,
            filterString: payload.filterString
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopListOneToOneChatsLoader());
    }
  };

export const listIdeaChats = (payload: TListIdeaChatsRequest) => async (dispatch: AppDispatch) => {
  dispatch(startListOneToOneChatsLoader());
  try {
    const { status, data }: APIResponse<TListIdeaChatsResponse> =
      await new ChatAPIService().listIdeaChats(payload);

    if (status === 200 && data) {
      dispatch(listIdeaChatsSuccess(data?.conversations));
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(stopListOneToOneChatsLoader());
  }
};
export const createGroup =
  (payload: TCreateGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, userType, ...rest } = payload;

    const createIdeaGroupPayload: TCreateGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TCreateGroupResponse> =
        await new ChatAPIService().createGroup(payload);
      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully created the group!',
            popupType: 'success'
          })
        );
        dispatch(getGroups({ userId, userType }));
        // dispatch(getIdeaGroups({ ideaId: payload?.ideaId, userId, userType }));
      } else if (status === 203) {
        dispatch(
          openPopup({
            popupMessage: 'A conversation with this Cohort already exists!',
            popupType: 'error'
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const findCreateConversation =
  (payload: TFindCreateConversationRequest) => async (dispatch: AppDispatch) => {
    dispatch(startListOneToOneChatsLoader());
    try {
      const { status, data }: APIResponse<TFindCreateConversationResponse> =
        await new ChatAPIService().findCreateConversation(payload);

      if (status === 200 && data) {
        dispatch(findCreateConversationSuccess(data?.conversation));
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopListOneToOneChatsLoader());
    }
  };

export const getOneToOneConversationMessages =
  (payload: TGetOneToOneConversationMessagesRequest) => async (dispatch: AppDispatch) => {
    dispatch(startItemListLoader());
    try {
      const { status, data }: APIResponse<TGetOneToOneConversationMessagesResponse> =
        await new ChatAPIService().getOneToOneConversationMessages(payload);

      if (status === 200 && data) {
        dispatch(
          getOneToOneConversationMessagesSuccess({
            messages: data?.messages,
            conversationId: payload.conversationId,
            offset: payload.offset
          })
        );
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopItemListLoader());
    }
  };

export const getIdeaGroups = (payload: TGetIdeaGroupsRequest) => async (dispatch: AppDispatch) => {
  dispatch(startChatGroupsLoader());
  try {
    const { status, data }: APIResponse<TGetIdeaGroupsResponse> =
      await new ChatAPIService().getIdeaGroups(payload);

    if (status === 200 && data?.ideaConversations) {
      dispatch(getIdeaGroupsSuccess(data?.ideaConversations));
      return data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(stopChatGroupsLoader());
  }
};

export const getGroups = (payload: TGetGroupsRequest) => async (dispatch: AppDispatch) => {
  dispatch(startChatGroupsLoader());
  try {
    const { status, data }: APIResponse<TGetGroupsResponse> =
      await new ChatAPIService().getGroups(payload);

    if (status === 200 && data?.Conversations) {
      dispatch(getGroupsSuccess(data?.Conversations));
      return data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(stopChatGroupsLoader());
  }
};

export const createIdeaGroup =
  (payload: TCreateIdeaGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, userType, ...rest } = payload;

    const createIdeaGroupPayload: TCreateIdeaGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TCreateIdeaGroupResponse> =
        await new ChatAPIService().createIdeaGroup(createIdeaGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully created the group!',
            popupType: 'success'
          })
        );
        dispatch(getIdeaGroups({ ideaId: payload?.ideaId, userId, userType }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const editIdeaGroup =
  (payload: TEditIdeaGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, userType, ideaId, ...rest } = payload;

    const editIdeaGroupPayload: TEditIdeaGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TEditIdeaGroupResponse> =
        await new ChatAPIService().editIdeaGroup(editIdeaGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated group details!',
            popupType: 'success'
          })
        );
        if (ideaId) {
        dispatch(getIdeaGroups({ ideaId, userId, userType }));
        } else {
          dispatch(getGroups({ userId, userType }));
        }

        return status;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteIdeaGroup =
  (payload: TDeleteIdeaGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, ideaId, userType, ...rest } = payload;

    const deleteIdeaGroupPayload: TDeleteIdeaGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TDeleteIdeaGroupResponse> =
        await new ChatAPIService().deleteIdeaGroup(deleteIdeaGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted the group!',
            popupType: 'error'
          })
        );
        if (ideaId) {
        dispatch(getIdeaGroups({ ideaId, userId, userType }));
        } else {
          dispatch(getGroups({ userId, userType }));
        }
        return status;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const getIdeaGroupParticipants =
  (payload: TGetIdeaConversationParticipantsRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TGetIdeaConversationParticipantsResponse> =
        await new ChatAPIService().getIdeaConversationParticipants(payload);

      if (status === 200 && data) {
        dispatch(getIdeaGroupsParticipantsSuccess(data));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

  export const getGroupParticipants =
  (payload: TGetConversationParticipantsRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TGetConversationParticipantsResponse> =
        await new ChatAPIService().getConversationParticipants(payload);

      if (status === 200 && data) {
        dispatch(getGroupsParticipantsSuccess(data));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const getProgramGroupParticipants =
  (payload: TGetProgramGroupParticipantsRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TGetProgramGroupParticipantsResponse> =
        await new ChatAPIService().getProgramGroupParticipants(payload);

      if (status === 200 && data) {
        dispatch(getProgramGroupsParticipantsSuccess(data));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const sendIdeaGroupMessage =
  (payload: TSendIdeaGroupMessageRequest) => async (dispatch: AppDispatch) => {
    const { eventName, ...messagePayload } = payload;
    try {
      socketService.emit(eventName, messagePayload);
    } catch (err) {
      console.log(err);
    }
  };

export const getIdeaConversationMessages =
  (payload: TGetIdeaConversationMessagesRequest) => async (dispatch: AppDispatch) => {
    dispatch(startItemListLoader());
    try {
      const { status, data }: APIResponse<TGetIdeaConversationMessagesResponse> =
        await new ChatAPIService().getIdeaConversationMessages(payload);

      if (status === 200 && data) {
        dispatch(
          getIdeaConversationMessagesSuccess({
            messages: data?.messages,
            conversationId: payload.conversationId,
            offset: payload?.offset
          })
        );
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopItemListLoader());
    }
  };

export const sendProgramGroupMessage =
  (payload: TSendIdeaGroupMessageRequest) => async (dispatch: AppDispatch) => {
    try {
      socketService.emit('program-conversation', payload);
    } catch (err) {
      console.log(err);
    }
  };

export const getProgramConversationMessages =
  (payload: TGetProgramConversationMessagesRequest) => async (dispatch: AppDispatch) => {
    dispatch(startChatsLoader());
    try {
      const { status, data }: APIResponse<TGetProgramConversationMessagesResponse> =
        await new ChatAPIService().getProgramConversationMessages(payload);

      if (status === 200 && data) {
        dispatch(
          getProgramConversationMessagesSuccess({
            messages: data?.messages,
            conversationId: payload.conversationId
          })
        );
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(startChatsLoader());
    }
  };

export const getProgramGroups =
  (payload: TGetProgramGroupsRequest) => async (dispatch: AppDispatch) => {
    dispatch(startChatGroupsLoader());
    try {
      const { status, data }: APIResponse<TGetProgramGroupsResponse> =
        await new ChatAPIService().getProgramGroups(payload);

      if (status === 200 && data) {
        dispatch(getProgramGroupsSuccess(data));
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopChatGroupsLoader());
    }
  };

export const createProgramGroup =
  (payload: TCreateProgramGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, userType, ...rest } = payload;

    const createProgramGroupPayload: TCreateProgramGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TCreateProgramGroupResponse> =
        await new ChatAPIService().createProgramGroup(createProgramGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully created the group!',
            popupType: 'success'
          })
        );
        dispatch(getProgramGroups({ programId: payload?.programId, userId, userType }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const editProgramGroup =
  (payload: TEditProgramGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { programId, userId, userType, ...editProgramGroupPayload } = payload;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TEditIdeaGroupResponse> =
        await new ChatAPIService().editProgramGroup(editProgramGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated group details!',
            popupType: 'success'
          })
        );
        dispatch(getProgramGroups({ programId, userId, userType }));

        return status;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteProgramGroup =
  (payload: TDeleteProgramGroupActionRequest) => async (dispatch: AppDispatch) => {
    const { userId, programId, userType, ...rest } = payload;

    const deleteProgramGroupPayload: TDeleteProgramGroupRequest = rest;

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TDeleteProgramGroupResponse> =
        await new ChatAPIService().deleteProgramGroup(deleteProgramGroupPayload);

      if (status === 200 || status === 201) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted the group!',
            popupType: 'error'
          })
        );
        dispatch(getProgramGroups({ programId, userId, userType }));
        return status;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const searchUsersToAddIdeaGroup =
  (payload: TSearchUsersToAddIdeaGroupRequest) => async (dispatch: AppDispatch) => {
    dispatch(startItemListLoader());
    try {
      const { status, data }: APIResponse<TSearchUsersToAddIdeaGroupResponse> =
        await new ChatAPIService().searchUsersToAddIdeaGroup(payload);

      if (status === 200 && data) {
        dispatch(searchUsersToAddIdeaGroupSuccess(data));
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopItemListLoader());
    }
  };

export const searchUsersToAddProgramGroup =
  (payload: TSearchUsersToAddProgramGroupRequest) => async (dispatch: AppDispatch) => {
    dispatch(startItemListLoader());
    try {
      const { status, data }: APIResponse<TSearchUsersToAddProgramGroupResponse> =
        await new ChatAPIService().searchUsersToAddProgramGroup(payload);

      if (status === 200 && data) {
        dispatch(searchUsersToAddProgramGroupSuccess(data));
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopItemListLoader());
    }
  };

export const likeConversationMessage =
  (payload: TLikeConversationMessageRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { eventName, ...likeMessagePayload } = payload;

      socketService.emit(eventName, likeMessagePayload);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const likeIdeaConversationMessage =
  (payload: TLikeIdeaConversationMessageRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status }: APIResponse<TLikeIdeaConversationMessageResponse> =
        await new ChatAPIService().likeIdeaConversationMessage(payload);

      if (status === 200 || status === 201) {
        console.log(status);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const dislikeConversationMessage =
  (payload: TDislikeConversationMessageRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { eventName, ...disLikMessagePayload } = payload;

      socketService.emit(eventName, disLikMessagePayload);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const dislikeIdeaConversationMessage =
  (payload: TDislikeIdeaConversationMessageRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status }: APIResponse<TDislikeIdeaConversationMessageResponse> =
        await new ChatAPIService().dislikeIdeaConversationMessage(payload);

      if (status === 200 || status === 201) {
        console.log(status);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(stopLoading());
    }
  };

export const uploadChatFile =
  (payload: TUploadChatFileToS3ActionRequest) => async (dispatch: AppDispatch) => {
    const { signedUrl, fileData, fileName, id } = payload;

    const uploadFilePayloadRequest: TUploadChatFileToS3Request = {
      signedUrl,
      fileData
    };

    dispatch(startLoading());
    try {
      const { status }: APIResponse<TUploadChatFileToS3Response> =
        await new ChatAPIService().uploadChatFile(uploadFilePayloadRequest);
      if (status === 200) {
        dispatch(uploadChatFileSuccess({ id, fileName }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getChatFilePresignedUrl =
  (payload: TGetChatFilePresignedUrlActionRequest) => async (dispatch: AppDispatch) => {
    const { fileData, ...getChatFilePresignedUrlPayload } = payload;
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TGetChatFilePresignedUrlResponse> =
        await new ChatAPIService().getChatFilePresignedUrl(getChatFilePresignedUrlPayload);

      if (status === 200 && data?.data) {
        const uploadPayload: TUploadChatFileToS3ActionRequest = {
          signedUrl: data?.data,
          ...payload
        };
        dispatch(uploadChatFile(uploadPayload));
      }
    } catch (err: unknown) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const downloadChatFile =
  (payload: TDownloadChatFileRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TDownloadChatFileResponse> =
        await new ChatAPIService().downloadChatFile(payload);

      if (status === 200 && data) {
        window.open(data?.data);
      }
    } catch (err: unknown) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };
