import { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { useGetIdentity } from 'src/hooks';

import {
  TIdeaDetailsSections,
  TIdeaCollaborationType,
  TIdeaStatus,
  TIdeaViewType,
  TIdeaSharedFor
} from '../..';
export type TUseAllowedActionsProps = {
  ideaStatus: TIdeaStatus;
  ideaCollaborationType: TIdeaCollaborationType;
  currentIdeaDetailsSection: TIdeaDetailsSections;
  ideaSharedFor?: TIdeaSharedFor;
  viewType: TIdeaViewType;
};

const useAllowedActions = ({
  ideaStatus,
  ideaCollaborationType,
  currentIdeaDetailsSection,
  ideaSharedFor,
  viewType
}: TUseAllowedActionsProps) => {
  // Hooks
  const role = useAppSelector((store) => store.profile.profile.role);
  const viewIdea = useAppSelector((store) => store.ideaHub.viewIdea);
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId } = getIdentities();

  const isOwner: boolean = loggedInUserId === viewIdea?.idea?.owner?.id;

  const checkEditButtonVisibility = () => {
    /*
     * Checks hierarchy
     * Finalized
     * Empty Idea
     * Edit access
     */

    if (ideaStatus === 'Finalized') {
      return false;
    }
    if (viewType === 'editIdea') {
      return false;
    }
    if (viewType === 'emptyIdea') {
      return true;
    }
    if (isOwner) {
      return true;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (ideaCollaborationType === 'Edit') {
      return true;
    }
    return false;
  };

  const checkCanUserEditIdea = () => {
    if (isOwner) {
      return true;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (ideaCollaborationType === 'Edit') {
      return true;
    }
    return false;
  };

  const checkFinalizeButtonVisibility = () => {
    /*
     * Checks hierarchy
     * Finalized -> hide
     * Empty Idea -> hide
     * Edit access -> Only owner can finalize the idea
     */
    if (ideaStatus === 'Finalized') {
      return false;
    }
    if (viewType === 'editIdea') {
      return false;
    }
    if (viewType === 'emptyIdea') {
      return false;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (isOwner) {
      return true;
    }

    return false;
  };

  const canUploadDocumentVisibility = () => {
    if (viewType === 'emptyIdea') {
      return false;
    }
    if (ideaStatus === 'Finalized') {
      return false;
    }
    if (isOwner) {
      return true;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (ideaCollaborationType === 'Edit') {
      return true;
    }

    return false;
  };

  const checkShareManageAccessButtonsVisibility = () => {
    /*
     * Checks hierarchy
     * Finalized
     * Empty Idea
     * Edit access
     */
    if (viewType === 'emptyIdea') {
      return false;
    }
    if (viewType === 'editIdea') {
      return false;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (loggedInUserId === viewIdea?.idea?.owner?.id) {
      return true;
    }

    return false;
  };
  const checkShareManageAccessVisibilityEditDetails = () => {
    // This button is present in Idea hub -> Idea -> Idea details -> Edit details
    /*
     * Checks hierarchy
     * Finalized
     * Empty Idea
     * Edit access
     */
    if (viewType === 'emptyIdea') {
      return false;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }
    if (loggedInUserId === viewIdea?.idea?.owner?.id) {
      return true;
    }

    return false;
  };

  const checkNextButtonVisibility = () => {
    // if (currentIdeaDetailsSection === 'BusinessModelCompetitors') {
    //   return false;
    // }
    if (viewType === 'editIdea' && ideaCollaborationType === 'Edit') {
      return true;
    }
    if (viewType === 'editIdea' && ideaCollaborationType === 'CoFounder') {
      return true;
    }
    return false;
  };

  const checkDiscardIdeaButtonVisibility = () => {
    if (viewType === 'viewIdea') {
      return false;
    }
    if (viewType === 'emptyIdea') {
      return false;
    }
    if (currentIdeaDetailsSection !== 'details') {
      return false;
    }
    if (isOwner) {
      return true;
    }
    if (ideaCollaborationType === 'CoFounder') {
      return true;
    }

    return false;
  };

  const checkSaveButtonVisibility = () => {
    if (
      viewType === 'editIdea' &&
      ideaCollaborationType === 'Edit' &&
      currentIdeaDetailsSection === 'details'
    ) {
      return true;
    }
    if (
      viewType === 'editIdea' &&
      ideaCollaborationType === 'CoFounder' &&
      currentIdeaDetailsSection === 'details'
    ) {
      return true;
    }
    return false;
  };

  const checkRateIdeaButtonVisibility = () => {
    if (viewIdea?.idea?.owner?.id === loggedInUserId) {
      return false;
    }
    if (viewType === 'emptyIdea' || viewType === 'editIdea') {
      return false;
    }
    if (ideaSharedFor === 'Evaluation') {
      return true;
    }
    return false;
  };

  const hasAccessToDiscussion = () => {
    if (viewIdea?.userCollaborationType === 'View') {
      return false;
    }
    if (viewIdea?.sharedFor === 'Evaluation') {
      return false;
    }
    return true;
  };

  const checkButtonsVisibility = () => {
    const showEditButton = checkEditButtonVisibility();
    const showFinalizeButton = checkFinalizeButtonVisibility();
    const showShareManageAccessButtons = checkShareManageAccessButtonsVisibility();
    const showShareManageAccessButtonsEditDetails = checkShareManageAccessVisibilityEditDetails();
    const showNextButton = checkNextButtonVisibility();
    const showDiscardIdeaButton = checkDiscardIdeaButtonVisibility();
    const showSaveButton = checkSaveButtonVisibility();
    const showRateIdeaButton = checkRateIdeaButtonVisibility();
    const showUploadButton = canUploadDocumentVisibility();
    const canUserEditIdea = checkCanUserEditIdea();
    const canUserAccessDiscussion = hasAccessToDiscussion();

    return {
      showEditButton,
      showFinalizeButton,
      showShareManageAccessButtons,
      showShareManageAccessButtonsEditDetails,
      showNextButton,
      showDiscardIdeaButton,
      showSaveButton,
      showRateIdeaButton,
      showUploadButton,
      canUserEditIdea,
      canUserAccessDiscussion
    };
  };

  useEffect(() => {
    checkButtonsVisibility();
  }, [ideaStatus, ideaCollaborationType, currentIdeaDetailsSection, ideaSharedFor, role]);

  return { checkButtonsVisibility };
};

export default useAllowedActions;
