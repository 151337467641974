import React, { useEffect } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { dotMenu } from 'src/assets/images';
import cx from './companyEvents.module.scss';
import EventCard from 'src/components/MarketPlace/EventCard/EventCard';
import { Button, Loader } from 'src/components/common';
import { MdAdd } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changeCreationModalState, clearMarketPlaceItems, getCommonMarketPlaceItems } from 'src/store/marketplace/marketplace.slice';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { useUrlParamValue } from 'src/hooks';
import EventServiceProviderModal from 'src/components/MarketPlace/EventServiceProviderModal/EventServiceProviderModal';

export default function CompanyEvents({ isViewerProgramCreator = true }: { isViewerProgramCreator?: boolean }) {
    const dispatch = useAppDispatch();
    const { paramValue } = useUrlParamValue();
    const { serviceProviderCommonModal, globalLoading, Event } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);

    const viewCompanyId = paramValue({ paramName: 'id' }) as unknown as string;

    const handleEventsAdd = () => {
        dispatch(changeCreationModalState({ state: true, dataType: 'Event', id: '' }))
    }
    useEffect(() => {
        if (myCompany?.id && !viewCompanyId) {
            const payload = {
                dataType: 'Event',
                companyId: myCompany?.id
            }
            dispatch(getCommonMarketPlaceItems(payload))
        }
    }, [myCompany?.id, dispatch])

    useEffect(() => {
        if (viewCompanyId) {
            const payload = {
                dataType: 'Event',
                companyId: viewCompanyId
            }
            dispatch(getCommonMarketPlaceItems(payload))
        }
    }, [viewCompanyId, dispatch])
    useEffect(() => {
        return () => {
            dispatch(clearMarketPlaceItems({ type: 'Event' }))
            dispatch(changeCreationModalState({ state: false, dataType: '', id: '' }))
        }
    }, [])
    if (globalLoading === 'Event') {
        return <Loader />
    }

    return (
        <>
            {serviceProviderCommonModal.state && <EventServiceProviderModal />}
            <div className={`${cx.companyevents}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${cx.eventsDetails}`}>
                            <h5 className={`${cx.eventName}`}>Events</h5>
                            {isViewerProgramCreator && <Button
                                variant="secondary"
                                size="small"
                                className="create-edit-group__buttons__close"
                                onClick={() => handleEventsAdd()}
                            >
                                <MdAdd /> Add Events
                            </Button>}
                            {/* <div className={cx.dotDropdown}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic-6">
                                    <img src={dotMenu} className={cx.dots} alt="dot" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <p className={cx.dotDropInside} >
                                            <span>Add Events</span>
                                        </p>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={`${cx.serviceRow}`}>
                            <Row>
                                {Event?.map((event, e: number) => {
                                    const { id, organizerName, title, thumbnailUrl, description } = event
                                    return (
                                        <Col md={4} key={e}>
                                            <EventCard isAction={false} isViewerProgramCreator={isViewerProgramCreator} id={id} organizer={organizerName || ''} logo={thumbnailUrl || ''} name={title} description={description} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
