import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Typography } from 'src/components/common';
import './commonMarketplaceModal.scss'
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Card, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { changeCreationModalState, createGlobalItem, getCommonMarketPlaceItems, getPresignedURL } from 'src/store/marketplace/marketplace.slice';
import { UploadIcon } from 'src/components/common/common.icons';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { TGetCompany, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { ViewCompany } from 'src/pages';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import { openPopup } from 'src/store/common/common.slice';

export type TFormValues = {
    title: string;
    description: string;
    mediaType?: string;
    mediaUrl?: string;
    thumbnailUrl?: string | null;
    id?: string
};

export type TCommonValues = {
    companyId: string,
    dataType: string,
};

const initialValues: TFormValues = {
    title: '',
    description: '',
    mediaType: 'image',
    mediaUrl: '',
    thumbnailUrl: ''
};
const globalServiceProviderValidationSchema = yup.object({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    mediaType: yup.string().optional(),
    thumbnailUrl: yup.string().optional(),
    mediaUrl: yup.string().url('Media URL must be a valid URL').optional()
});
const CommonMarketplaceModal = () => {
    const { serviceProviderCommonModal, globalLoading, Product, Portfolio } = useAppSelector((state) => state.marketPlace)
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    // states
    const [bannerLoading, setBannerLoading] = useState<boolean>(false);
    const [initialCommonData, setInitialCommonData] = useState(initialValues)
    // Hooks
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(changeCreationModalState({ state: false, dataType: '', id: '' }))
    };
    const uploadImageRef = useRef<HTMLInputElement>(null);
    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };

    const handleUpload = async (e, setFieldValue) => {
        const file = e.target.files[0];
        setBannerLoading(true)
        if (file) {
            const fileName = handleExtension(file?.name)
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const data = reader.result;
                    // const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
                    //     id: viewCompany?.id,
                    //     data,
                    //     showPopup: false
                    // };
                    const response = await dispatch(getPresignedURL({ companyId: myCompany?.id, fileName }))
                    if (response?.status) {
                        const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                            await new CompanyAPIService().uploadCompanyProfilePicture({
                                presignedUrl: response?.data?.signedUrl,
                                data,
                                includeAuthorizationHeaders: false
                            });
                        if (res.status === 200) {
                            const data = await dispatch(getPresignedURL({ companyId: myCompany?.id, fileName }))
                            if (data?.data?.key) {
                                setFieldValue('thumbnailUrl', data?.data?.key)
                            }
                            setBannerLoading(false)
                        } else {
                            dispatch(
                                openPopup({
                                    popupMessage: res?.message || 'something went wrong',
                                    popupType: 'error'
                                })
                            );
                        }
                    } else {
                        setBannerLoading(false)
                        dispatch(
                            openPopup({
                                popupMessage: 'something went wrong',
                                popupType: 'error'
                            })
                        );
                    }
                } catch (error) {
                    setBannerLoading(false)
                    dispatch(
                        openPopup({
                            popupMessage: error?.message || 'something went wrong',
                            popupType: 'error'
                        })
                    );
                }
            }
        }
    }
    function handleSubmit(values) {
        const payload: TFormValues & TCommonValues = {
            ...values,
            companyId: myCompany?.id,
            dataType: serviceProviderCommonModal.dataType
        }
        dispatch(createGlobalItem(payload)).then((response) => {
            if (response.status) {
                const payload = {
                    dataType: serviceProviderCommonModal.dataType,
                    companyId: myCompany?.id
                }
                dispatch(getCommonMarketPlaceItems(payload))
                closeModal()
            }
        })
    }
    function handleMedia(value: string, setFieldValue: (field: string, value: string) => void) {
        if (!value) {
            setFieldValue('mediaUrl', value);
            return;
        }

        // Check if value is already a valid YouTube embed URL
        if (value.startsWith('https://www.youtube.com/embed/')) {
            setFieldValue('mediaUrl', value);
            return;
        }

        // Extract the src attribute from an iframe string
        const srcMatch = value.match(/src="([^"]+)"/);
        const srcValue = srcMatch ? srcMatch[1] : '';
        setFieldValue('mediaUrl', srcValue);
    }

    useEffect(() => {
        if (serviceProviderCommonModal?.id && (Product?.length > 0 || Portfolio.length > 0)) {
            const conditionalArr = serviceProviderCommonModal.dataType === 'Product' ? Product : Portfolio
            const commonData = conditionalArr?.find((item) => item?.id === serviceProviderCommonModal.id);
            if (commonData) {
                const dataObj = {
                    title: commonData.title || '',
                    description: commonData.description || '',
                    mediaType: 'image',
                    mediaUrl: commonData.mediaUrl || '',
                    thumbnailUrl: commonData.thumbnailUrl || '',
                    id: commonData?.id || ''
                }
                setInitialCommonData(dataObj)
            }
        }
    }, [Product, Portfolio, serviceProviderCommonModal?.id])

    return (
        <Modal
            isModalOpen={serviceProviderCommonModal.state}
            onModalClose={closeModal}
            title={`${serviceProviderCommonModal?.id ? "Update" : "Add"} ${serviceProviderCommonModal.dataType}`}
            className="add__global-modal"
        >
            <div className="add__global">
                {/* Form starts */}
                <Formik
                    initialValues={initialCommonData}
                    onSubmit={handleSubmit}
                    validationSchema={globalServiceProviderValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        console.log(errors, values, 'touched');

                        return (
                            <Form className="add__global__form" noValidate>
                                <div className='add__global__form__crate-form'>
                                    <Row>
                                        <Col md={12}>
                                            <div className="program-details__form__row mb-3">
                                                <div className='w-100'>
                                                    <>
                                                        <InputField
                                                            label="Upload Logo"
                                                            id='banner-label'
                                                            hidden
                                                            variant={componentSizeVariantsEnum.SMALL}
                                                            placeholder="Upload Logo"
                                                            type="text"
                                                        // required
                                                        />

                                                        <div
                                                            className="dynamic-field__upload-file"
                                                        >
                                                            <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                                                                <UploadIcon />
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    value={''}
                                                                    ref={uploadImageRef}
                                                                    accept=".jpg,.jpeg,.png,"
                                                                    className="select-post-image"
                                                                    onChange={(e) => {
                                                                        handleUpload(e, setFieldValue)
                                                                    }}
                                                                />
                                                            </span>
                                                            <Typography
                                                                as="span"
                                                                variant="body-2"
                                                                fontWeight="medium"
                                                                className="dynamic-field__upload-file__browse"
                                                                onClick={openImageFileManager}
                                                            >
                                                                Upload Logo
                                                            </Typography>
                                                        </div>
                                                    </>
                                                    {bannerLoading && <Loader />}

                                                    {values?.thumbnailUrl && !bannerLoading && <Row>
                                                        <Col md={12}>
                                                            <div className='position-relative w-100'>
                                                                <button style={{ position: 'absolute', top: '10px', right: '10px', background: '#fff', height: '23px', fontSize: '14px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: 'none', color: 'red' }} onClick={() => setFieldValue('thumbnailUrl', '')}>
                                                                    X
                                                                </button>
                                                                <img src={values?.thumbnailUrl} alt='logo' style={{ width: '100%', height: '120px', objectFit: 'cover', backgroundPosition: 'center', marginTop: '10px', borderRadius: '10px' }} />
                                                            </div>
                                                        </Col>
                                                    </Row>}

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                            <InputField
                                                required
                                                label="Title"
                                                id="title"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Title"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputField
                                                label="Media Url"
                                                className='mb-3 mt-3'
                                                id="mediaUrl"
                                                value={values.mediaUrl}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMedia(e.target.value, setFieldValue)}
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="https://youtu.be/WpA8vg5PmuQ?si=ri3YtaIi1fhIOC7k"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <h5 className='typography__caption typography__semiBold mb-2'>Description</h5>
                                            <TextArea id='programObjective' placeholder='Description'
                                                required={true}
                                                value={values.description}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('description', e.target.value)}
                                                textAreaStyle={{ width: '100%' }}
                                            />
                                            {touched.description && errors.description && <ErrorMessage message="Description is required" />}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="add__global__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Cancel
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" disabled={globalLoading === 'create'}>
                                        {globalLoading === 'create' ? <Loader /> : serviceProviderCommonModal.id ? "Update" : "Submit"}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >
        </Modal >
    );
};

export default CommonMarketplaceModal;
