import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCalculateElementHeight,
  useGetIdentity,
  useToggleOpenModal,
  useUrlParamValue
} from 'src/hooks';
import { ConfirmModal, TDropdownOptions } from 'src/components/common';
import { useToggleCreateEditGroupModal, useToggleManageGroupModalModal } from 'src/components/chat';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TDiscussionTypes } from 'src/components/chat/chat.type';
import { getGroups, editIdeaGroup, deleteIdeaGroup } from 'src/store/chat/chat.slice';
import {
  TDeleteIdeaGroupActionRequest,
  TEditIdeaGroupActionRequest
} from 'src/services/apiEndpoint.types.ts';

import DiscussionList from './discussionList/DiscussionList';
import { TDiscussionProps } from './discussion.types';
import useGetProgramGroups from './discussionList/useGetProgramGroups.hook';
import './discussion.scss';
import ChatMessages from './chatMessages/ChatMessages';
import EditProgramGroup from './editProgramGroup/EditProgramGroup';

const Discussion = () => {
  // Hooks
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();
  const programDiscussionRef = useRef<HTMLDivElement>(null);
  const { toggleManageGroupModalModal } = useToggleManageGroupModalModal();
  const toggleOpenModal = useToggleOpenModal();
  // Get program groups
  const { Groups, GroupParticipants } = useAppSelector((store) => store.chat);
  const modalId = useAppSelector((store) => store.common.modalId);
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { authenticatedId, loggedInUserType } = getIdentities();

  const handleGetGroups = () => {
    const payload: any = {
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getGroups(payload));
  };

  useEffect(() => {
    handleGetGroups();
  }, []);
  const discussionType: TDiscussionTypes = paramValue({
    paramName: 'chatType'
  }) as unknown as TDiscussionTypes;
  const groupId: string = paramValue({ paramName: 'groupId' }) || '';

  // Constants
  // const groupName = programGroupParticipants?.[0]?.groupName;
  const deleteProgramGroupConfirmModalId = `deleteProgramGroupConfirmModal-${groupId}`;
  const isDeleteGroupConfirmModalOpen = modalId === deleteProgramGroupConfirmModalId;

  const toggleOpenDeleteGroupModal = () => {
    toggleOpenModal({ modalId: deleteProgramGroupConfirmModalId });
  };

  // const headerDropdownOptions: TDropdownOptions[] = [];

  // if (discussionType === 'programDiscussion') {
  //   headerDropdownOptions.push(
  //     // { label: 'View members', onClick: toggleOpenOpenDiscussionMembersModal },
  //     { label: 'Clear chat' }
  //   );
  // } else if (discussionType === 'programGroup') {
  //   headerDropdownOptions.push(
  //     { label: 'Manage Members', onClick: toggleManageGroupModalModal },
  //     { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
  //     { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
  //   );
  // }

  // const handleDeleteProgramGroup = () => {
  //   const payload: TDeleteProgramGroupActionRequest = {
  //     userId: authenticatedId,
  //     userType: loggedInUserType,
  //     programId,
  //     programConversationId: groupId
  //   };

  //   dispatch(deleteProgramGroup(payload)).then(() => {
  //     navigate(`/view-program?id=${programId}&tab=discussion`);
  //   });
  // };

  const handleDeleteGroup = () => {
    try {
      if (GroupParticipants?.[0]?.ownerId !== authenticatedId) {
        const payload: TEditIdeaGroupActionRequest = {
          userId: authenticatedId,
          userType: loggedInUserType,
          ideaConversationId: groupId,
          usersToAdd: [],
          usersToRemove: [
            {
              userId: authenticatedId,
              userType: loggedInUserType
            }
          ],
          groupName: GroupParticipants?.[0]?.groupName
        };

        dispatch(editIdeaGroup(payload)).then(() => {});
      } else {
        const payload: TDeleteIdeaGroupActionRequest = {
          userId: authenticatedId,
          userType: loggedInUserType,
          ideaConversationId: groupId
        };

        dispatch(deleteIdeaGroup(payload)).then(() => {});
      }
    } catch (error) {
    } finally {
      toggleOpenDeleteGroupModal();
      navigate('/chat');
    }
  };
  const { elementHeight } = useCalculateElementHeight({
    ref: programDiscussionRef,
    triggers: [window.location.search],
    heightOffset: 45
  });

  return (
    // <div className="p" ref={programDiscussionRef} style={{ height: `${elementHeight}px` }}>
    <div>
      <DiscussionList
        discussionType={discussionType}
        groupId={groupId}
        groups={Groups}
        // programId={programId}
        // companyId={companyId}
      />
      {/* <ChatMessages
        discussionType={discussionType}
        groupId={groupId}
        headerDropdownOptions={headerDropdownOptions}
        members={programGroupParticipants}
        // programId={programId}
        // companyId={companyId}
      />{' '} */}
      {/* {isToggleCreateEditGroupModalOpen && (
        <EditProgramGroup
          mode="Edit"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          programId={programId}
          groupId={groupId}
          groupName={groupName}
          groupMembers={programGroupParticipants}
        />
      )}{' '} */}
      {isDeleteGroupConfirmModalOpen && (
        <ConfirmModal
          title="Do wish to delete this group?"
          description="Deleting this group will permanently remove all the chats."
          openConfirmModal={isDeleteGroupConfirmModalOpen}
          onClose={toggleOpenDeleteGroupModal}
          onSubmit={handleDeleteGroup}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default Discussion;
