import React, { useState } from 'react';
import cx from './eventCard.module.scss';
import { eventsTest, playerIcon, test } from 'src/assets/images';
import { Button, ConfirmModal } from 'src/components/common';
import { textEllipsis } from 'src/utils';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { changeCreationModalState, deleteItem, getCommonMarketPlaceItems } from 'src/store/marketplace/marketplace.slice';
import { openPopup } from 'src/store/common/common.slice';
import { useNavigate } from 'react-router-dom';
import EventServiceProviderModal from '../EventServiceProviderModal/EventServiceProviderModal';
interface TEventProps {
    id?: string,
    isAction?: boolean,
    name?: string,
    url?: string,
    description?: string,
    logo?: string,
    organizer?: string,
    isViewerProgramCreator?: boolean
}
export default function EventCard({ isAction = true, name, id, description, logo, organizer, url = '', isViewerProgramCreator = true }: TEventProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { serviceProviderCommonModal } = useAppSelector((state) => state.marketPlace);
    const [openConfirmation, setOpenConfirmationModal] = useState<boolean>(false);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    async function handleDelete() {
        if (id) {
            const response = await dispatch(deleteItem({ id }))
            if (response.status) {
                dispatch(
                    openPopup({
                        popupMessage: "Event deleted successfully",
                        popupType: 'success'
                    })
                );
                const payload = {
                    dataType: "Event",
                    companyId: myCompany?.id
                }
                dispatch(getCommonMarketPlaceItems(payload))
            } else {
                dispatch(
                    openPopup({
                        popupMessage: "Event is not deleted",
                        popupType: 'error'
                    })
                );
            }
        }
    }
    function handleEventsUpdate() {
        dispatch(changeCreationModalState({ state: true, dataType: 'Event', id }))
    }
    function handleNavigate() {
        if (!url || typeof url !== 'string') {
            console.error("Invalid URL provided.");
            return;
        }

        const trimmedUrl = url.trim();
        const path = trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')
            ? trimmedUrl
            : `http://${trimmedUrl}`;

        window.open(path, '_blank'); // Opens the URL in a new tab
    }

    const descriptionObj = textEllipsis({ charLimit: 60, text: description });
    const nameWithEllipsis = textEllipsis({ charLimit: 18, text: name });
    const organizerWithEllipsis = textEllipsis({ charLimit: 18, text: organizer || '' });
    return (
        <>
            {serviceProviderCommonModal.state && <EventServiceProviderModal />}
            {openConfirmation && <ConfirmModal
                openConfirmModal={openConfirmation}
                title="Are you sure you want to remove this Event?"
                description=""
                onClose={() => {
                    setOpenConfirmationModal(false);
                }}
                onSubmit={handleDelete}
                submitText="Confirm"
                type="error"
            />}
            <div className='item'>
                <div className={`${cx.outer_box}`} style={isAction ? { minHeight: '370px', maxHeight: "370px" } : {}}>
                    <div className={`${cx.imageBox}`}>
                        <img
                            src={logo || test}
                            alt="meeting-img"
                        />
                    </div>
                    {!isAction && isViewerProgramCreator && <div className={`${cx.actions}`}>
                        <EditIcon className={cx.icons} onClick={handleEventsUpdate} />
                        <TrashIcon className={cx.icons} onClick={() => setOpenConfirmationModal(true)} />
                    </div>}
                    <div className={`${cx.marketplace_heading}`}>
                        <h4 title={name}>{nameWithEllipsis.text}</h4>
                        {organizer && <h5 title={organizer}>{organizerWithEllipsis?.text}</h5>}
                        <div className={`${cx.marketplace_content}`}>
                            <p title={description}>{descriptionObj.text}</p>
                        </div>
                        {isAction && url && <div className={`${cx.ctaButton}`}>
                            <Button size="small" variant="primary" type="submit" onClick={handleNavigate}>
                                Learn More
                            </Button>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
