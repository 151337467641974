import * as Yup from 'yup';

const createCustomApplicationFormSchema = ({
  sectionFields
}: {
  sectionFields: Record<string, string>;
}) => {
  const shape: { [key: string]: any } = {};

  try {
    const fields: string[] = Object.keys(sectionFields);

    fields.forEach((field) => {
      if (field.includes('sectionRole')) {
        // Make sectionRole optional
        shape[field] = Yup.string();
      } else {
        // Make other fields required
        shape[field] = Yup.string().required('Please enter details in this field');
      }
    });

    return Yup.object().shape(shape);
  } catch {
    return Yup.object().shape(shape);
  }
};

export default createCustomApplicationFormSchema;
