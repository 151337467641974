import { TInitialState } from './ideaHub.slice';

const initialState: TInitialState = {
  myIdeas: [],
  myIdeasPageNumber: 1,
  myIdeasItemsPerPage: 10,
  myIdeasMoreItemsLeft: true,
  ideasSharedWithMe: [],
  ideasSharedWithMePageNumber: 1,
  ideasSharedWithMeItemsPerPage: 10,
  ideasSharedWithMeMoreItemsLeft: true,
  ideasFeedLoading: false,
  viewIdea: {
    idea: {
      id: '',
      ownerId: '',
      title: '',
      domain: '',
      description: '',
      // marketSize: '',
      targetAudience: '',
      // marketTrends: '',
      identifiedProblem: '',
      solutionEffectiveness: '',
      uniqueValueProposition: '',
      majorRevenueStream: '',
      // scaliblityOfIdea: '',
      competitors: '',
      validationReport: '',
      // keyDifferentiatingFactor: '',
      status: 'Draft',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
      owner: {
        id: '',
        name: '',
        email: '',
        profilePic: '',
        role: ''
      }
    },
    collaborators: [],
    collaboratorsCount: 1
  },
  viewEvaluatorResponses: [],
  viewEvaluatorResponsesCount: 0,
  viewCollaborators: [],
  viewDocuments: [],
  searchUserLoading: false,
  searchCollaboratorsList: [],
  searchCollaboratorsPageNumber: 1,
  searchCollaboratorsItemsPerPage: 12,
  searchCollaboratorsMoreItemsLeft: true,
  searchEvaluatorsList: [],
  searchEvaluatorsPageNumber: 1,
  searchEvaluatorsItemsPerPage: 12,
  searchEvaluatorsMoreItemsLeft: true,
  recommendedEvaluators: [],
  // List Evaluators
  listEvaluators: [],
  listEvaluatorsPageNumber: 1,
  listEvaluatorsItemsPerPage: 10,
  listEvaluatorsMoreItemsLeft: true
};

export default initialState;
