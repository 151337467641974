import { useState } from 'react';
import { ValidationError } from 'yup';
import { Form, Formik } from 'formik';
import { Typography, ConfirmModal } from 'src/components/common';
import { colors } from 'src/constants';
import Button from 'src/components/button/Button';
import { useAppSelector } from 'src/store/hooks';

import './applicationFormStep.scss';
import {
  TApplicationFormStepProps,
  TApplicationFormStepSectionItem,
  TSelectedFiles
} from './applicationFormStep.types';
import createYupSchema from './createApplicationFormStepSchema';

import PreviewField from '../previewField/PreviewField';

const ApplicationFormStep = ({
  sectionIndex,
  sectionTitle,
  sectionItems,
  sectionRoles,
  finalStep,
  handleDraft,
  updateApplicantSectionResponse,
  handleApplyToProgram,
  goToApplicationHome
}: TApplicationFormStepProps) => {
  // Hooks
  const loading: boolean = useAppSelector((store) => store.common.loading);

  // React Hooks
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  const fieldValueMapping: Record<string, string> = {};

  try {
    sectionItems.forEach((item) => {
      const { id, response } = item;
      fieldValueMapping[id] = response;
    });
  } catch {}

  const validationSchema = createYupSchema({ sectionItems });

  const validateForm = async ({ values }: { values: Record<string, string> }) => {
    const errors = await validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        setErrors({});
        return [];
      })
      .catch((validationError: any) => {
        const errors: Record<string, string> = {};

        validationError.inner.forEach((error: ValidationError) => {
          const field = error.path;
          if (field) {
            errors[field as string] = error.message;
          }
        });
        setErrors(errors);
        return validationError.errors;
      });

    return errors;
  };

  const validateSection = async (values: Record<string, string>, isDraft: string) => {
    const errors = await validateForm({ values });

    const hasZeroErrors: boolean = errors?.length < 1;

    try {
      if (hasZeroErrors) {
        const section: { sectionTitle: string; sectionItems: TApplicationFormStepSectionItem[] } =
          JSON.parse(
            JSON.stringify({
              sectionTitle,
              sectionItems
            })
          );

        const selectedFiles: TSelectedFiles = {};

        Object.keys(values).forEach((sectionId) => {
          const sectionItem = section?.sectionItems.find(
            (sectionItem) => sectionItem.id === sectionId
          );
          if (sectionItem && sectionItem?.type === 'fileUpload') {
            const files = values[sectionId];

            if (files?.length > 0) {
              sectionItem.files = values[sectionId] as unknown as {
                key: string;
                fileName: string;
                fileSize: number;
              }[];

              selectedFiles[sectionId] = files as unknown as {
                key: string;
                fileName: string;
                fileSize: number;
                data: string | ArrayBuffer | null;
              }[];

              sectionItem.response = files;
            }
          } else if (sectionItem && 'response' in sectionItem) {
            sectionItem.response = values[sectionId];
          }
        });
        if (isDraft === 'Application' && finalStep) {
          handleApplyToProgram({
            sectionIndex,
            sectionTitle,
            sectionRoles,
            sectionResponses: section?.sectionItems,
            selectedFiles
          });
        } else {
          console.log(section?.sectionItems, 'section?.sectionItems');
          if (isDraft === 'draft') {
            handleDraft({
              sectionIndex,
              sectionTitle,
              sectionRoles,
              sectionResponses: section?.sectionItems,
              selectedFiles
            });
          } else {
            console.log(section, 'section?.sectionItems');
            updateApplicantSectionResponse({
              sectionIndex,
              sectionTitle,
              sectionRoles,
              sectionResponses: section?.sectionItems,
              selectedFiles,
              draft: true
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDraftState = (values: Record<string, string>) => {
    validateSection(values, 'draft'); // set finalStep to false
  };
  console.log(sectionItems, 'sectionItems');

  return (
    <div className="apply-program-application-form-step">
      <Typography
        as="p"
        variant="body-2"
        fontWeight="regular"
        style={{ color: colors.neutral[1100] }}
      >
        To apply for this program, please fill out the application form below. Make sure all fields
        are filled in correctly.
      </Typography>
      <Formik
        initialValues={fieldValueMapping}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => validateSection(values, 'Application')} // set finalStep to true on form submit
      >
        {({ values }) => (
          <Form className="apply-program-application-form-step__form">
            <Typography
              as="p"
              variant="body-1"
              fontWeight="semiBold"
              style={{ color: colors.neutral[1100] }}
            >
              {sectionTitle}
            </Typography>
            {sectionItems.map((section, index) => (
              <PreviewField key={index} sectionId={`${index}`} {...section} errors={errors} />
            ))}

            <div className="apply-program-application-form-step__form__footer">
              <Button
                size="small"
                variant="secondary"
                onClick={() => {
                  setOpenCancelModal(true);
                }}
              >
                Cancel
              </Button>
              <Button size="small" variant="primary" type="submit" loading={loading}>
                {finalStep ? 'Submit' : 'Next'}
              </Button>
              <Button
                size="small"
                variant="secondary"
                onClick={() => handleDraftState(values)} // Manually trigger save as draft
              >
                Save as Draft
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {openCancelModal && (
        <ConfirmModal
          openConfirmModal={openCancelModal}
          title="Are you sure you want to exit?"
          description="Any modifications made here will not be saved"
          onClose={() => {
            setOpenCancelModal(false);
          }}
          onSubmit={goToApplicationHome}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default ApplicationFormStep;
