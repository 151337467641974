export type TCreationModal = { state: boolean, dataType: string, id: string, services?: any, catId: string | null }
export type TInitialState = {
    globalLoading?: string;
    productLoading?: boolean;
    eventLoading?: boolean;
    serviceLoading?: boolean;
    topCompany?: any;
    topProduct?: any;
    topEvent?: any;
    topService?: any;
    serviceProviderCommonModal?: TCreationModal,
    Product?: any,
    Portfolio?: any,
    Event?: any,
    globalServices?: any,
    marketPlaceServices?: any;
    counter?: number;
    settings?: any
};

export const initialState: TInitialState = {
    globalLoading: '',
    productLoading: false,
    eventLoading: false,
    serviceLoading: false,
    topCompany: [],
    topProduct: [],
    topService: [],
    topEvent: [],
    serviceProviderCommonModal: { state: false, dataType: '', id: '', services: [], catId: '' },
    Product: [],
    Portfolio: [],
    Event: [],
    globalServices: [],
    marketPlaceServices: [],
    counter: 0,
    settings: []
};
