import React, { useEffect } from 'react';
import cx from './cohortsidebar.module.scss';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { PlusIcon, SingleChevronIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import { useToggleCreateEditGroupModal, useToggleManageGroupModalModal } from 'src/components/chat';
import { createGroup } from 'src/store/chat/chat.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';

import { useDispatch, useSelector } from 'react-redux';
import {
  cohortTeamList,
  removeTeam,
  resetMilestoneDataSuccess
} from 'src/store/cohort/cohort.slice';
import { AppDispatch } from 'src/store/store';

import { TCreateGroupActionRequest } from 'src/services/apiEndpoint.types.ts';
export default function CohortSidebar({ handleClick, activeTab }: any) {
  const { getIdentities } = useGetIdentity();
  const { paramValue } = useUrlParamValue();
  const dispatch = useDispatch<AppDispatch>();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  const { loggedInCompanyId, loggedInUserType } = getIdentities();
  const { viewCohort, cohortTeam }: any = useAppSelector((store: any) => store.cohort);
  console.log(cohortTeam, 'cohortTeam');
  const handleCreateGroup = () => {
    const usersToAdd: string[] = [];
    console.log(loggedInUserType, 'loggedInUserType');
    const activeTeamMemberIds = cohortTeam
      .filter((team) => team.status !== 'Deactivated') // Filter teams with status not "Deactivated"
      .flatMap((team) => team.teamMemberMappings.map((member) => member.teamMemberId));
    const GroupUsers = activeTeamMemberIds.map((userId) => ({ userId, userType: 'User' }));
    console.log(activeTeamMemberIds, 'activeTeamMemberIds');

    // const ownerIndex = usersToAdd?.findIndex((user) => user === loggedInCompanyId);

    // if (ownerIndex > -1) {
    //   // Remove the owner from the array
    //   usersToAdd.splice(ownerIndex, 1);
    // }
    // // Adding the owner as the first item in the array.
    GroupUsers.unshift({ userId: loggedInCompanyId, userType: 'Company' });

    const payload: TCreateGroupActionRequest = {
      moduleId: viewCohort?.id,
      moduleType: 'Cohort',
      groupName: viewCohort?.cohortTitle,
      usersToAdd: GroupUsers,
      userId: loggedInCompanyId,
      userType: loggedInUserType
    };

    dispatch(createGroup(payload));
  };
  useEffect(() => {
    if (cohortId) {
      dispatch(cohortTeamList({ cohortId, incubatorId }));
    }
  }, [cohortId]);
  const isInvestmentTracking = viewCohort?.templates?.investmentTracking || false;
  const iskpiTrackingTracking = viewCohort?.templates?.kpiTracking || false;
  if (!viewCohort) {
    return <></>;
  }
  return (
    <div className={`${cx.sidebar}`}>
      <div className="insideCard">
        <ul className={cx.sidebaritemList}>
          <li
            className={activeTab === 'Dashboard' ? cx.active : ''}
            onClick={() => handleClick('Dashboard')}
          >
            Dashboard
          </li>
          <li
            className={activeTab === 'GlobalMilestone' ? cx.active : ''}
            onClick={() => handleClick('GlobalMilestone')}
          >
            Milestone
          </li>
          <li
            className={activeTab === 'Teams' || activeTab === 'Relationship' ? cx.active : ''}
            onClick={() => handleClick('Teams')}
          >
            Teams
          </li>
          {isInvestmentTracking && (
            <li
              className={activeTab === 'Investment' ? cx.active : ''}
              onClick={() => handleClick('Investment')}
            >
              Investment Tracking
            </li>
          )}
          {iskpiTrackingTracking && (
            <li className={activeTab === 'Kpi' ? cx.active : ''} onClick={() => handleClick('Kpi')}>
              KPI Tracking
            </li>
          )}
          <li
            className={activeTab === 'Resource' ? cx.active : ''}
            onClick={() => handleClick('Resource')}
          >
            Resource
          </li>
          <li>
            <Button
              variant="secondary"
              size="small"
              startIcon={<PlusIcon />}
              className="discussion-list__groups__body__create-group"
              onClick={handleCreateGroup}
            >
              Create Group
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
}
