import React, { useEffect } from 'react';
import cx from './companyServices.module.scss';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { dotMenu } from 'src/assets/images';
import ServiceSubCategoryCard from 'src/components/ServiceProvider/ServiceSubCategoryCard/ServiceSubCategoryCard';
import { Button, Loader } from 'src/components/common';
import { MdAdd } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { changeCreationModalState, changeServiceOrder, clearMarketPlaceItems, clearMarketPlaceService, getMarketPlaceServices } from 'src/store/marketplace/marketplace.slice';
import AssignServiceModal from 'src/components/MarketPlace/AssignServiceModal/AssignServiceModal';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useUrlParamValue } from 'src/hooks';

export default function CompanyServices({ isViewerProgramCreator = true }: { isViewerProgramCreator?: boolean }) {
    const dispatch = useAppDispatch()
    const { paramValue } = useUrlParamValue();
    const { serviceProviderCommonModal, marketPlaceServices, globalLoading } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);

    const viewCompanyId = paramValue({ paramName: 'id' }) as unknown as string;

    const handleServiceAdd = (catId: string = '') => {
        dispatch(changeCreationModalState({ state: true, dataType: 'Services', id: '', services: [], catId }))
    }
    const groupServices = marketPlaceServices?.reduce((acc, item) => {
        const categoryTitle = item.ServiceCategory.title;
        // Find the category object in the accumulator array
        let categoryObj = acc.find(group => group.category === categoryTitle);

        // If not found, create a new category object
        if (!categoryObj) {
            categoryObj = { category: categoryTitle, services: [] };
            acc.push(categoryObj);
        }

        // Add the current service to the services array
        categoryObj.services.push(item);
        return acc;
    }, []);

    const handleOnDragEndFields = (result, index) => {
        const { source, destination } = result;

        // If there's no destination, don't do anything
        if (!destination) return;

        // Optimistically update the services order
        const catService = groupServices[index]
        const updatedServices = Array.from(catService?.services || []);
        const [movedItem] = updatedServices.splice(source.index, 1); // Remove the item from its source position
        updatedServices.splice(destination.index, 0, movedItem); // Insert the item at the destination position
        const payload = {
            dataType: 'Service',
            orderArray: updatedServices?.map((service: any, i: number) => ({ id: service.id, order: i + 1 }))
        }
        dispatch(changeServiceOrder(payload)).then((res) => {
            if (res.status) {
                const payload = {
                    companyId: myCompany?.id
                }
                dispatch(getMarketPlaceServices(payload))
            }
        })
    };
    useEffect(() => {
        if (viewCompanyId) {
            const payload = {
                companyId: viewCompanyId
            }
            dispatch(getMarketPlaceServices(payload))
        }

        return () => {
            dispatch(clearMarketPlaceService())
        }
    }, [dispatch, viewCompanyId])
    console.log(marketPlaceServices, 'marketPlaceServices');
    
    useEffect(() => {
        if (myCompany?.id && !viewCompanyId) {
            const payload = {
                companyId: myCompany?.id
            }
            dispatch(getMarketPlaceServices(payload))
        }

        return () => {
            dispatch(clearMarketPlaceService())
        }
    }, [myCompany?.id, dispatch])
    useEffect(() => {
        return () => {
            dispatch(changeCreationModalState({ state: false, dataType: '', id: '', services: [], catId: '' }))
        }
    }, [])
    if (globalLoading === 'getServices') {
        return <Loader />
    }

    return (
        <>
            {serviceProviderCommonModal.state && <AssignServiceModal />}
            <div className={`${cx.companyServices}`}>
                {isViewerProgramCreator && <Row>
                    <Col md={12}>
                        <div className={`${cx.addServices}`}>
                            <Button
                                variant="secondary"
                                size="small"
                                className="create-edit-group__buttons__close"
                                onClick={() => handleServiceAdd('')}
                            >
                                <MdAdd /> Add Services
                            </Button>
                        </div>
                    </Col>
                </Row>}
                {groupServices?.map((catService, c: number) => {
                    return (
                        <Row key={c}>
                            <Col md={12}>
                                <div className={`${cx.serviceDetails}`}>
                                    <h5 className={`${cx.serviceName}`}>{catService?.category}</h5>
                                    {isViewerProgramCreator && <div className={cx.dotDropdown}>
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic-6">
                                                <img src={dotMenu} className={cx.dots} alt="dot" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={() => handleServiceAdd(catService?.services?.[0]?.ServiceCategory?.id)}>
                                                    <p className={cx.dotDropInside} >
                                                        <span>Add Services</span>
                                                    </p>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>}
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className={`${cx.serviceRow}`}>
                                    <DragDropContext onDragEnd={(result) => handleOnDragEndFields(result, c)}>
                                        <Droppable droppableId="services" direction="horizontal">
                                            {(provided: any) => (
                                                <Row {...provided.droppableProps} ref={provided.innerRef}>
                                                    {catService?.services?.map((service, index) => {
                                                        const comment = service.MarketPlaceServiceAndCompanyMappings?.[0]?.comments || ''
                                                        return (
                                                            <Draggable draggableId={String(service.id)} index={index} key={service.id}>
                                                                {(provided: any) => (
                                                                    <Col
                                                                        md={4}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <ServiceSubCategoryCard
                                                                            isViewerProgramCreator={isViewerProgramCreator}
                                                                            title={service.title}
                                                                            comment={comment}
                                                                            id={service?.id}
                                                                        />
                                                                    </Col>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </Row>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </Col>
                        </Row>
                    )
                })}
            </div >
        </>
    )
}
