import React, { RefObject, TextareaHTMLAttributes, useState, useEffect, useRef } from 'react';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import ReactQuill, { Quill } from 'react-quill';
import imageCompression from 'browser-image-compression';
import 'react-quill/dist/quill.snow.css'; // Import styles

import './textArea.scss';
import { useAppDispatch } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';

type TTextAreaProps = {
  variant?: componentSizeVariantsEnum;
  inputRef?: RefObject<HTMLTextAreaElement>;
  createProgramStep?: any;
  textLengthLimit?: any;
  maxLength?: number; // Added maxLength prop
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * Props for the TextEditorAreaDescriptive component.
 * @typedef {Object} TTextAreaProps
 * @param {componentSizeVariantsEnum} [variant] - The variant of the text area.
 * @param {TextareaHTMLAttributes<HTMLTextAreaElement>} [HTMLTextAreaElement] - HTML attributes of the text area element.
 * @param {number} [maxLength] - Maximum allowed length of the content.
 */
const MAX_IMAGE_SIZE = 300 * 1024 
const MAX_IMAGE_COUNT = 5;
const TextEditorAreaDescriptive = ({
  className,
  value,
  textLengthLimit,
  disabled,
  variant = componentSizeVariantsEnum.MEDIUM,
  ...rest
}: TTextAreaProps) => {
  const dispatch = useAppDispatch()
  const maxLength = textLengthLimit;
  const [content, setContent] = useState<string>(value as string || '');
  const [error, setError] = useState<string | null>(null);
  const [imageCount, setImageCount] = useState(0)
  const quillRef = useRef<ReactQuill>(null); // Reference to Quill editor instance

  useEffect(() => {
    if (value) {
      setContent(value as string);
    }
  }, [value]);

  function removeTags(str: any) {
    if (!str || typeof str !== 'string') return '';
    return str.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '');
  }

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        ["link"],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["clean"],
        ['image']
      ]
    }
  };

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // Get the Quill instance
      const toolbar = editor.getModule('toolbar');

      toolbar.addHandler('image', async () => {
        if (imageCount >= MAX_IMAGE_COUNT) {
          return;
        }

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files ? input.files[0] : null;

          if (file) {
            try {
              // Image compression options
              const options = {
                maxSizeMB: 0.3, // Maximum size in kb (300kb)
                maxWidthOrHeight: 1024, // Maximum width or height in pixels
                useWebWorker: true
              };

              // Compress the image
              const compressedFile = await imageCompression(file, options);
              if (compressedFile?.size > MAX_IMAGE_SIZE) {
                dispatch(
                  openPopup({
                    popupMessage: `Image size must be less than ${MAX_IMAGE_SIZE / 1024} KB.`,
                    popupType: 'error'
                  })
                );
                return;
              }

              // Convert compressed image to Base64
              const reader = new FileReader();
              reader.onload = () => {
                const base64Image = reader.result;
                editor.insertEmbed(editor.getSelection().index, 'image', base64Image);
                setImageCount((prevCount) => prevCount + 1);
                setError(null); // Clear any previous errors
              };
              reader.readAsDataURL(compressedFile);
            } catch (error) {
              console.error('Image compression error:', error);
              setError('Failed to compress the image.');
            }
          }
        };
      });
    }
  }, [quillRef, imageCount]);

  const handleQuillChange = (content: string, delta: any, source: any, editor: any) => {
    const plainText = removeTags(content);
    const plainTextLength = plainText.length;

    if (maxLength) {
      if (plainTextLength <= maxLength) {
        setContent(content);
        setError(null);
      } else {
        // Optionally truncate the content to fit the maxLength
        const truncatedContent = content.slice(0, maxLength);
        const truncatedCopyContent = removeTags(content).slice(0, maxLength);
        setContent(truncatedContent);
        setError(`Content exceeds maximum length of ${maxLength} characters`);
        if (quillRef.current) {
          const editor = quillRef.current.getEditor();
          editor.setText(truncatedCopyContent);
          editor.setSelection(truncatedCopyContent.length, 0);
        }
      }
    } else {
      setContent(content); // Directly set the content when no maxLength is provided
    }

    if (rest.onChange) {
      rest.onChange({
        ...event,
        target: {
          ...event?.target,
          value: content
        }
      } as unknown as React.ChangeEvent<HTMLTextAreaElement>);
    }
  };

  return (
    <>
      <div className={className} style={{ maxHeight: '300px', height: '200px' }}>
        <ReactQuill
          value={content}
          onChange={handleQuillChange}
          readOnly={disabled}
          style={{ maxHeight: '300px', height: '150px' }}
          ref={quillRef}
          modules={modules}
        />
      </div>
      {error && maxLength && <div className="error-message">{error}</div>}
    </>
  );
};

export default TextEditorAreaDescriptive;
