import { CheckMarkIcon, ErrorIcon } from '../common.icons';

export const icon = {
  success: <CheckMarkIcon />,
  successRetainModal: <CheckMarkIcon />,
  error: <ErrorIcon />,
  wait: <svg id="load" viewBox="25 25 50 50">
  <circle r="20" cy="50" cx="50"></circle>
</svg>
};
