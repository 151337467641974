import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'src/components/header/Header';
import IdeaSkeleton from 'src/components/ideaHub/ideaSkeleton/IdeaSkeleton';
import { ConfirmModal, TBreadcrumb } from 'src/components/common';
import { TIdeaCollaborationType } from 'src/components/ideaHub';
import { TGetIdea } from 'src/components/ideaHub/ideaHub.types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity, useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { TGetIdeaRequest } from 'src/services/apiEndpoint.types.ts';
import { getIdea } from 'src/store/ideaHub/ideaHub.slice';

import './editIdea.scss';

const EditIdea = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const toggleOpenModal = useToggleOpenModal();
  const { viewIdea, viewEvaluatorResponses, viewDocuments } = useAppSelector(
    (store) => store.ideaHub
  );
  const { id, name, profilePic, email, role } = useAppSelector((store) => store.profile.profile);
  const modalId = useAppSelector((store) => store.common.modalId);

  const ideaId: string = paramValue({ paramName: 'id' }) as unknown as string;
  const editType = paramValue({ paramName: 'editType' }) as 'newIdea' | 'draft' | 'Discard';
  const { loggedInUserId } = getIdentities();

  // State

  // Constants
  const exitIdeaModalId = 'exitIdeaModalId';
  const ideaCollaborationType: TIdeaCollaborationType = viewIdea?.userCollaborationType || 'Edit';
  const openExitIdeaModal = modalId === exitIdeaModalId;

  const breadcrumbs: TBreadcrumb[] = [
    { label: 'Idea Hub', url: '/idea-hub?tab=myIdea' },
    {
      label: editType === 'newIdea' ? 'Idea 1' : viewIdea?.idea?.title,
      url:
        editType === 'newIdea'
          ? '/view-empty-idea?tab=ideaDetails'
          : `/view-idea?id=${ideaId}&tab=ideaDetails`
    },
    { label: 'Edit Idea Details', url: '' }
  ];

  const toggleOpenExitModal = () => {
    toggleOpenModal({ modalId: exitIdeaModalId });
  };

  const changeTabAfterConfirmation = () => {
    toggleOpenModal({ modalId: exitIdeaModalId });

    const redirectUrl: string =
      editType === 'newIdea'
        ? '/view-empty-idea?tab=ideaDetails'
        : `/view-idea?id=${ideaId}&tab=ideaDetails`;

    navigate(redirectUrl);
  };

  const handleChangeTab = () => {
    toggleOpenExitModal();
  };

  const emptyIdea: TGetIdea = {
    idea: {
      id: '',
      ownerId: '',
      title: '',
      domain: '',
      description: '',
      // marketSize: '',
      targetAudience: '',
      // marketTrends: '',
      identifiedProblem: '',
      solutionEffectiveness: '',
      uniqueValueProposition: '',
      majorRevenueStream: '',
      // scaliblityOfIdea: '',
      competitors: '',
      validationReport: '',
      // keyDifferentiatingFactor: '',
      status: 'Draft',
      createdAt: '',
      updatedAt: '',
      deletedAt: '',
      owner: {
        id,
        name,
        email,
        profilePic,
        role
      }
    },
    collaborators: [],
    collaboratorsCount: 0
  };

  const ideaToRender = editType === 'draft' ? viewIdea : emptyIdea;

  const fetchIdea = () => {
    const payload: TGetIdeaRequest = {
      ideaId,
      userId: loggedInUserId
    };
    if (ideaId) {
      dispatch(getIdea(payload));
    }
  };

  useEffect(() => {
    fetchIdea();
  }, [ideaId]);

  return (
    <div className="view-idea">
      <Header />
      <div className="view-idea__body">
        <IdeaSkeleton
          viewType="editIdea"
          breadCrumbs={breadcrumbs}
          changeTab={handleChangeTab}
          ideaStatus={viewIdea?.idea?.status}
          ideaCollaborationType={ideaCollaborationType}
          ideaData={ideaToRender}
          documents={viewDocuments}
          ratings={viewEvaluatorResponses}
        />
      </div>
      {openExitIdeaModal && (
        <ConfirmModal
          title="Are you sure you want to exit"
          description="Any modifications made here will not be saved"
          openConfirmModal={openExitIdeaModal}
          onClose={toggleOpenExitModal}
          submitText="Confirm"
          onSubmit={changeTabAfterConfirmation}
          type="error"
        />
      )}
    </div>
  );
};

export default EditIdea;
