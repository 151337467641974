import { useEffect } from 'react';
import { socketService } from 'src/services/socket.api.service';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TDiscussionTypes } from 'src/components/chat/chat.type';
import {
  TDeleteIdeaMessageResponse,
  TDislikeConversationMessageResponse,
  TDislikeConversationMessageSuccess,
  TEditIdeaMessageResponse,
  TGetIdeaConversationMessagesRequest,
  TGetProgramConversationMessagesRequest,
  TLikeConversationMessageResponse,
  TLikeConversationMessageSuccess,
  TReceiveMessageResponse
} from 'src/services/apiEndpoint.types.ts';
import {
  deleteIdeaMessageSuccess,
  dislikeMessageSuccess,
  editIdeaMessageSuccess,
  getIdeaConversationMessages,
  getProgramConversationMessages,
  incrementIdeaChatMessagesPageNumber,
  likeConversationMessageSuccess,
  receiveMessageSuccess,
  resetMessages
} from 'src/store/chat/chat.slice';
import { ScrollTopPagination } from 'src/components/common';

import { TChatMessagesBodyProps } from './chatMessagesBody.types';
import './chatMessagesBody.scss';

import ChatMessage from '../chatMessage/ChatMessage';

const ChatMessagesBody = ({
  groupId,
  updateMessageSettings,
  groupMessageContext
}: TChatMessagesBodyProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { itemListLoader } = useAppSelector((store) => store.common);
  const {
    messages,
    ideaChatMessagesPageNumber,
    ideaChatMessagesItemsPerPage,
    ideaChatMessagesMoreItemsLeft
  } = useAppSelector((store) => store.chat);

  const chatMessages = messages[groupId] ? messages[groupId] : [];

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const chatType = paramValue({ paramName: 'chatType' }) as unknown as TDiscussionTypes;

  const onSocketConnection = () => {
    console.log(`${groupMessageContext} chat connected`);
  };

  const onSocketConversations = (event: TReceiveMessageResponse) => {
    dispatch(receiveMessageSuccess(event));
  };

  const editLocalMessageState = (event: TEditIdeaMessageResponse) => {
    dispatch(editIdeaMessageSuccess(event));
  };

  const deleteLocalMessageState = (event: TDeleteIdeaMessageResponse) => {
    dispatch(deleteIdeaMessageSuccess(event));
  };

  const likeLocalMessageState = (event: TLikeConversationMessageResponse) => {
    const payload: TLikeConversationMessageSuccess = {
      conversationId: groupId,
      messageId: event?.messageId
    };
    dispatch(likeConversationMessageSuccess(payload));
  };

  const dislikeLocalMessageState = (event: TDislikeConversationMessageResponse) => {
    const payload: TDislikeConversationMessageSuccess = {
      conversationId: groupId,
      messageId: event?.messageId
    };
    dispatch(dislikeMessageSuccess(payload));
  };

  const getIdeaChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetIdeaConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId: groupId,
      limit: ideaChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : ideaChatMessagesPageNumber
    };

    dispatch(getIdeaConversationMessages(payload));
  };

  const getProgramChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetProgramConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId: groupId,
      limit: ideaChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : ideaChatMessagesPageNumber
    };
    // <htting

    dispatch(getProgramConversationMessages(payload));
  };

  const loadMoreItems = () => {
    if (ideaChatMessagesMoreItemsLeft && !itemListLoader) {
      dispatch(incrementIdeaChatMessagesPageNumber(1));
    }
  };

  // console.info('idea message offset', ideaChatMessagesPageNumber);

  useEffect(() => {
    if (groupId) {
      socketService.connect();

      socketService.on('connect', onSocketConnection);

      socketService.emit('join', {
        userId: authenticatedId,
        conversationId: groupId
      });

      socketService.on('idea-conversation', onSocketConversations);
      socketService.on('edit-idea-conversation-message', editLocalMessageState);
      socketService.on('delete-idea-conversation-message', deleteLocalMessageState);

      socketService.on('like-idea-conversation-message', likeLocalMessageState);
      socketService.on('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.on('like-program-conversation-message', likeLocalMessageState);
      socketService.on('dislike-program-conversation-message', dislikeLocalMessageState);

      socketService.on('program-conversation', onSocketConversations);
      socketService.on('edit-program-conversation-message', editLocalMessageState);
      socketService.on('delete-program-conversation-message', deleteLocalMessageState);
    }

    return () => {
      socketService.off('connect', onSocketConnection);

      socketService.off('idea-conversation', onSocketConversations);
      socketService.off('edit-idea-conversation-message', editLocalMessageState);
      socketService.off('delete-idea-conversation-message', deleteLocalMessageState);

      socketService.off('like-idea-conversation-message', likeLocalMessageState);
      socketService.off('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.off('like-program-conversation-message', likeLocalMessageState);
      socketService.off('dislike-program-conversation-message', dislikeLocalMessageState);

      socketService.off('program-conversation', onSocketConversations);
      socketService.off('edit-program-conversation-message', editLocalMessageState);
      socketService.off('delete-program-conversation-message', deleteLocalMessageState);
      socketService.disconnect();
    };
  }, [groupId]);

  useEffect(() => {
    if (groupId) {
      if (chatType === 'ideaGroup') {
        getIdeaChatMessages({ resetMessages: true });
      } else if (chatType === 'programGroup' || chatType === 'programDiscussion') {
        getProgramChatMessages({ resetMessages: true });
      }
    }
  }, [groupId]);

  useEffect(() => {
    if (ideaChatMessagesPageNumber) {
      if (chatType === 'ideaGroup') {
        getIdeaChatMessages({ resetMessages: false });
      } else if (chatType === 'programGroup' || chatType === 'programDiscussion') {
        getProgramChatMessages({ resetMessages: false });
      }
    }
  }, [ideaChatMessagesPageNumber]);

  useEffect(() => {
    return () => {
      dispatch(resetMessages());
    };
  }, [groupId]);

  console.log(ideaChatMessagesPageNumber);

  return (
    <ScrollTopPagination
      id={groupId}
      className="chat-messages-body"
      onScrollTop={loadMoreItems}
      loading={itemListLoader}
      moreItemsLeft={ideaChatMessagesMoreItemsLeft}
      scrollToBottomOnLoad={true}
      offset={ideaChatMessagesPageNumber}
    >
      {chatMessages.map((message, index) => {
        return (
          <ChatMessage
            key={index}
            {...message}
            loggedInUserId={authenticatedId}
            loggedInUserType={loggedInUserType}
            groupId={groupId}
            updateMessageSettings={updateMessageSettings}
            groupMessageContext={groupMessageContext}
          />
        );
      })}
    </ScrollTopPagination>
  );
};

export default ChatMessagesBody;
