import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Typography } from 'src/components/common';
import './globalServiceProviderModal.scss';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { disablePastDate } from 'src/utils/DisablePastDate/disablePasteDate';
import { UploadIcon } from 'src/components/common/common.icons';
import { openPopup } from 'src/store/common/common.slice';
import { httpRequest } from 'src/services/commonApis';
import { APIResponse } from 'src/services/base.api.service';
import { TGetCompany, TGetCompanyPresignedUrlActionRequest, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { CompanyAPIService } from 'src/services/company.service';
import handleExtension from 'src/utils/handleDocumentNameExtension/handleDocumentNameExtension';
import { changeCreationModalState, createGlobalItem, getCommonMarketPlaceItems, getPresignedURL } from 'src/store/marketplace/marketplace.slice';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

export type TFormValues = {
    title: string;
    description: string;
    mediaType?: string;
    mediaUrl?: string;
    thumbnailUrl?: string | null;
    date: string;
    organizerName: string
};

export type TCommonValues = {
    companyId: string,
    dataType: string,
};

const initialValues: TFormValues = {
    title: '',
    description: '',
    mediaType: 'image',
    mediaUrl: '',
    thumbnailUrl: '',
    date: '',
    organizerName: ''
};
const globalServiceProviderValidationSchema = yup.object({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    mediaType: yup.string().optional(),
    organizerName: yup.string().optional(),
    mediaUrl: yup.string().optional(),
    thumbnailUrl: yup.string().optional(),
    date: yup.date().required('date is required')
});
const EventServiceProviderModal = () => {
    const loading: boolean = useAppSelector<boolean>((store: any) => store.common.loading);
    const { serviceProviderCommonModal, globalLoading, Event } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    // states
    const [bannerLoading, setBannerLoading] = useState<boolean>(false)
    const [eventData, setEventData] = useState<TFormValues>(initialValues)

    // ref 
    const uploadImageRef = useRef<HTMLInputElement>(null);

    // Hooks
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(changeCreationModalState({ state: false, dataType: '', id: '' }))
    };
    function handleSubmit(values) {
        const payload: TFormValues & TCommonValues = {
            ...values,
            companyId: myCompany?.id,
            dataType: serviceProviderCommonModal.dataType
        }
        dispatch(createGlobalItem(payload)).then((response) => {
            if (response.status) {
                const payload = {
                    dataType: serviceProviderCommonModal.dataType,
                    companyId: myCompany?.id
                }
                dispatch(getCommonMarketPlaceItems(payload))
                closeModal()
            }
        })
    }
    function handleMedia(value: string, setFieldValue: (field: string, value: string) => void) {
        setFieldValue('mediaUrl', value);
    }

    const openImageFileManager = async () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.click();
        }
    };

    const handleUpload = async (e, setFieldValue) => {
        const file = e.target.files[0];
        setBannerLoading(true)
        if (file) {
            const fileName = handleExtension(file?.name)
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                try {
                    const data = reader.result;
                    // const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
                    //     id: viewCompany?.id,
                    //     data,
                    //     showPopup: false
                    // };
                    const response = await dispatch(getPresignedURL({ companyId: myCompany?.id, fileName }))
                    if (response?.status) {
                        const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                            await new CompanyAPIService().uploadCompanyProfilePicture({
                                presignedUrl: response?.data?.signedUrl,
                                data,
                                includeAuthorizationHeaders: false
                            });
                        if (res.status === 200) {
                            const data = await dispatch(getPresignedURL({ companyId: myCompany?.id, fileName }))
                            if (data?.data?.key) {
                                setFieldValue('thumbnailUrl', data?.data?.key)
                            }
                            setBannerLoading(false)
                        } else {
                            dispatch(
                                openPopup({
                                    popupMessage: res?.message || 'something went wrong',
                                    popupType: 'error'
                                })
                            );
                        }
                    } else {
                        setBannerLoading(false)
                        dispatch(
                            openPopup({
                                popupMessage: 'something went wrong',
                                popupType: 'error'
                            })
                        );
                    }
                } catch (error) {
                    setBannerLoading(false)
                    dispatch(
                        openPopup({
                            popupMessage: error?.message || 'something went wrong',
                            popupType: 'error'
                        })
                    );
                }
            }
        }
    }

    useEffect(() => {
        if (serviceProviderCommonModal?.id && Event?.length > 0) {
            const eventData = Event.find((event) => event?.id === serviceProviderCommonModal.id);
            if (eventData) {
                const eventObj = {
                    title: eventData.title || '',
                    description: eventData.description || '',
                    mediaType: 'image',
                    mediaUrl: eventData.mediaUrl || '',
                    thumbnailUrl: eventData.thumbnailUrl || '',
                    date: eventData?.date ? eventData?.date?.split('T')[0] : '',
                    organizerName: eventData.organizerName || '',
                    id: eventData?.id || ''
                }
                setEventData(eventObj)
            }
        }
    }, [Event, serviceProviderCommonModal?.id])

    return (
        <Modal
            isModalOpen={serviceProviderCommonModal.state}
            onModalClose={closeModal}
            title={`${serviceProviderCommonModal?.id ? "Update" : "Add"} Event`}
            className="add__global-modal"
        >
            <div className="add__global">
                {/* Form starts */}
                <Formik
                    initialValues={eventData}
                    onSubmit={handleSubmit}
                    validationSchema={globalServiceProviderValidationSchema}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        return (
                            <Form className="add__global__form" noValidate>
                                <div className='add__global__form__crate-form'>
                                    <Row>
                                        <Col md={12}>
                                            <div className="program-details__form__row mb-3">
                                                <div className='w-100'>
                                                    <>
                                                        <InputField
                                                            label="Upload Image"
                                                            id='banner-label'
                                                            hidden
                                                            variant={componentSizeVariantsEnum.SMALL}
                                                            placeholder="Upload Image"
                                                            type="text"
                                                        />

                                                        <div
                                                            className="dynamic-field__upload-file"
                                                        >
                                                            <span className="dynamic-field__upload-file__drag-drop" onClick={openImageFileManager}>
                                                                <UploadIcon />
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    value={''}
                                                                    ref={uploadImageRef}
                                                                    accept=".jpg,.jpeg,.png,"
                                                                    className="select-post-image"
                                                                    onChange={(e) => {
                                                                        handleUpload(e, setFieldValue)
                                                                    }}
                                                                />
                                                            </span>
                                                            <Typography
                                                                as="span"
                                                                variant="body-2"
                                                                fontWeight="medium"
                                                                className="dynamic-field__upload-file__browse"
                                                                onClick={openImageFileManager}
                                                            >
                                                                Upload Image
                                                            </Typography>
                                                        </div>
                                                    </>
                                                    {bannerLoading && <Loader />}

                                                    {values?.thumbnailUrl && !bannerLoading && <Row>
                                                        <Col md={12}>
                                                            <div className='position-relative w-100'>
                                                                <button style={{ position: 'absolute', top: '10px', right: '10px', background: '#fff', height: '23px', fontSize: '14px', width: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: 'none', color: 'red' }} onClick={() => setFieldValue('thumbnailUrl', '')}>
                                                                    X
                                                                </button>
                                                                <img src={values?.thumbnailUrl} alt='logo' style={{ width: '100%', height: '120px', objectFit: 'cover', backgroundPosition: 'center', marginTop: '10px', borderRadius: '10px' }} />
                                                            </div>
                                                        </Col>
                                                    </Row>}

                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                            <InputField
                                                required
                                                label="Title"
                                                id="title"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Title"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputField
                                                label="Organizer's Name"
                                                id="organizerName"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="Organizer's Name"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputField
                                                required
                                                label="Date"
                                                min={disablePastDate()}
                                                id="date"
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder=""
                                                type="date"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputField
                                                label="Link"
                                                className='mb-3 mt-3'
                                                id="mediaUrl"
                                                value={values.mediaUrl}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMedia(e.target.value, setFieldValue)}
                                                variant={componentSizeVariantsEnum.SMALL}
                                                placeholder="https://youtu.be/WpA8vg5PmuQ?si=ri3YtaIi1fhIOC7k"
                                                type="text"
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <h5 className='typography__caption typography__semiBold mb-2'>Description</h5>
                                            <TextArea id='programObjective' placeholder='Description'
                                                required={true}
                                                value={values.description}
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('description', e.target.value)}
                                                textAreaStyle={{ width: '100%' }}
                                            />
                                            {touched.description && errors.description && <ErrorMessage message="Description is required" />}
                                        </Col>
                                    </Row>
                                </div>
                                <div className="add__global__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Cancel
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" loading={loading} disabled={globalLoading === 'create'}>
                                        {globalLoading === 'create' ? <Loader /> : serviceProviderCommonModal?.id ? "Update Events" : "Post Events"}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >
        </Modal >
    );
};

export default EventServiceProviderModal;
