import { useState } from 'react';
import { useUrlParamValue } from 'src/hooks';
import { Card, Typography } from 'src/components/common';
import { emptyChatLogo } from 'src/assets/images';
import { TEditMessageSettings } from 'src/components/chat/chatMessages/chatMessages.types';

import ChatMessagesFooter from '../chatMessages/chatMessagesFooter/ChatMessagesFooter';
import ChatMessagesBody from '../chatMessages/chatMessagesBody/ChatMessagesBody';

import { TChatMessages } from './chatMessages.types';
import './chatMessages.scss';
import IdeaDiscussionGroupHeader from '../chatMessages/ideaDiscussionGroupHeader/IdeaDiscussionGroupHeader';

const ChatMessagesGroup = ({
  discussionType,
  groupId,
  // programId,
 
  companyId
}: TChatMessages) => {
  // Hooks
  const { paramValue } = useUrlParamValue();

  // State
  const [editMessageSettings, setEditMessageSettings] = useState<TEditMessageSettings>({
    editMode: false,
    messageId: '',
    message: ''
  });

  // Constants
  const conversationId = paramValue({ paramName: 'groupId' }) || '';

  const updateMessageSettings = ({ editMode, messageId, message }: TEditMessageSettings) => {
    setEditMessageSettings({ editMode, messageId, message });
  };

  if (!conversationId) {
    return (
      <Card className="chat-messages">
        <div className="chat-messages__no-chat-selected">
          <img src={emptyChatLogo} alt="no chat initiated" />
          <Typography variant="subHeading-2" fontWeight="semiBold">
            Start Chatting with Others!
          </Typography>
          <Typography as="p" variant="caption" fontWeight="regular">
            Your selected conversations will appear here. Choose a chat to begin and enjoy easy,
            friendly interactions!
          </Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card className="discussion-messages">
      <IdeaDiscussionGroupHeader
        discussionType={discussionType}
        groupId={groupId}
        // ideaId={ideaId}
        // isViewerOwner
      />
      <ChatMessagesBody
        groupId={groupId}
        updateMessageSettings={updateMessageSettings}
        groupMessageContext="idea"
      />
      <ChatMessagesFooter
        placeholder="Message..."
        groupId={groupId}
        editMessageSettings={editMessageSettings}
        updateMessageSettings={updateMessageSettings}
        groupMessageContext="program"
      />
    </Card>
  );
};

export default ChatMessagesGroup;
