import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Card, Loader, Typography } from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import {
  TListOneToOneChatsRequest,
  TListOneToOneChatsResponseData
} from 'src/services/apiEndpoint.types.ts';
import { clearChatLists, listOneToOneChats } from 'src/store/chat/chat.slice';
import useDebounce from 'src/hooks/useDebounce/useDebounce';

import { TChatListProps } from './chatList.types';
import './chatList.scss';

import ChatListUserCard from '../chatListUserCard/ChatListUserCard';
import Discussion from '../discussion/Discussion';

import { Container } from 'react-bootstrap';
import ToggleSwitch from 'src/components/common/ToggleSwitch/ToggleSwitch';

const ChatList = ({
  selectedUserId,
  selectedUserType,
  showChatList,
  authenticatedId,
  loggedInUserType
}: TChatListProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const {
    oneToOneChatListItemsPerPage,
    oneToOneChatLists,
    isDirectChatNotInitiated,
    listOneToOneChatsLoader
  } = useAppSelector((store) => store.chat);

  // State
  const [filterString, setFilterString] = useState<string>('');
  console.log(oneToOneChatLists, 'oneToOneChatLists');
  const initialValues = {
    filterString: ''
  };
  const [filteredList, setFilteredList] =
    useState<TListOneToOneChatsResponseData[]>(oneToOneChatLists);

  const handleChangeSetting = (e) => {
    if (e?.target?.checked) {
      setFilteredList(oneToOneChatLists.filter((e) => e.moduleType === 'ServiceProvider'));
    } else {
      console.log('dddddddddddd');
      setFilteredList(oneToOneChatLists.filter((e) => e.moduleType !== 'ServiceProvider'));
    }
  };
  const getChatLists = () => {
    const payload: TListOneToOneChatsRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      limit: oneToOneChatListItemsPerPage,
      offset: 1,
      ...(filterString && { filterString })
    };

    dispatch(listOneToOneChats(payload));
  };

  useDebounce({
    func: () => getChatLists(),
    delay: 500,
    dependency: filterString
  });

  useEffect(() => {
    getChatLists();

    return () => {
      dispatch(clearChatLists());
    };
  }, []);
  useEffect(() => {
    handleChangeSetting(false);
  }, [oneToOneChatLists]);
  return (
    <Card className={classNames('chat-list', { 'chat-list--show-on-mobile': showChatList })}>
      <div className="chat-list__heading">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Chat
        </Typography>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ setFieldValue }) => {
            return (
              <Form>
                <InputField
                  id="filterString"
                  startIcon={<SearchIcon />}
                  placeholder="Search"
                  onChange={(e) => {
                    const value = e.target.value;

                    setFieldValue('filterString', value);
                    setFilterString(value);
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <div>
        <Discussion />
      </div>

      {listOneToOneChatsLoader && <Loader />}
      <div className=" d-flex container p-3 justify-content-between align-items-center">
        <Typography as="span" variant="body-2" fontWeight="semiBold">
          Company Chat
        </Typography>
        <span>
          <ToggleSwitch id="company" color={'#fa4616'} onChange={handleChangeSetting} />

          {/* color={true ? '#fa4616' : '#bbb6b2'} */}
          {/* <ToggleSwitch
            id="company"
            color={'#bbb6b2'}
            // checked={true}
            // onChange={handleChangeSetting}
          /> */}
        </span>
      </div>
      {!isDirectChatNotInitiated && (
        <div className="chat-list__users">
          {filteredList?.map((user, index) => {
            // if (user.moduleType === 'General') {
            return (
              <ChatListUserCard
                key={index}
                {...user}
                selectedUserId={selectedUserId}
                selectedUserType={selectedUserType}
              />
            );
            // }
          })}
        </div>
      )}
      {isDirectChatNotInitiated && (
        <div className="chat-list__not-initiated">
          <Typography variant="subHeading-2" fontWeight="semiBold">
            No Users Yet!
          </Typography>
          <Typography variant="caption" fontWeight="regular">
            It looks like no one has been added yet. Reach out and start a conversation here.
          </Typography>
        </div>
      )}
    </Card>
  );
};

export default ChatList;
