import Button from 'src/components/button/Button';
import { fetchEvaluators } from 'src/store/ideaHub/ideaHub.slice';
import { useUrlParamValue } from 'src/hooks';
import React, { useEffect, useState } from 'react';
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';

import './details.scss';
import { TIdeaCollaborator, TIdeaCollaborationType } from '../../ideaHub.types';
import IdeaPeople from '../ideaPeople/IdeaPeople';
import ManageAccess from '../manageAccess/ManageAccess';
import IdeaLabelValue, { TIdeaLabelValue } from '../ideaLabelValue/IdeaLabelValue';
export type TIdeaDetailsDetailsProps = {
  title: string;
  domain: string;
  ideaSharedFor: string;
  owner: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
  };
  collaborators: TIdeaCollaborator[];
};

const IdeaEvaluationDetails = ({
  title,
  domain,
  owner,
  ideaSharedFor,
  collaborators: initialCollaborators
}: TIdeaDetailsDetailsProps) => {
  const dispatch = useAppDispatch();
  const { listEvaluators } = useAppSelector((store) => store.ideaHub);
  const { paramValue } = useUrlParamValue();
  const [collaborators, setCollaborators] = useState<TIdeaCollaborator[]>(initialCollaborators);

  const ideaId = paramValue({ paramName: 'id' }) as unknown as string;
  const manageAccessModalId = 'manageAccessModal';

  useEffect(() => {
    // Fetch evaluators when the component mounts
    dispatch(
      fetchEvaluators({
        ideaId,
        limit: 100, // Adjust as needed
        offset: 1
      })
    );
  }, [dispatch, ideaId]);

  useEffect(() => {
    // Transform and append evaluators to collaborators
    if (listEvaluators.length > 0) {
      const evaluatorCollaborators: TIdeaCollaborator[] = listEvaluators.map((evaluator) => ({
        id: evaluator.id,
        name: evaluator.name,
        email: evaluator.email,
        role: evaluator.role,
        profilePic: evaluator.profilePic,
        IdeaCollaboratorMapping: {
          collaborationType: 'edit' as TIdeaCollaborationType
        }
      }));

      // Combine initial collaborators with evaluators, removing duplicates
      const uniqueCollaborators = [
        ...initialCollaborators,
        ...evaluatorCollaborators.filter(
          (evaluator) => 
            !initialCollaborators.some((collab) => collab.id === evaluator.id)
        )
      ];

      setCollaborators(uniqueCollaborators);
    }
  }, [listEvaluators, initialCollaborators]);

  const fields: TIdeaLabelValue[] = [
    { label: 'Title', value: title },
    { label: 'Domain', value: domain }
  ];

  return (
    <div className="idea-details-details">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="idea-details-details__heading"
      >
        Overview
      </Typography>

      <div className="idea-details-details__people-access">
        <Typography
          as="p"
          variant="body-1"
          fontWeight="semiBold"
        >
          People access to this idea
        </Typography>
        {ideaSharedFor !== 'Evaluation' && <div className=''>
          <Button
            size="extraSmall"
            variant="tertiary"
            type="button"
            onClick={() => {
              dispatch(assignModalId(manageAccessModalId));
            }}
          >
            Manage Access
          </Button>
        </div>}
      </div>
      <IdeaPeople owner={owner} collaborators={collaborators} />
      <div className="idea-details-details__fields">
        {fields.map((field, index) => {
          return <IdeaLabelValue key={index} {...field} />;
        })}
      </div>
    </div>
  );
};

export default IdeaEvaluationDetails;
