import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import {
  Card,
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography
} from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Button from 'src/components/button/Button';
import { assignModalId, assignSubMenuModalId } from 'src/store/common/common.slice';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { textEllipsis, textFormatter, timeAgo } from 'src/utils';
import {
  TRespondToCollaborationRequest,
  TRespondToEvaluationRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  respondToCollaborationRequest,
  respondToEvaluationRequest
} from 'src/store/ideaHub/ideaHub.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';

import './ideaCard.scss';

import { SharedIdeaContext } from '../ideasSharedWithMe/IdeasSharedWithMe';
import {
  TIdeaSharedFor,
  TIdeaCollaborationType,
  TIdeaStatus,
  TIdeaUserActionType,
  TIdeaHubFilters
} from '../ideaHub.types';

export type TIdeaCardProps = {
  id: string;
  status: TIdeaStatus;
  title: string;
  description: string;
  updatedAt: string;
  collaborators: { id: string; name: string; profilePic: string }[];
  ideaFilterDropdownItems: TDropdownOptions[];
  sharedByUser?: { name: string; profilePic: string };
  acceptedByUser?: boolean;
  accessType?: 'View Access' | 'Edit Access' | 'Discussion Access';
  owner?: { id: string; name: string; email: string; role: string; profilePic: string };
  collaboratorCount: number;
  sharedFor?: TIdeaSharedFor;
  userCollaborationType?: TIdeaCollaborationType;
  userActionStatus?: TIdeaUserActionType;
};

const IdeaCard = ({
  id,
  status,
  title,
  description,
  updatedAt,
  collaborators,
  ideaFilterDropdownItems,
  acceptedByUser,
  accessType,
  owner,
  collaboratorCount,
  userActionStatus,
  sharedFor,
  userCollaborationType
}: TIdeaCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { dropdownId, modalId } = useAppSelector((store) => store.common);

  // Utils
  const titleWitEllipsis = textEllipsis({ text: title, charLimit: 15 });

  const uniqueKey = useMemo(() => {
    // idea id is not unique, as it can appear multiple times (as evaluator and collaborator)
    return nanoid();
  }, []);

  const ideaCardDropdownId: string = `ideaCardDropdown-${uniqueKey}`;
  const acceptCollaborationModalId = `acceptCollaborationModal-${uniqueKey}`;
  const rejectCollaborationModalId = `rejectCollaborationModal-${uniqueKey}`;
  const acceptEvaluationModalId = `acceptEvaluationModal-${uniqueKey}`;
  const rejectEvaluationModalId = `rejectEvaluationModal-${uniqueKey}`;

  const { loggedInUserId } = getIdentities();
  const isIdeaShared: boolean = useContext(SharedIdeaContext);
  const openAcceptCollaborationModal = modalId === acceptCollaborationModalId;
  const openRejectCollaborationModal = modalId === rejectCollaborationModalId;
  const openAcceptEvaluationModal = modalId === acceptEvaluationModalId;
  const openRejectEvaluationModal = modalId === rejectEvaluationModalId;

  const filterBy: TIdeaHubFilters = (paramValue({ paramName: 'filterBy' }) ||
    'latest') as TIdeaHubFilters;

  // Whether the idea is shared
  /*
   * Only hide status cards when the idea is shared not accepted by the user
   */
  let showStatusCards = true;
  if (!isIdeaShared) {
    showStatusCards = true;
  } else if (isIdeaShared && userActionStatus === 'Pending') {
    showStatusCards = false;
  }
  // const showStatusCards = useMemo(() => {
  //   if (!isIdeaShared) {
  //     return true;
  //   } else if (isIdeaShared && userActionStatus === 'Pending') {
  //     return false;
  //   }
  //   return true;
  // }, [isIdeaShared, userActionStatus]);
  /*
   * Only show pictures of 3 collaborators, then the remaining count represented as a number
   */
  const collaboratorsToRender: { name: string; profilePic: string }[] =
    collaboratorCount > 3 ? collaborators.slice(0, 3) : collaborators;

  const ideaFilterDropdownProps: TDropdown = {
    id: `${ideaCardDropdownId}-${uniqueKey}`,
    size: 'large',
    alignment: 'left',
    items: ideaFilterDropdownItems
  };

  const openIdeaDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(ideaFilterDropdownProps.id));
    }
  };

  const handleCloseModal = () => {
    dispatch(assignModalId(''));
  };

  const acceptCollaborationRequest = () => {
    const payload: TRespondToCollaborationRequest = {
      ideaId: id,
      userId: loggedInUserId,
      status: 'Accepted',
      filterBy
    };

    dispatch(respondToCollaborationRequest(payload));
  };

  const rejectCollaborationRequest = () => {
    const payload: TRespondToCollaborationRequest = {
      ideaId: id,
      userId: loggedInUserId,
      status: 'Rejected',
      filterBy
    };

    dispatch(respondToCollaborationRequest(payload));
  };

  const acceptEvaluationRequest = () => {
    const payload: TRespondToEvaluationRequest = {
      ideaId: id,
      userId: loggedInUserId,
      status: 'Accepted',
      filterBy
    };

    dispatch(respondToEvaluationRequest(payload));
  };

  const rejectEvaluationRequest = () => {
    const payload: TRespondToEvaluationRequest = {
      ideaId: id,
      userId: loggedInUserId,
      status: 'Rejected',
      filterBy
    };

    dispatch(respondToEvaluationRequest(payload));
  };

  const gotToViewIdea = () => {
    let params: string = `id=${id}&tab=ideaDetails`;
    if (sharedFor === 'Collaboration' || sharedFor === 'Evaluation') {
      params += `&collaborationType=${sharedFor}`;
    }
    if (showStatusCards) {
      navigate(`/view-idea?${params}`);
    }
  };
  if (collaboratorsToRender?.length >= 1 && owner) {
    const isOwnerAlreadyInList = collaboratorsToRender.some(
      collaborator => collaborator.name === owner.name
    );
    
    if (!isOwnerAlreadyInList) {
      collaboratorsToRender.push({
        name: owner.name,
        profilePic: owner.profilePic || ''
      });
    }
  }
  console.log(collaborators)
  return (
    <Card className="idea-card" onClick={gotToViewIdea}>
      <div className="idea-card__content">
        <div className="idea-card__content__header">
          {showStatusCards && (
            <div className="idea-card__content__header__cards">
              <Typography
                as="span"
                variant="caption"
                fontWeight="semiBold"
                className="idea-card__content__header__cards__status"
              >
                {status}
              </Typography>
              {/* {isIdeaShared && (
                <Typography
                  as="span"
                  variant="caption"
                  fontWeight="semiBold"
                  className="idea-card__content__header__cards__status"
                >
                  {sharedFor}
                </Typography>
              )} */}
              {userCollaborationType && (
                <Typography
                  as="span"
                  variant="caption"
                  fontWeight="semiBold"
                  className="idea-card__content__header__cards__status"
                >
                  {`${userCollaborationType === 'CoFounder' ? 'Co-founder' : userCollaborationType} Access`}
                </Typography>
              )}
              {sharedFor === 'Evaluation' && (
                <Typography
                  as="span"
                  variant="caption"
                  fontWeight="semiBold"
                  className="idea-card__content__header__cards__status"
                >
                  {userActionStatus === 'Complete' ? 'Rated this idea' : 'Rating Pending'}
                </Typography>
              )}
            </div>
          )}
          {!showStatusCards && isIdeaShared && (
            <div className="idea-card__content__header__idea-owner">
              <Typography as="span" variant="caption" fontWeight="regular">
                Shared by&#58;
              </Typography>
              <ProfilePicture
                url={owner?.profilePic || ''}
                fallback={textFormatter({ value: owner?.name[0] })}
                className="idea-card__content__header__idea-owner__profile-pic"
              />
              <Typography as="span" variant="body-2" fontWeight="medium">
                {owner?.name}
              </Typography>
            </div>
          )}
          <div
            className="idea-card__content__header__dropdown"
            onClick={(e) => {
              e.stopPropagation();
              openIdeaDropdown();
            }}
          >
            {<MoreOptionsIcon />}{' '}
            <SubMenuModal>
              <Dropdown {...ideaFilterDropdownProps} />
            </SubMenuModal>
          </div>
        </div>
        <Tooltip text={title} conditionToDisplay={titleWitEllipsis?.hasEllipsis} limitWidth>
          <Typography
            variant="subHeading-2"
            fontWeight="semiBold"
            className="idea-card__content__title"
          >
            {titleWitEllipsis?.text}
          </Typography>
        </Tooltip>
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="idea-card__content__description"
        >
          {description}
        </Typography>
        <div className="idea-card__content__row idea-card__content__place-bottom">
          {!showStatusCards && (
            <Typography
              as="span"
              variant="caption"
              fontWeight="semiBold"
              className="idea-card__content__row__status"
            >
              {sharedFor}
            </Typography>
          )}
          <Typography
            as="span"
            variant="caption"
            fontWeight="regular"
            className="idea-card__content__row__saved-time"
          >
            Last edited: {timeAgo({ timestamp: updatedAt })}
          </Typography>
        </div>
      </div>
      {showStatusCards && collaboratorsToRender?.length > 0 && (
        <div className="idea-card__collaborators">
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Collaborators:
          </Typography>
          <div className="idea-card__collaborators__pictures">
            {collaboratorsToRender.map(({ name = '', profilePic = '' }, index) => (
              <ProfilePicture
                key={index}
                url={profilePic}
                fallback={name[0]}
                className="idea-card__collaborators__pictures__picture"
                style={{
                  transform: `translateX(${(collaboratorsToRender?.length - index) * 13}px)`,
                  zIndex: index + 1
                }}
              />
            ))}
            {collaboratorCount > 3 && (
              <div
                className="idea-card__collaborators__pictures__remaining"
                style={{
                  zIndex: 4
                }}
              >
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  +{collaboratorCount - 3}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}
      {showStatusCards && collaboratorsToRender?.length === 0 && (
        <div className="idea-card__collaborators">
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Collaborators:
          </Typography>
          <div className="idea-card__collaborators__pictures">
            <ProfilePicture
              url={owner?.profilePic || ''}
              fallback={owner?.name[0]}
              className="idea-card__collaborators__pictures__picture"
            />
          </div>
        </div>
      )}
      {!showStatusCards && (
        <div
          className="idea-card__accept-idea"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            variant="secondary"
            size="extraSmall"
            onClick={(e) => {
              e.stopPropagation();
              if (sharedFor === 'Collaboration') {
                dispatch(assignModalId(rejectCollaborationModalId));
              } else if (sharedFor === 'Evaluation') {
                dispatch(assignModalId(rejectEvaluationModalId));
              }
            }}
          >
            Reject
          </Button>{' '}
          <Button
            variant="primary"
            size="extraSmall"
            onClick={(e) => {
              e.stopPropagation();
              if (sharedFor === 'Collaboration') {
                dispatch(assignModalId(acceptCollaborationModalId));
              } else if (sharedFor === 'Evaluation') {
                dispatch(assignModalId(acceptEvaluationModalId));
              }
            }}
          >
            Accept
          </Button>
        </div>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {openAcceptCollaborationModal && (
          <ConfirmModal
            title={`Accept collaboration request from ${owner?.name}?`}
            description={`Click 'Confirm' to accept and start collaborating with ${owner?.name} on this idea.`}
            openConfirmModal={openAcceptCollaborationModal}
            submitText="Confirm"
            onClose={handleCloseModal}
            onSubmit={acceptCollaborationRequest}
            type="error"
          />
        )}
        {openRejectCollaborationModal && (
          <ConfirmModal
            title={`Reject Collaboration request from ${owner?.name}?`}
            description={`Click 'Confirm' to reject collaborating with ${owner?.name} on this idea.`}
            openConfirmModal={openRejectCollaborationModal}
            submitText="Confirm"
            onClose={handleCloseModal}
            onSubmit={rejectCollaborationRequest}
            type="error"
          />
        )}
        {openAcceptEvaluationModal && (
          <ConfirmModal
            title={`Accept evaluation request from ${owner?.name}?`}
            description={"Click 'Confirm' to accept and start evaluating this idea."}
            openConfirmModal={openAcceptEvaluationModal}
            submitText="Confirm"
            onClose={handleCloseModal}
            onSubmit={acceptEvaluationRequest}
            type="error"
          />
        )}
        {openRejectEvaluationModal && (
          <ConfirmModal
            title={`Reject evaluation request from ${owner?.name}?`}
            description={"Click 'Confirm' to reject evaluation of this idea"}
            openConfirmModal={openRejectEvaluationModal}
            submitText="Confirm"
            onClose={handleCloseModal}
            onSubmit={rejectEvaluationRequest}
            type="error"
          />
        )}
      </div>
    </Card>
  );
};

export default IdeaCard;
