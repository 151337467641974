import { AxiosError } from 'axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { errorMessages } from 'src/constants';
import { TGetIdea, TMyIdea } from 'src/components/ideaHub';
import {
  TCreateIdeaRequest,
  TCreateIdeaResponse,
  TEditIdeaRequest,
  TEditIdeaResponse,
  TGetCollaboratorsRequest,
  TGetCollaboratorsResponse,
  TGetCollaboratorsResponseData,
  TGetIdeaRequest,
  TGetIdeaResponse,
  TIdeasSharedWithMeRequest,
  TIdeasSharedWithMeResponse,
  TListDocumentsRequest,
  TListDocumentsResponse,
  TListEvaluationResponsesData,
  TListEvaluationResponsesRequest,
  TListEvaluationResponsesResponse,
  TMyIdeasRequest,
  TMyIdeasResponse,
  TSearchCollaboratorsRequest,
  TSearchCollaboratorsResponse,
  TSearchCollaborator,
  TAddCollaboratorsRequest,
  TAddCollaboratorsResponse,
  TFinalizeIdeaResponse,
  TFinalizeIdeaRequest,
  TValidateIdeaRequest,
  TValidateIdeaResponse,
  TSearchEvaluator,
  TSearchEvaluatorsRequest,
  TSearchEvaluatorsResponse,
  TAddEvaluatorsRequest,
  TAddEvaluatorsResponse,
  TEditCollaboratorsRequest,
  TEditCollaboratorsResponse,
  TGetDocumentPreSignedUrlResponse,
  TGetDocumentPreSignedUrlActionRequest,
  TUploadDocumentResponse,
  TGetDocumentPreSignedUrlRequest,
  TGetDocumentRequest,
  TUploadDocumentActionRequest,
  TRespondToCollaborationResponse,
  TRespondToCollaborationRequest,
  TRespondToEvaluationRequest,
  TRespondToEvaluationResponse,
  TSubmitEvaluationRequest,
  TSubmitEvaluationResponse,
  TRecommendedEvaluatorRequest,
  TRecommendedEvaluatorResponse,
  TRecommendedEvaluator,
  TListEvaluator,
  TListEvaluatorsRequest,
  TListEvaluatorsResponse,
  TDeleteDocumentRequest,
  TDeleteDocumentResponse,
  TGetIdeaRequestsCountRequest,
  TGetIdeaRequestsCountResponse
} from 'src/services/apiEndpoint.types.ts';
import { APIResponse } from 'src/services/base.api.service';
import { IdeaHubAPIService } from 'src/services/ideaHub.api.service';
import {
  TEvaluatorResponse,
  TIdeaDocument,
  TIdeaSharedWithMe
} from 'src/components/ideaHub/ideaHub.types';

import initialState from './ideaHub.slice.initialState';

import { AppDispatch } from '../store';
import { assignModalId, openPopup, startLoading, stopLoading } from '../common/common.slice';
import { updateIdeaHubNotificationCount } from '../notifications/notifications.slice';

export type TInitialState = {
  // My ideas
  myIdeas: TMyIdea[];
  myIdeasPageNumber: number;
  myIdeasItemsPerPage: number;
  myIdeasMoreItemsLeft: boolean;
  // Ideas shared with me
  ideasSharedWithMe: TIdeaSharedWithMe[];
  ideasSharedWithMePageNumber: number;
  ideasSharedWithMeItemsPerPage: number;
  ideasSharedWithMeMoreItemsLeft: boolean;
  ideasFeedLoading: boolean;
  viewIdea: TGetIdea;
  viewEvaluatorResponses: TEvaluatorResponse[];
  viewEvaluatorResponsesCount: number;
  viewCollaborators: TGetCollaboratorsResponseData[];
  viewDocuments: TIdeaDocument[];
  searchUserLoading: boolean;
  searchCollaboratorsList: TSearchCollaborator[];
  searchCollaboratorsPageNumber: number;
  searchCollaboratorsItemsPerPage: number;
  searchCollaboratorsMoreItemsLeft: boolean;
  searchEvaluatorsList: TSearchEvaluator[];
  searchEvaluatorsPageNumber: number;
  searchEvaluatorsItemsPerPage: number;
  searchEvaluatorsMoreItemsLeft: boolean;
  recommendedEvaluators: TRecommendedEvaluator[];
  // List evaluators
  listEvaluators: TListEvaluator[];
  listEvaluatorsPageNumber: number;
  listEvaluatorsItemsPerPage: number;
  listEvaluatorsMoreItemsLeft: boolean;
};

export const ideaHubSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    createIdeaSuccess: () => {},
    fetchMyIdeaSuccess: (state, action: PayloadAction<{ data: TMyIdea[]; offset?: number }>) => {
      const myIdeas = action?.payload?.data || [];
      const { offset } = action?.payload;

      if (offset === 1) {
        state.myIdeas = myIdeas;
      } else {
        const currentItems: TMyIdea[] = JSON.parse(JSON.stringify(state.myIdeas));

        myIdeas.forEach((item) => {
          const itemId = item?.idea?.id;

          const indexInExistingUsers = currentItems.findIndex(
            (CItem) => CItem?.idea?.id === itemId
          );

          if (indexInExistingUsers < 0) {
            currentItems.push(item);
          }
        });

        if (currentItems) {
          state.myIdeas = currentItems;
        }
      }

      if (myIdeas?.length > 0) {
        state.myIdeasMoreItemsLeft = true;
      }

      if (offset) {
        state.myIdeasPageNumber = offset;
      }

      if (myIdeas.length === 0) {
        state.myIdeasMoreItemsLeft = false;
      }
    },
    incrementMyIdeasPage: (state, action: PayloadAction<number>) => {
      state.myIdeasPageNumber = state.myIdeasPageNumber + action.payload;
    },
    resetMyIdeasPage: (state) => {
      state.myIdeasPageNumber = 1;
      state.myIdeasMoreItemsLeft = true;
      state.myIdeas = [];
    },
    startIdeasLoading: (state) => {
      state.ideasFeedLoading = true;
    },
    stopIdeasLoading: (state) => {
      state.ideasFeedLoading = false;
    },
    fetchIdeasSharedWithSuccess: (
      state,
      action: PayloadAction<{ data: TIdeaSharedWithMe[]; offset?: number }>
    ) => {
      const ideasSharedWithMe = action?.payload?.data || [];
      const { offset } = action?.payload;

      if (offset === 1) {
        state.ideasSharedWithMe = ideasSharedWithMe;
      } else {
        const currentItems: TIdeaSharedWithMe[] = JSON.parse(
          JSON.stringify(state.ideasSharedWithMe)
        );

        ideasSharedWithMe.forEach((item) => {
          const itemId = item?.idea?.id;
          const itemSharedFor = item?.idea?.sharedFor;

          // Multiple ideas with the same id butter different sharedFor values
          const indexInExistingUsers = currentItems.findIndex((CItem) => {
            return CItem?.idea?.id === itemId && CItem?.idea?.sharedFor === itemSharedFor;
          });

          if (indexInExistingUsers < 0) {
            currentItems.push(item);
          }
        });

        if (currentItems) {
          // Not using currentItems because duplicate items are received.
          state.ideasSharedWithMe = currentItems;
        }
      }

      if (ideasSharedWithMe?.length > 0) {
        state.ideasSharedWithMeMoreItemsLeft = true;
      }

      if (offset) {
        state.ideasSharedWithMePageNumber = offset;
      }

      if (ideasSharedWithMe.length === 0) {
        state.ideasSharedWithMeMoreItemsLeft = false;
      }
    },
    incrementIdeasSharedPage: (state, action: PayloadAction<number>) => {
      state.ideasSharedWithMePageNumber = state.ideasSharedWithMePageNumber + action.payload;
    },
    resetMyIdeasSharedPage: (state) => {
      state.ideasSharedWithMePageNumber = 1;
      state.ideasSharedWithMeMoreItemsLeft = true;
      state.ideasSharedWithMe = [];
    },
    fetchIdeaSuccess: (state, action: PayloadAction<TGetIdea>) => {
      const idea: TGetIdea = action.payload;

      if (idea) {
        state.viewIdea = idea;
      }
    },
    fetchEvaluatorResponsesSuccess: (
      state,
      action: PayloadAction<TListEvaluationResponsesData>
    ) => {
      const { responses, count }: TListEvaluationResponsesData = action.payload;

      if (responses) {
        state.viewEvaluatorResponses = responses;
      }
      if (count) {
        state.viewEvaluatorResponsesCount = count;
      }
    },
    getCollaboratorsSuccess: (state, action: PayloadAction<TGetCollaboratorsResponseData[]>) => {
      const collaborators: TGetCollaboratorsResponseData[] = action.payload;

      if (collaborators) {
        state.viewCollaborators = collaborators;
      }
    },
    listDocumentsSuccess: (state, action: PayloadAction<TIdeaDocument[]>) => {
      const documents: TIdeaDocument[] = action.payload;

      if (documents) {
        state.viewDocuments = documents;
      }
    },
    searchCollaboratorsSuccess: (
      state,
      action: PayloadAction<{
        users: TSearchCollaborator[];
        currentPage: number;
        itemsPerPage: number;
        totalItems: number;
        totalPages: number;
      }>
    ) => {
      const currentSearchUsers: TSearchCollaborator[] = JSON.parse(
        JSON.stringify(state.searchCollaboratorsList)
      );

      const { users, totalPages } = action.payload;

      users.forEach((item) => {
        const itemId = item.id;

        const indexInExistingUsers = currentSearchUsers.findIndex((user) => user.id === itemId);

        if (indexInExistingUsers < 0) {
          currentSearchUsers.push(item);
        }
      });

      state.searchCollaboratorsList = currentSearchUsers;
      state.searchCollaboratorsMoreItemsLeft = state.searchCollaboratorsPageNumber < totalPages;
    },
    incrementSearchCollaboratorsPage: (state, action: PayloadAction<number>) => {
      state.searchCollaboratorsPageNumber = state.searchCollaboratorsPageNumber + action.payload;
    },
    resetSearchCollaborators: (state) => {
      state.searchCollaboratorsList = [];
      state.searchCollaboratorsPageNumber = 1;
      state.searchCollaboratorsMoreItemsLeft = true;
    },
    searchEvaluatorsSuccess: (
      state,
      action: PayloadAction<{
        users: TSearchEvaluator[];
        currentPage: number;
        itemsPerPage: number;
        totalItems: number;
        totalPages: number;
      }>
    ) => {
      const currentSearchUsers: TSearchEvaluator[] = JSON.parse(
        JSON.stringify(state.searchEvaluatorsList)
      );

      const { users, totalPages } = action.payload;

      users.forEach((item) => {
        const itemId = item.id;

        const indexInExistingUsers = currentSearchUsers.findIndex((user) => user.id === itemId);

        if (indexInExistingUsers < 0) {
          currentSearchUsers.push(item);
        }
      });

      state.searchEvaluatorsList = currentSearchUsers;
      state.searchEvaluatorsMoreItemsLeft = state.searchEvaluatorsPageNumber < totalPages;
    },
    incrementSearchEvaluatorsPage: (state, action: PayloadAction<number>) => {
      state.searchEvaluatorsPageNumber = state.searchEvaluatorsPageNumber + action.payload;
    },
    resetSearchEvaluators: (state) => {
      state.searchEvaluatorsList = [];
      state.searchEvaluatorsPageNumber = 1;
      state.searchEvaluatorsMoreItemsLeft = true;
    },
    fetchRecommendedEvaluatorsSuccess: (
      state,
      action: PayloadAction<{
        users: TRecommendedEvaluator[];
        totalItems: number;
        currentPage: number;
        itemsPerPage: number;
        totalPages: number;
      }>
    ) => {
      const { users } = action.payload;

      state.recommendedEvaluators = users;
    },
    respondToCollaborationRequestSuccess: (
      state,
      action: PayloadAction<TRespondToCollaborationRequest>
    ) => {
      const { ideaId, status } = action.payload;

      const currentIdeas: TIdeaSharedWithMe[] = JSON.parse(JSON.stringify(state.ideasSharedWithMe));

      const selectedIdea = currentIdeas.find((idea) => idea?.idea?.id === ideaId);
      const selectedIdeaIndex = currentIdeas.findIndex((idea) => idea?.idea?.id === ideaId);

      if (status === 'Accepted' && selectedIdea) {
        selectedIdea.idea.userActionStatus = 'Accepted';
      } else if (status === 'Rejected' && selectedIdeaIndex > -1) {
        currentIdeas.splice(selectedIdeaIndex, 1);
      }

      state.ideasSharedWithMe = currentIdeas;
    },
    /*
     ** List evaluators
     */
    fetchListEvaluatorsSuccess: (
      state,
      action: PayloadAction<{
        users: TListEvaluator[];
        currentPage: number;
        itemsPerPage: number;
        totalItems: number;
        totalPages: number;
      }>
    ) => {
      const { users, totalPages, currentPage } = action.payload;

      if (currentPage === 1) {
        state.listEvaluators = users;
      } else {
        const currentEvaluators: TListEvaluator[] = JSON.parse(
          JSON.stringify(state.listEvaluators)
        );

        users.forEach((item) => {
          const itemId = item.id;

          const indexInExistingUsers = currentEvaluators.findIndex((user) => user.id === itemId);

          if (indexInExistingUsers < 0) {
            currentEvaluators.push(item);
          }
        });

        state.listEvaluators = currentEvaluators;
      }

      state.listEvaluatorsPageNumber = currentPage;
      state.listEvaluatorsMoreItemsLeft = currentPage < totalPages;
    },
    incrementListEvaluatorsPage: (state, action: PayloadAction<number>) => {
      state.listEvaluatorsPageNumber = state.listEvaluatorsPageNumber + action.payload;
    },
    resetListEvaluators: (state) => {
      state.listEvaluators = [];
      state.listEvaluatorsPageNumber = 1;
      state.listEvaluatorsMoreItemsLeft = true;
    },
    fetchMyIdeaError: () => {},
    startSearchUsersLoading: (state) => {
      state.searchUserLoading = true;
    },
    stopSearchUsersLoading: (state) => {
      state.searchUserLoading = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createIdeaSuccess,
  fetchMyIdeaSuccess,
  incrementMyIdeasPage,
  incrementIdeasSharedPage,
  resetMyIdeasSharedPage,
  resetMyIdeasPage,
  startIdeasLoading,
  stopIdeasLoading,
  fetchIdeasSharedWithSuccess,
  fetchIdeaSuccess,
  fetchEvaluatorResponsesSuccess,
  getCollaboratorsSuccess,
  listDocumentsSuccess,
  searchCollaboratorsSuccess,
  incrementSearchCollaboratorsPage,
  resetSearchCollaborators,
  searchEvaluatorsSuccess,
  incrementSearchEvaluatorsPage,
  resetSearchEvaluators,
  startSearchUsersLoading,
  stopSearchUsersLoading,
  respondToCollaborationRequestSuccess,
  fetchRecommendedEvaluatorsSuccess,
  fetchListEvaluatorsSuccess,
  incrementListEvaluatorsPage,
  resetListEvaluators
} = ideaHubSlice.actions;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;
  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] || errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const createIdea = (payload: TCreateIdeaRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TCreateIdeaResponse> =
      await new IdeaHubAPIService().createIdea(payload);
    if (status === 200) {
      const ideaId = data?.data?.id;
      const navigateTo = ideaId
        ? `/view-idea?id=${ideaId}&tab=ideaDetails`
        : '/idea-hub?tab=myIdea';
      dispatch(createIdeaSuccess());
      dispatch(
        openPopup({
          popupMessage: 'Your idea details created successfully!',
          popupType: 'success',
          navigateTo
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getMyIdeas = (payload: TMyIdeasRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startIdeasLoading());
    const { status, data }: APIResponse<TMyIdeasResponse> = await new IdeaHubAPIService().myIdeas(
      payload
    );
    if (status === 200 && data) {
      dispatch(fetchMyIdeaSuccess({ data: data?.data, offset: payload?.offset }));
    }
  } catch (err) {
  } finally {
    dispatch(stopIdeasLoading());
  }
};

export const ideasSharedWithMe =
  (payload: TIdeasSharedWithMeRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startIdeasLoading());
      const { status, data }: APIResponse<TIdeasSharedWithMeResponse> =
        await new IdeaHubAPIService().sharedWithMe(payload);
      if (status === 200 && data) {
        dispatch(fetchIdeasSharedWithSuccess({ data: data?.data, offset: payload?.offset }));
      }
    } catch (err) {
    } finally {
      dispatch(stopIdeasLoading());
    }
  };

export const getIdea = (payload: TGetIdeaRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TGetIdeaResponse> = await new IdeaHubAPIService().getIdea(
      payload
    );
    if (status === 200 && data) {
      dispatch(fetchIdeaSuccess(data?.data));
    }
  } catch (err) {
    return err;
  } finally {
    dispatch(stopLoading());
  }
};

export const getIdeaForValidate = (payload: TGetIdeaRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TGetIdeaResponse> = await new IdeaHubAPIService().getIdea(
      payload
    );
    if (status === 200 && data) {
      dispatch(fetchIdeaSuccess(data?.data));
    }
  } catch (err) {
    return err;
  } finally {
    dispatch(stopLoading());
  }
};

export const listEvaluatorResponses =
  (payload: TListEvaluationResponsesRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TListEvaluationResponsesResponse> =
        await new IdeaHubAPIService().listEvaluationResponses(payload);
      if (status === 200 && data) {
        dispatch(fetchEvaluatorResponsesSuccess(data?.data));
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const getCollaborators =
  (payload: TGetCollaboratorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetCollaboratorsResponse> =
        await new IdeaHubAPIService().getCollaborators(payload);
      if (status === 200 && data) {
        dispatch(getCollaboratorsSuccess(data?.data));
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const listDocuments = (payload: TListDocumentsRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TListDocumentsResponse> =
      await new IdeaHubAPIService().listDocuments(payload);
    if (status === 200 && data) {
      dispatch(listDocumentsSuccess(data?.data));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};

export const editIdea = (payload: TEditIdeaRequest) => async (dispatch: AppDispatch) => {
  const { discardIdea, ...editPayload } = payload;
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TEditIdeaResponse> = await new IdeaHubAPIService().editIdea(
      editPayload
    );
    if (status === 200) {
      if (payload.discardIdea) {
        dispatch(assignModalId(''));
      }
      if (!payload.discardIdea) {
        dispatch(
          openPopup({
            popupMessage: 'Your idea details updated successfully!',
            popupType: 'success',
            navigateTo: `/view-idea?id=${payload.ideaId}&tab=ideaDetails`
          })
        );
      }
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const searchCollaborators =
  (payload: TSearchCollaboratorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startSearchUsersLoading());
      const { status, data }: APIResponse<TSearchCollaboratorsResponse> =
        await new IdeaHubAPIService().searchCollaborators(payload);
      if (status === 200 && data) {
        dispatch(searchCollaboratorsSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopSearchUsersLoading());
    }
  };

export const addCollaborators =
  (payload: TAddCollaboratorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TAddCollaboratorsResponse> =
        await new IdeaHubAPIService().addCollaborators(payload);
      if (status === 200 && data) {
        dispatch(
          openPopup({
            popupMessage: 'Your have successfully added a collaborator!',
            popupType: 'success'
          })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const editCollaborators =
  (payload: TEditCollaboratorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TEditCollaboratorsResponse> =
        await new IdeaHubAPIService().editCollaborators(payload);
      if (status === 200 && data) {
        dispatch(
          openPopup({
            popupMessage: 'Your have successfully updated collaborators!',
            popupType: 'success'
          })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const searchEvaluators =
  (payload: TSearchEvaluatorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startSearchUsersLoading());
      const { status, data }: APIResponse<TSearchEvaluatorsResponse> =
        await new IdeaHubAPIService().searchEvaluators(payload);
      if (status === 200 && data) {
        dispatch(searchEvaluatorsSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopSearchUsersLoading());
    }
  };

export const addEvaluators = (payload: TAddEvaluatorsRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TAddEvaluatorsResponse> =
      await new IdeaHubAPIService().addEvaluators(payload);
    if (status === 200 && data) {
      dispatch(
        openPopup({
          popupMessage: 'Your idea  has been successfully shared with the users.',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const fetchRecommendedEvaluators =
  (payload: TRecommendedEvaluatorRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TRecommendedEvaluatorResponse> =
        await new IdeaHubAPIService().recommendedEvaluators(payload);
      if (status === 200 && data) {
        dispatch(fetchRecommendedEvaluatorsSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const finalizeIdea = (payload: TFinalizeIdeaRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startSearchUsersLoading());
    const { status, data }: APIResponse<TFinalizeIdeaResponse> =
      await new IdeaHubAPIService().finalizeIdea(payload);
    if (status === 200 && data) {
      dispatch(
        openPopup({
          popupMessage: 'Congratulations! Your idea has been successfully finalized.',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopSearchUsersLoading());
  }
};

export const validateIdea = (payload: TValidateIdeaRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(
      openPopup({
        popupMessage: 'Please wait it may take a while',
        popupType: 'wait'
      })
    );
    dispatch(startSearchUsersLoading());
    const { status, data }: APIResponse<TValidateIdeaResponse> =
      await new IdeaHubAPIService().validateIdea(payload);
    if (status === 200 && data) {
      dispatch(
        openPopup({
          popupMessage: 'Your idea has been successfully validated!',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopSearchUsersLoading());
  }
};

export const uploadDocument =
  (payload: TUploadDocumentActionRequest) => async (dispatch: AppDispatch) => {
    const { ideaId, ...uploadDocumentPayload } = payload;
    try {
      const { status }: APIResponse<TUploadDocumentResponse> =
        await new IdeaHubAPIService().uploadDocument({
          presignedUrl: uploadDocumentPayload.presignedUrl,
          data: uploadDocumentPayload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        dispatch(listDocuments({ ideaId }));
        stopLoading()
        dispatch(
          openPopup({
            popupMessage: 'You have successfully upload the document!',
            popupType: 'success'
          })
        );
      }
    } catch (err) {
      stopLoading()
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const getDocumentPresignedUrl =
  (payload: TGetDocumentPreSignedUrlActionRequest) => async (dispatch: AppDispatch) => {
    const { id, ideaId, fileName } = payload;

    const getDocumentPresignedUrlPayload: TGetDocumentPreSignedUrlRequest = {
      id,
      ideaId,
      fileName
    };
    try {
      startLoading()
      const { status, data }: APIResponse<TGetDocumentPreSignedUrlResponse> =
        await new IdeaHubAPIService().getDocumentSignedUrl(getDocumentPresignedUrlPayload);
      if (status === 200 && data?.data && payload?.data) {
        dispatch(
          uploadDocument({ presignedUrl: data?.data?.presignedUrl, data: payload?.data, ideaId })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      stopLoading()
    }
  };

export const getDocument = (payload: TGetDocumentRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<TGetDocumentPreSignedUrlResponse> =
      await new IdeaHubAPIService().getDocument(payload);
    if (status === 200 && data?.data) {
      window.open(data?.data?.presignedUrl);
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const respondToCollaborationRequest =
  (payload: TRespondToCollaborationRequest) => async (dispatch: AppDispatch) => {
    try {
      const { filterBy, ...respondPayload } = payload;
      const { status }: APIResponse<TRespondToCollaborationResponse> =
        await new IdeaHubAPIService().respondToCollaborationRequest(respondPayload);
      if (status === 200) {
        dispatch(
          ideasSharedWithMe({ userId: payload?.userId, limit: 12, offset: 1, orderBy: filterBy })
        );
        dispatch(
          openPopup({
            popupMessage:
              payload?.status === 'Accepted'
                ? 'Request Accepted! You can now collaborate with the user on this idea'
                : 'Request Rejected! You have rejected the collaboration request.',
            popupType: 'success'
          })
        );

        return status;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const respondToEvaluationRequest =
  (payload: TRespondToEvaluationRequest) => async (dispatch: AppDispatch) => {
    try {
      const { filterBy, ...respondPayload } = payload;
      const { status }: APIResponse<TRespondToEvaluationResponse> =
        await new IdeaHubAPIService().respondToEvaluationRequest(respondPayload);
      if (status === 200) {
        dispatch(
          ideasSharedWithMe({ userId: payload?.userId, limit: 12, offset: 1, orderBy: filterBy })
        );
        dispatch(
          openPopup({
            popupMessage:
              payload?.status === 'Accepted'
                ? 'Request Accepted! You can now evaluate this idea'
                : 'Request Rejected! You have rejected the evaluation request.',
            popupType: 'success'
          })
        );

        return status;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const submitEvaluation =
  (payload: TSubmitEvaluationRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TSubmitEvaluationResponse> =
        await new IdeaHubAPIService().submitEvaluation(payload);
      if (status === 200) {
        dispatch(getIdea({ ideaId: payload.ideaId, userId: payload.userId }));
        dispatch(listEvaluatorResponses({ ideaId: payload.ideaId, limit: 100, offset: 1 }));
        dispatch(
          openPopup({
            popupMessage: 'You have successfully rated this idea!',
            popupType: 'success'
          })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const fetchEvaluators =
  (payload: TListEvaluatorsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startSearchUsersLoading());
      const { status, data }: APIResponse<TListEvaluatorsResponse> =
        await new IdeaHubAPIService().listEvaluators(payload);
      if (status === 200 && data) {
        dispatch(fetchListEvaluatorsSuccess(data?.data));
      }
    } catch (err) {
    } finally {
      dispatch(stopSearchUsersLoading());
    }
  };

export const deleteDocument =
  (payload: TDeleteDocumentRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TDeleteDocumentResponse> =
        await new IdeaHubAPIService().deleteDocument(payload);
      if (status === 200 && data) {
        dispatch(listDocuments({ ideaId: payload.ideaId }));
        dispatch(
          openPopup({
            popupMessage: 'You have successfully deleted the document!',
            popupType: 'success'
          })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getIdeaRequestsCount =
  (payload: TGetIdeaRequestsCountRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startSearchUsersLoading());
      const { status, data }: APIResponse<TGetIdeaRequestsCountResponse> =
        await new IdeaHubAPIService().getIdeaRequestsCount(payload);
      if (status === 200 && data) {
        dispatch(updateIdeaHubNotificationCount(data?.data?.totalPendingRequest));
      }
    } catch (err) {
    } finally {
      dispatch(stopSearchUsersLoading());
    }
  };

export default ideaHubSlice.reducer;
