import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import { optionsSlider1 } from 'src/constants/OwlCarouselConstant';
import cx from './topproduct.module.scss';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Loader } from 'src/components/common';
import { clearTopCompany, getMarketPlaceProduct, getTopProductSuccess } from 'src/store/marketplace/marketplace.slice';
import { useNavigate } from 'react-router-dom';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';

export default function TopProduct({ gb }: any) {
    const { topProduct, productLoading } = useAppSelector((state) => state.marketPlace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/all?dataType=top-Product')
    }
    useEffect(() => {
        dispatch(getMarketPlaceProduct({ dataType: 'top-Product', pageSize: 11, page: 1 }))
        return () => {
            dispatch(getTopProductSuccess([]))
        }
    }, [dispatch])
    if (productLoading) {
        return <Loader />
    }

    return (
        <div className={`${gb.section}`}>
            <div className={`${gb.headBox}`}>
                <h5 className={`${gb.sectionHeading}`}>Top Product</h5>
                {topProduct?.length > 10 && <span onClick={handleNavigate}>See All</span>}
            </div>
            <div className={`${cx.topCompanies}`}>
                <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                    {topProduct?.map((product, p: number) => {
                        return (
                            <CompanyCard key={p} dataType="Product" id={product.id} name={product.title} logo={product.thumbnailUrl} description={product?.description} />
                            // <ProductCard key={p} name={product.title} logo={product.thumbnailUrl} description={product?.description} />
                        )
                    })}
                </OwlCarousel>
                {!productLoading && topProduct?.length === 0 && <p className={`${cx.globalUnknonwn}`}>No Product Found</p>}
            </div>
        </div>
    )
}
