import { AppDispatch } from '../store';
import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessages } from 'src/constants';

import { initialState } from './marketplace.slice.initialState';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';
import { APIResponse } from 'src/services/base.api.service';
import { CoohortApiServices } from 'src/services/cohort.api.service';
import { MarketPlaceApiServices } from 'src/services/marketplace.api.service';
import { getCompany } from '../company/company.slice';
export const marketplace = createSlice({
  name: 'marketPlace',
  initialState,
  reducers: {
    globalLoadingStart: (state, action) => {
      state.globalLoading = action.payload
    },
    productLoadingStart: (state) => {
      state.productLoading = true
    },
    eventLoadingStart: (state) => {
      state.eventLoading = true
    },
    serviceLoadingStart: (state) => {
      state.serviceLoading = true
    },
    globalLoadingEnd: (state: any) => {
      state.globalLoading = ''
    },
    productLoadingEnd: (state: any) => {
      state.productLoading = false
    },
    eventLoadingEnd: (state: any) => {
      state.eventLoading = false
    },
    serviceLoadingEnd: (state: any) => {
      state.serviceLoading = false
    },
    changeCreationModalState: (state, action) => {
      state.serviceProviderCommonModal = action.payload
    },
    getTopCompanySuccess: (state, action) => {
      state.counter = action.payload.count
      state[action.payload.type] = action.payload.data
    },
    getTopProductSuccess: (state, action) => {
      state.topProduct = action.payload
    },
    getEventSuccess: (state, action) => {
      state.topEvent = action.payload
    },
    getServiceSuccess: (state, action) => {
      state.topService = action.payload
    },
    clearTopCompany: (state, action) => {
      state.counter = 0
      state[action.payload.type] = []
    },
    getMarketplaceItems: (state, action) => {
      state[action.payload.type] = action.payload.data
    },
    clearMarketPlaceItems: (state, action) => {
      state[action.payload.type] = []
    },
    getGlobalServiceSuccess: (state, action) => {
      state.globalServices = action.payload
    },
    getMarketPlaceServiceSuccess: (state, action) => {
      state.marketPlaceServices = action.payload
    },
    clearMarketPlaceService: (state) => {
      state.marketPlaceServices = []
    },
    clearGlobalServices: (state) => {
      state.globalServices = []
    },
    settingSuccess: (state, action) => {
      state.settings = action.payload
    },
    clearSetting: (state) => {
      state.settings = []
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  globalLoadingStart,
  globalLoadingEnd,
  productLoadingStart,
  productLoadingEnd,
  eventLoadingStart,
  serviceLoadingStart,
  serviceLoadingEnd,
  eventLoadingEnd,
  getTopCompanySuccess,
  getTopProductSuccess,
  getEventSuccess,
  getServiceSuccess,
  clearTopCompany,
  changeCreationModalState,
  getMarketplaceItems,
  clearMarketPlaceItems,
  getGlobalServiceSuccess,
  clearGlobalServices,
  getMarketPlaceServiceSuccess,
  clearMarketPlaceService,
  settingSuccess,
  clearSetting
} = marketplace.actions;

export default marketplace.reducer;
const errorHandler = (err) => (dispatch: AppDispatch) => {
  dispatch(
    openPopup({
      popupMessage: err?.response?.data?.message || err?.message,
      popupType: 'error'
    })
  );
};

export const getMarketPlace = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart(payload.dataType));
  dispatch(getTopCompanySuccess({ data: [], type: 'topProduct' }))
  dispatch(getTopCompanySuccess({ data: [], type: 'topEvent' }))
  dispatch(getTopCompanySuccess({ data: [], type: 'topCompany' }))
  const { dataType, pageSize, page } = payload
  const dataArr = dataType.split('-')
  const marketPlaceItemType = dataArr[1];
  const type = `${dataArr[0]}${dataArr[1]}`
  dispatch(clearTopCompany({ type }))
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlace({ dataType: marketPlaceItemType, pageSize, page });

    if (status === 200 || data.status) {
      const key = `${marketPlaceItemType?.toLocaleLowerCase()}Count`
      dispatch(getTopCompanySuccess({ data: data.data, type, count: data[key] || 0 }))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getTopCompanySuccess({ data: [], type }))
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getMarketPlaceCompaniesByCatService = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('topCompany'));
  dispatch(clearTopCompany({ type: 'topCompany' }))
  dispatch(clearTopCompany({ type: 'topProduct' }))
  dispatch(clearTopCompany({ type: 'topEvent' }))
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getCompanyViaCatService(payload);

    if (status === 200 || data.status) {
      dispatch(getTopCompanySuccess({ data: data.data, type: 'topCompany', count: data.companyCount || 0 }))
    }
  } catch (err: any) {
    dispatch(getTopCompanySuccess({ data: [], type: 'topCompany', count: 0 }))
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getMarketPlaceProduct = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(productLoadingStart());
  const { dataType, pageSize, page } = payload
  const dataArr = dataType.split('-')
  const marketPlaceItemType = dataArr[1];
  const type = `${dataArr[0]}${dataArr[1]}`
  dispatch(clearTopCompany({ type }))
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlace({ dataType: marketPlaceItemType, pageSize, page });

    if (status === 200 || data.status) {
      dispatch(getTopProductSuccess(data?.data))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getTopProductSuccess([]))
  } finally {
    dispatch(productLoadingEnd())
  }
}

export const getMarketPlaceEvent = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(eventLoadingStart());
  const { dataType, pageSize, page } = payload
  const dataArr = dataType.split('-')
  const marketPlaceItemType = dataArr[1];
  const type = `${dataArr[0]}${dataArr[1]}`
  dispatch(clearTopCompany({ type }))
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlace({ dataType: marketPlaceItemType, pageSize, page });

    if (status === 200 || data.status) {
      dispatch(getEventSuccess(data?.data))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getEventSuccess([]))
  } finally {
    dispatch(eventLoadingEnd())
  }
}

export const getMarketPlaceService = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(serviceLoadingStart());
  const { dataType, pageSize, page } = payload
  const dataArr = dataType.split('-')
  const marketPlaceItemType = dataArr[1];
  const type = `${dataArr[0]}${dataArr[1]}`
  dispatch(clearTopCompany({ type }))
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlace({ dataType: marketPlaceItemType, pageSize, page });

    if (status === 200 || data.status) {
      dispatch(getServiceSuccess(data?.data))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getServiceSuccess([]))
  } finally {
    dispatch(serviceLoadingEnd())
  }
}

export const getMarketPlaceSearch = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart(payload.dataType));
  const { dataType, pageSize, page, filterString } = payload
  const dataArr = dataType.split('-')
  const marketPlaceItemType = dataArr[1];
  const type = `${dataArr[0]}${dataArr[1]}`
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlaceItemsSearch({ dataType: marketPlaceItemType, pageSize, page, filterString });

    if (status === 200 || data.status) {
      dispatch(getTopCompanySuccess({ data: data.data, type }))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getTopCompanySuccess({ data: [], type }))
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const deleteItem = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('delete'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().deleteMarketPlaceItem(payload);

    return { status: true }
  } catch (err: any) {
    console.log(err.message);
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const deleteMarketPlaceService = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('delete'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().deleteService(payload);

    return { status: true, message: data?.message }
  } catch (err: any) {
    console.log(err.message);
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getCommonMarketPlaceItems = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart(payload.dataType));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getMarketPlaceItems(payload);

    if (status === 200 || data.status) {
      dispatch(getMarketplaceItems({ data: data.data, type: payload.dataType }))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getMarketplaceItems({ data: [], type: payload.dataType }))
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const createGlobalItem = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('create'));
  const { path, ...payloadData } = payload
  try {
    const apiService = new MarketPlaceApiServices();
    const { status, data }: APIResponse<any> = payloadData?.id
      ? await apiService.updateMarketPlaceItem(payloadData)
      : await apiService.createItem(payloadData);

    if (status === 200 || data.status) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success'
        })
      );
    }
    return { status: true }
  } catch (err: any) {
    dispatch(errorHandler(err));
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getMarketPlaceGlobalServices = () => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('service-loading'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getGlobalServices({});

    if (status === 200 || data.status) {
      dispatch(getGlobalServiceSuccess(data.data))
    }
  } catch (err: any) {
    console.log(err.message);
    dispatch(getGlobalServiceSuccess([]))
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const addServices = (payload) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('service-add'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().addService(payload);

    if (status === 200 || data.status) {
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success'
        })
      );
    }
    return { status: true }
  } catch (err: any) {
    dispatch(errorHandler(err));
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
}
export const updateServiceComment = (payload) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('update-comment'));
  const { companyId, ...payloadData } = payload
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().updateService(payloadData);

    if (status === 200 || data.status) {
      dispatch(getMarketPlaceServices({ companyId }))
      dispatch(
        openPopup({
          popupMessage: data.message,
          popupType: 'success'
        })
      );
    }
    return { status: true }
  } catch (err: any) {
    dispatch(errorHandler(err));
    return { status: false }
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const getMarketPlaceServices = (payload) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('getServices'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getServices(payload);

    if (status === 200 || data.status) {
      dispatch(getMarketPlaceServiceSuccess(data.data))
    } else {
      dispatch(clearMarketPlaceService())
    }
  } catch (err: any) {
    dispatch(errorHandler(err));
    dispatch(clearMarketPlaceService())
  } finally {
    dispatch(globalLoadingEnd())
  }
}

export const changeServiceOrder = (payload) => async (dispatch: AppDispatch) => {
  dispatch(globalLoadingStart('getServices'));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().maintainOrder(payload);

    if (status === 200 || data.status) {
      return { status: true }
    }
  } catch (err: any) {
    dispatch(errorHandler(err));
    dispatch(globalLoadingEnd())
    return { status: false }
  }
}

export const getPresignedURL = (payload) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().getPresignedUrl(payload);

    if (status === 200 || data.status) {
      return { status: true, data: data.data }
    }
  } catch (err: any) {
    dispatch(errorHandler(err));
    return { status: false }
  }
}

export const changeTabSetting = (payload) => async (dispatch: AppDispatch) => {
  const { key, ...payloadData } = payload
  dispatch(globalLoadingStart(key));
  try {
    const { status, data }: APIResponse<any> =
      await new MarketPlaceApiServices().changeSetting(payloadData);

    if (status === 200 || data.status) {
      dispatch(getCompany({ id: payload.userId }));
    }
  } catch (err: any) {
    dispatch(globalLoadingEnd())
    dispatch(errorHandler(err));
  }
}
