/* eslint-disable */

import { FC } from 'react';
import {
  // ActionIcon,
  TDropdown,
  ThreeDotsDropdown,
  Tooltip,
  Typography
} from 'src/components/common';
import { colors } from 'src/constants';
import { textEllipsis } from 'src/utils';
import { useAppSelector } from 'src/store/hooks';
import { useToggleCreateEditGroupModal } from 'src/components/chat';
import { useGetIdentity } from 'src/hooks';

import './ideaDiscussionGroupHeader.scss';
import { TIdeaDiscussionGroupHeaderProps } from './ideaDiscussionGroupHeader.types';
import useToggleOpenDiscussionMembersModal from './useToggleOpenDiscussionMembersModal';

import EditIdeaGroup from '../../editIdeaGroup/EditIdeaGroup';
import { TIdeaSharedWithMe } from 'src/components/ideaHub/ideaHub.types';

const IdeaDiscussionGroupHeader: FC<TIdeaDiscussionGroupHeaderProps> = ({
  discussionType,
  headerDropdownOptions,
  groupId,
  ideaId,
  isViewerOwner
}: TIdeaDiscussionGroupHeaderProps) => {
  // Hooks
  const ideaGroupParticipants: any = useAppSelector((store) => store.chat.ideaGroupParticipants);
  const ideasSharedWithMe: TIdeaSharedWithMe[] = useAppSelector(
    (store) => store.ideaHub.ideasSharedWithMe
  );

  const { toggleOpenOpenDiscussionMembersModal } = useToggleOpenDiscussionMembersModal();
  const { isToggleCreateEditGroupModalOpen, toggleCreateEditGroupModal } =
    useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { getIdentities } = useGetIdentity();

  // Constants
  const participants: any = ideaGroupParticipants?.[0]?.Users;

  const groupName = ideaGroupParticipants?.[0]?.groupName;
  const ownerName: any = ideasSharedWithMe?.find((item: TIdeaSharedWithMe) => item?.idea?.id === ideaGroupParticipants?.[0]?.ideaId)?.idea?.owner || ''
  const ownerNameWitEllipsis = textEllipsis({ text: ownerName?.name, charLimit: 12 });
  const { authenticatedId, loggedInUserType } = getIdentities();

  const dropdownOptionsProps: TDropdown = {
    id: `chat-messages-header-${groupId}`,
    size: 'large',
    alignment: 'center',
    items: headerDropdownOptions
  };

  const filterParticipant = participants?.filter((item: any) => item?.id !== ownerName?.id)?.map((item: any) => item?.name)
  let membersToRender: string[] = filterParticipant;
  const remainingMembers: number = filterParticipant?.length - 3;
  // try {
  //   if (participants?.length > 3) {
  //     for (let i = 1; i < 3; i++) {
  //       const member = filterParticipant?.[i];
  //       membersToRender.push(member?.name);
  //     }
  //   } else {
  //     membersToRender = filterParticipant?.slice(0)?.map((member: any) => member?.name);
  //   }
  // } catch { }
  const openMembersModal = () => {
    if (discussionType === 'programDiscussion') {
      toggleOpenOpenDiscussionMembersModal();
    }
  };

  return (
    <div className="discussion-messages-header">
      <div className="discussion-messages-header__left">
        <Typography
          as="p"
          variant="body-1"
          fontWeight="semiBold"
          style={{ color: colors?.neutral?.[1100] }}
          className="discussion-messages-header__left__heading"
        >
          {discussionType === 'programDiscussion' ? 'Program Discussion' : groupName}
        </Typography>

        <div className="discussion-messages-header__left__members">
          {ownerNameWitEllipsis?.text && <Tooltip
            key={'owner'}
            text={ownerName?.name}
            conditionToDisplay={ownerNameWitEllipsis?.hasEllipsis}
            limitWidth
          >
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              style={{ color: colors?.neutral?.[1100] }}
            >
              {ownerNameWitEllipsis?.text}&nbsp;(Owner),&nbsp;
            </Typography>
          </Tooltip>}
          {membersToRender &&
            membersToRender?.length > 0 &&
            membersToRender.map((member, index) => {
              const nameWitEllipsis = textEllipsis({ text: member, charLimit: 12 });
              return (
                <Tooltip
                  key={index}
                  text={member}
                  conditionToDisplay={nameWitEllipsis?.hasEllipsis}
                  limitWidth
                >
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    style={{ color: colors?.neutral?.[1100] }}
                  >
                    {nameWitEllipsis?.text},&nbsp;
                  </Typography>
                </Tooltip>
              );
            })}
          {remainingMembers > 0 && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              style={{ color: colors?.neutral?.[1100] }}
              className="discussion-messages-header__left__members__more-users"
              onClick={openMembersModal}
            >
              +{remainingMembers}&nbsp;more users
            </Typography>
          )}
        </div>
      </div>
      {isViewerOwner && (
        <div className="discussion-messages-header__right">
          <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />
        </div>
      )}

      {isToggleCreateEditGroupModalOpen && (
        
        <EditIdeaGroup
          mode="Edit"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          groupId={groupId}
          userId={authenticatedId}
          userType={loggedInUserType}
          groupMembers={ideaGroupParticipants?.[0]}
          ideaId={ideaId}
          groupName={groupName || ''}
        />
      )}
    </div>
  );
};

export default IdeaDiscussionGroupHeader;
