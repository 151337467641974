/* eslint-disable  multiline-ternary */
import { ReactNode, useContext, useMemo, useRef, useState, useEffect } from 'react';
import { FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setValiditedTrue, setValiditedFalse } from 'src/store/validation/validationSlice';
import { RootState } from 'src/store/store';
import { useNavigate } from 'react-router-dom';
import { Card, LeftMenu, Typography, Button } from 'src/components/common';
import { useAppDispatch } from 'src/store/hooks';
import { TCreateIdeaRequest, TEditIdeaRequest } from 'src/services/apiEndpoint.types.ts';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { createIdea, editIdea } from 'src/store/ideaHub/ideaHub.slice';
import { assignModalId } from 'src/store/common/common.slice';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import MarkdownIt from 'markdown-it';
import Htraction from 'src/assets/images/logo/htraction.png';
import Ideahub from 'src/assets/images/logo/ideahub.png';

// import { IdeaEvaluationDetails } from './ideaEvaluationDetails/IdeaEvaluationDetails';
import useAllowedActions from './ideaDetailsActions/useAllowedActions';
import './ideaDetails.scss';

import {
  TIdeaDetailsSections,
  TIdeaStatus,
  TIdeaCollaborationType,
  TIdeaViewType,
  TGetIdea,
  TEvaluatorResponse,
  TIdea,
  TIdeaCollaborator,
  TIdeaMarketDetailsData,
  TIdeaProblemsSolutionsData,
  TIdeaBusinessModelCompetitorsData,
  TIdeaValidationReport,
  TIdeaSharedFor
} from '../ideaHub.types';
import { IdeaViewTypeContext } from '../ideaSkeleton/IdeaSkeleton';

import {
  IdeaEvaluationDetails,
  Details,
  EditBusinessModelCompetitors,
  EditDetails,
  EditMarketDetails,
  EditProblemsSolutions,
  IdeaDetailsActions,
  IdeaDetailsSectionView,
  ViewRatings
} from '.';

export type TIdeaDetailsProps = {
  ideaStatus: TIdeaStatus;
  ideaCollaborationType: TIdeaCollaborationType;
  ideaData: TGetIdea;
  ratings: TEvaluatorResponse[];
  ideaSharedFor?: TIdeaSharedFor;
  ideaRatedByUser?: boolean;
};

export type TEditIdeaFormValues = {
  title?: string;
  domain?: string;
  // description?: string;
  // marketSize?: string;
  targetAudience?: string;
  // marketTrends?: string;
  identifiedProblem?: string;
  solutionEffectiveness?: string;
  uniqueValueProposition?: string;
  majorRevenueStream?: string;
  // scaliblityOfIdea?: string;
  competitors?: string;
  // keyDifferentiatingFactor?: string;
};

const IdeaDetails = ({
  ideaStatus,
  ideaCollaborationType,
  ideaData,
  ratings,
  ideaSharedFor,
  ideaRatedByUser
}: TIdeaDetailsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();

  const { loggedInUserId } = getIdentities();
  const editIdeaType: 'newIdea' | 'draft' = paramValue({ paramName: 'editType' }) as
    | 'newIdea'
    | 'draft';
  const editIdeaId: string = paramValue({ paramName: 'id' }) as unknown as string;

  const { idea, collaborators }: { idea: TIdea; collaborators: TIdeaCollaborator[] } = ideaData;

  const {
    title,
    domain,
    // description,
    owner,
    // marketSize,
    targetAudience,
    // marketTrends,
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition,
    majorRevenueStream,
    // scaliblityOfIdea,
    competitors,
    validationReport
    // keyDifferentiatingFactor
  } = idea;

  const [currentIdeaDetailsIndex, setCurrentIdeaDetailsIndex] = useState<number>(0);
  const [editIdeaFormValues, setEditIdeaFormValues] = useState<TEditIdeaFormValues>({
    title,
    domain,
    // description,
    // marketSize,
    targetAudience,
    // marketTrends,
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition,
    majorRevenueStream,
    // scaliblityOfIdea,
    competitors
    // keyDifferentiatingFactor
  });
  const submitButtonRef = useRef<FormikProps<TEditIdeaFormValues>>(null);

  const ideaSteps: TIdeaDetailsSections[] = [
    'details',
    'ideaEvaluation',
    'ideaRating'
  ];

  const marketDetails: TIdeaMarketDetailsData = {
    // marketSize,
    targetAudience
    // marketTrends
  };
  const ideaProblemsSolutions: TIdeaProblemsSolutionsData = {
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition
  };
  const ideaBusinessModelCompetitors: TIdeaBusinessModelCompetitorsData = {
    majorRevenueStream,
    // scaliblityOfIdea,
    competitors,
    targetAudience
    // keyDifferentiatingFactor
  };
  const ideaValidationReport: TIdeaValidationReport = {
    validationReport
  };

  const ideaViewContext: TIdeaViewType = useContext(IdeaViewTypeContext);

  const updateFormValues = ({ values }: { values: TEditIdeaFormValues }) => {
    setEditIdeaFormValues((prev) => ({ ...prev, ...values }));
  };

  const goToNextTab = ({ fromEditDetails }: { fromEditDetails: boolean }) => {
    setCurrentIdeaDetailsIndex((prev) => {
      if (prev === 0 && !fromEditDetails) {
        handleNext();
        return prev;
      } else if (ideaSteps[prev + 1]) {
        return prev + 1;
      }
      return prev;
    });
  };

  const handleNext = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.handleSubmit();
    }
  };

  const { checkButtonsVisibility } = useAllowedActions({
    ideaStatus,
    ideaCollaborationType,
    currentIdeaDetailsSection: ideaSteps[currentIdeaDetailsIndex],
    ideaSharedFor,
    viewType: ideaViewContext
  });

  const {
    showEditButton,
    showFinalizeButton,
    showShareManageAccessButtons,
    showNextButton,
    showDiscardIdeaButton,
    showSaveButton,
    showRateIdeaButton
  } = checkButtonsVisibility();

  // This button is present in Idea hub -> Idea -> Idea details -> Edit details
  const { showShareManageAccessButtonsEditDetails } = checkButtonsVisibility();

  const handleDiscard = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.handleReset();
    }
    setEditIdeaFormValues({
      title: '',
      domain: '',
      // description: '',
      // marketSize: '',
      targetAudience: '',
      // marketTrends: '',
      identifiedProblem: '',
      solutionEffectiveness: '',
      uniqueValueProposition: '',
      majorRevenueStream: '',
      // scaliblityOfIdea: '',
      competitors: ''
      // keyDifferentiatingFactor: ''
    });
    if (editIdeaType === 'newIdea') {
      navigate('/view-empty-idea?tab=ideaDetails');
      dispatch(assignModalId(''));
    } else {
      const payload: TEditIdeaRequest = {
        ideaId: editIdeaId,
        status: 'Discard',
        title: 'Title that best reflects your idea',
        domain: 'Select a specific domain your idea relates to',
        // description: 'Elaborate on your idea with details.',
        // marketSize: 'Describe size of the market for your idea.',
        targetAudience: 'Describe the specific demographic or group your idea.',
        // marketTrends: 'Outline current and emerging trends relevant to your idea',
        identifiedProblem: 'Clearly state and elaborate on the problem your idea aims to address',
        solutionEffectiveness:
          'Explain how effective your proposed solution is in addressing the identified problem.',
        uniqueValueProposition: 'Describe the distinctive value your idea offers',
        majorRevenueStream:
          'Specify the primary source through which your business generates revenue',
        // scaliblityOfIdea: 'Describe the potential for your idea to grow and expand',
        competitors: 'List and analyze the key competitors in your idea.',
        // keyDifferentiatingFactor:
        // 'Highlight the unique feature or aspect that sets your idea apart from competitors.',
        discardIdea: true,
        userId: loggedInUserId
      };

      dispatch(editIdea(payload)).then(() => {
        navigate(`/view-idea?id=${editIdeaId}&tab=ideaDetails`);
      });
    }
  };

  const handleSave = () => {
    const {
      title,
      domain,
      // description,
      // marketSize,
      targetAudience,
      // marketTrends,
      identifiedProblem,
      solutionEffectiveness,
      uniqueValueProposition,
      majorRevenueStream,
      // scaliblityOfIdea,
      competitors
      // keyDifferentiatingFactor
    } = editIdeaFormValues;

    if (editIdeaType === 'newIdea') {
      const payload: TCreateIdeaRequest = {
        ownerId: loggedInUserId,
        title: title || 'Title that best reflects your idea',
        domain: domain || 'Select a specific domain your idea relates to',
        // description: description || 'Elaborate on your idea with details.',
        // marketSize: marketSize || 'Describe size of the market for your idea.',
        targetAudience: targetAudience || 'Describe the specific demographic or group your idea.',
        // marketTrends: marketTrends || 'Outline current and emerging trends relevant to your idea',
        identifiedProblem:
          identifiedProblem ||
          'Clearly state and elaborate on the problem your idea aims to address',
        solutionEffectiveness:
          solutionEffectiveness ||
          'Explain how effective your proposed solution is in addressing the identified problem.',
        uniqueValueProposition:
          uniqueValueProposition || 'Describe the distinctive value your idea offers',
        majorRevenueStream:
          majorRevenueStream ||
          'Specify the primary source through which your business generates revenue',
        // scaliblityOfIdea:
        // scaliblityOfIdea || 'Describe the potential for your idea to grow and expand',
        competitors: competitors || 'List and analyze the key competitors in your idea.'
        // keyDifferentiatingFactor:
        //   keyDifferentiatingFactor ||
        //   'Highlight the unique feature or aspect that sets your idea apart from competitors.'
      };

      dispatch(createIdea(payload));
    } else if (editIdeaId && editIdeaType === 'draft') {
      const payload: TEditIdeaRequest = {
        ideaId: editIdeaId,
        status: ideaStatus,
        userId: loggedInUserId,
        title: title || 'Title that best reflects your idea',
        domain: domain || 'Select a specific domain your idea relates to',
        // description: description || 'Elaborate on your idea with details.',
        ...(domain && { domain }),
        // ...(description && { description }),
        // ...(marketSize && { marketSize }),
        ...(targetAudience && { targetAudience }),
        // ...(marketTrends && { marketTrends }),
        ...(identifiedProblem && { identifiedProblem }),
        ...(solutionEffectiveness && { solutionEffectiveness }),
        ...(uniqueValueProposition && { uniqueValueProposition }),
        ...(majorRevenueStream && { majorRevenueStream }),
        // ...(scaliblityOfIdea && { scaliblityOfIdea }),
        ...(competitors && { competitors })
        // ...(keyDifferentiatingFactor && { keyDifferentiatingFactor })
      };

      dispatch(editIdea(payload));
    }
  };
  // const openFinalizeIdeaModal = () => {
  //   dispatch(assignModalId(confirmFinalizeIdeaModalId));
  // };
  const confirmFinalizeIdeaModalId = 'confirmFinalizeIdeaModal';
  const confirmValidateIdeaModalId = 'confirmValidateIdeaModal';
  const openFinalizeIdeaModal = () => {
    dispatch(assignModalId(confirmFinalizeIdeaModalId));
  };
  const openValidateIdeaModal = () => {
    dispatch(assignModalId(confirmValidateIdeaModalId));
  };
  //   const exportReportToPDF = (report) => {
  //     console.log("report", report, idea);
  //     console.log("check", MarkdownIt); // Should not be undefined

  //     const mdParser = new MarkdownIt();
  //     const doc = new JsPDF();

  //     // Set the background color to orange
  //     doc.setFillColor(255, 165, 0); // RGB color for orange
  //     doc.rect(0, 0, 210, 297, 'F'); // Fill the entire A4 page (210mm x 297mm)

  //     // Parse Markdown to HTML
  //     const markdownContent = `${report.validationReport}`;

  //     const htmlContent = mdParser.render(markdownContent);

  //     // Create a hidden div to render HTML for conversion
  //     const hiddenDiv = document.createElement('div');
  //     hiddenDiv.style.width = '210mm'; // A4 size width
  //     hiddenDiv.style.padding = '10px';
  //     hiddenDiv.innerHTML = htmlContent;
  //     document.body.appendChild(hiddenDiv);

  //     // Use html2canvas to render the content into the PDF
  //     html2canvas(hiddenDiv, { scale: 2 }).then((canvas) => {
  //         const imgData = canvas.toDataURL('image/png');
  //         const imgWidth = 190; // Adjust to fit A4 width
  //         const pageHeight = 297; // A4 height in mm
  //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //         let heightLeft = imgHeight;

  //         let position = 0;

  //         // Add image data to the PDF page by page
  //         while (heightLeft > 0) {
  //             // Adjust position to the new page if content overflows
  //             doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
  //             heightLeft -= pageHeight;
  //             position = -pageHeight; // Set position for the next page

  //             if (heightLeft > 0) {
  //                 doc.addPage(); // Add a new page only if there is more content
  //                 // Set background color for the new page
  //                 doc.setFillColor(255, 165, 0); // Same orange background color
  //                 doc.rect(0, 0, 210, 297, 'F'); // Fill the new page with background
  //             }
  //         }

  //         // Cleanup
  //         document.body.removeChild(hiddenDiv);

  //         // Save PDF
  //         const fileName = `Report.pdf`;
  //         doc.save(fileName);
  //     });
  // };

  const exportReportToPDF = (report) => {
    console.log("report", report);
    const mdParser = new MarkdownIt();
    const doc = new JsPDF();

    const headerHeight = 15; // Space for the header
    const footerHeight = 18; // Space for the footer
    const headerLineHeight = 1; // Height of the orange line in the header
    const usablePageHeight = 297 - headerHeight - footerHeight - 10; // A4 height minus header and footer
    const pageWidth = 210; // A4 width in mm
    const footerText = "www.htraction.com"; // Footer text

    // Parse Markdown to HTML
    const markdownContent = `${report.validationReport}`;
    const markdownContent2 = `### Idea details
**Title:** ${idea.title} 

**Domain:** ${idea.domain} 

**Identified Problem:** ${idea.identifiedProblem} 

**Solution Effectiveness:** ${idea.solutionEffectiveness} 

**Unique Value Proposition:** ${idea.uniqueValueProposition}  

**Major Revenue Stream:** ${idea.majorRevenueStream}  

**Competitors:** ${idea.competitors}

**Target Audience:** ${idea.targetAudience}

---
`;

    const htmlcontent2 = mdParser.render(markdownContent2);
    const htmlcontent3 = mdParser.render(markdownContent);

    const htmlContent = htmlcontent2 + htmlcontent3;

    // Create a hidden div to render HTML for conversion
    const hiddenDiv = document.createElement("div");
    hiddenDiv.style.width = "210mm"; // A4 size width
    hiddenDiv.style.padding = "10px";
    hiddenDiv.innerHTML = htmlContent;
    document.body.appendChild(hiddenDiv);

    // Use html2canvas to render the content into the PDF
    html2canvas(hiddenDiv, { scale: 2 }).then((canvas) => {
      const imgWidth = 190; // Adjust to fit A4 width
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // const pageCanvasHeight = (usablePageHeight * canvas.height) / imgHeight;
      const pageCanvasHeight = (usablePageHeight * canvas.height) / imgHeight;
      const imgData = canvas.toDataURL("image/png");
      let heightLeft = canvas.height;
      let canvasOffset = 0;

      // Page 1: Custom layout with margin before header
      doc.setFillColor(255, 255, 255); // Light gray background
      doc.rect(0, 0, pageWidth, 297, "F"); // Fill the entire page

      // Add Images
      const image1URL = Htraction; // Replace with your first image path
      const image2URL = Ideahub; // Replace with your second image path

      const image1X = 30;
      const image1Y = 80;
      const image1Width = 70;
      const image1Height = 20;

      const image2X = 110;
      const image2Y = 80;
      const image2Width = 70;
      const image2Height = 20;

      doc.addImage(image1URL, "PNG", image1X, image1Y, image1Width, image1Height);
      doc.addImage(image2URL, "PNG", image2X, image2Y, image2Width, image2Height);

      // Add margin space before the header line for page 1
      const marginBeforeHeader = 18; // Adjust margin as needed

      // Add header to page 1 with margin
      doc.setFillColor(250, 70, 22); // Set the fill color to #fa4616
      doc.rect(0, marginBeforeHeader, pageWidth, headerLineHeight, "F"); // Header line with margin

      // Add title (Custom Report Title) after the images
      doc.setFont("helvetica", "bold"); // Set font to bold
      doc.setFontSize(30);
      doc.setFillColor(250, 70, 22); // Orange color for the title
      doc.setTextColor(250, 70, 22); // Set the fill color to #fa4616
      doc.text("Idea Validation Report", pageWidth / 2, 140, { align: "center" }); // Adjusted vertical position (140)

      // Add space between title and subtitle
      const subtitleY = 115; // Adjust Y-coordinate to add space below the title

      // Add subtitle or additional information
      doc.setTextColor(0, 0, 0); // Black text
      doc.setFontSize(12);
      doc.text(
        `Idea Title - ${idea.title}`,
        pageWidth / 2,
        170,
        { align: "center" }
      );

      // Add footer to page 1
      doc.setFillColor(250, 70, 22); // Orange background for footer
      doc.rect(0, 297 - footerHeight, pageWidth, footerHeight, "F");
      doc.setFontSize(10);
      doc.setTextColor(255, 255, 255); // White text
      // doc.text(footerText, pageWidth / 2, 297 - footerHeight / 2 + 2, {
      //   align: "center"
      // });
      doc.textWithLink("www.htraction.com", pageWidth / 2, 297 - footerHeight / 2 + 2, {
        align: "center",
        url: "https://www.htraction.com" // Add the hyperlink
      });

      // Add a new page for Markdown content
      doc.addPage();

      // Markdown Content: From second page onwards
      while (heightLeft > 0) {
        // Add header
        doc.setFillColor(250, 70, 22); // Orange background for header line
        doc.rect(0, headerHeight - headerLineHeight, pageWidth, headerLineHeight, "F"); // Header line

        // Add footer
        doc.setFillColor(250, 70, 22); // Set the fill color to #fa4616
        doc.rect(0, 297 - footerHeight, pageWidth, footerHeight, "F");
        doc.setFontSize(10);
        doc.setTextColor(255, 255, 255); // White text
        doc.text(footerText, pageWidth / 2, 297 - footerHeight / 2 + 2, {
          align: "center"
        });

        // Add content
        const croppedCanvas = document.createElement("canvas");
        croppedCanvas.width = canvas.width;
        croppedCanvas.height = pageCanvasHeight;
        const croppedCtx = croppedCanvas.getContext("2d");
        croppedCtx.drawImage(
          canvas,
          0,
          canvasOffset,
          canvas.width,
          pageCanvasHeight,
          0,
          0,
          canvas.width,
          pageCanvasHeight
        );

        const croppedImgData = croppedCanvas.toDataURL("image/png");

        // Adjust the image position to make sure it starts from below the header
        doc.addImage(
          croppedImgData,
          "PNG",
          10,
          headerHeight + 5, // Start content below the header
          imgWidth,
          usablePageHeight // Adjust height to not overlap the footer
        );

        heightLeft -= pageCanvasHeight;
        canvasOffset += pageCanvasHeight;

        // Add new page if there’s more content
        if (heightLeft > 0) doc.addPage();
      }

      // Cleanup
      document.body.removeChild(hiddenDiv);

      // Save the PDF
      const fileName = `Report.pdf`;
      doc.save(fileName);
    });
  };

  const ideaDetailsSections: { key: TIdeaDetailsSections; label: string; component: ReactNode }[] =
    [
      {
        key: 'details',
        label: 'Details',
        component:
          ideaViewContext === 'editIdea' ? (
            <div>
              <EditDetails
                title={editIdeaFormValues?.title}
                domain={editIdeaFormValues?.domain}
                // description={editIdeaFormValues?.description}
                owner={idea?.owner}
                collaborators={collaborators}
                submitButtonRef={submitButtonRef}
                updateFormValues={updateFormValues}
                handleNext={handleNext}
                goToNextTab={goToNextTab}
                showShareManageAccessButtonsEditDetails={showShareManageAccessButtonsEditDetails}
              />
              {/* <EditMarketDetails
              // marketSize={editIdeaFormValues.marketSize}
              targetAudience={editIdeaFormValues.targetAudience}
              // marketTrends={editIdeaFormValues.marketTrends}
              submitButtonRef={submitButtonRef}
              updateFormValues={updateFormValues}
              handleNext={handleNext}
              goToNextTab={goToNextTab}
            /> */}
              <EditProblemsSolutions
                identifiedProblem={editIdeaFormValues.identifiedProblem}
                solutionEffectiveness={editIdeaFormValues.solutionEffectiveness}
                uniqueValueProposition={editIdeaFormValues.uniqueValueProposition}
                submitButtonRef={submitButtonRef}
                updateFormValues={updateFormValues}
                handleNext={handleNext}
                goToNextTab={goToNextTab}
              />
              <EditBusinessModelCompetitors
                majorRevenueStream={editIdeaFormValues.majorRevenueStream}
                // scaliblityOfIdea={editIdeaFormValues.scaliblityOfIdea}
                competitors={editIdeaFormValues.competitors}
                // keyDifferentiatingFactor={editIdeaFormValues.keyDifferentiatingFactor}
                submitButtonRef={submitButtonRef}
                updateFormValues={updateFormValues}
              />
            </div>
          ) : (
            <div>
              <div className='scrollable-container'>
              <IdeaEvaluationDetails
                title={title}
                domain={domain}
                // description={description}
                owner={owner}
                ideaSharedFor={ideaSharedFor}
                collaborators={collaborators}
              />
              {/* <IdeaDetailsSectionView sectionTitle="Market Details" data={marketDetails} /> */}
              <IdeaDetailsSectionView
                sectionTitle="Problem & Solutions"
                data={ideaProblemsSolutions}
              />
              <IdeaDetailsSectionView
                sectionTitle="Business Model & Competitors"
                data={ideaBusinessModelCompetitors}
              />
              </div>
              {showFinalizeButton && (<Button
                variant="primary"
                size="small"
                className="idea-details-actions__actions__finalize-idea"
                onClick={openFinalizeIdeaModal}
              >
                Finalize this idea
              </Button>)}
            </div>
          )
      }
      // {
      //   key: 'marketDetails',
      //   label: 'Market Details',
      //   component:
      //     ideaViewContext === 'editIdea' ? (
      //       <EditMarketDetails
      //         // marketSize={editIdeaFormValues.marketSize}
      //         targetAudience={editIdeaFormValues.targetAudience}
      //         // marketTrends={editIdeaFormValues.marketTrends}
      //         submitButtonRef={submitButtonRef}
      //         updateFormValues={updateFormValues}
      //         handleNext={handleNext}
      //         goToNextTab={goToNextTab}
      //       />
      //     ) : (
      //       <IdeaDetailsSectionView sectionTitle="Market Details" data={marketDetails} />
      //     )
      // },
      // {
      //   key: 'problemsSolutions',
      //   label: 'Problem & Solutions',
      //   component:
      //     ideaViewContext === 'editIdea' ? (
      //       <EditProblemsSolutions
      //         identifiedProblem={editIdeaFormValues.identifiedProblem}
      //         solutionEffectiveness={editIdeaFormValues.solutionEffectiveness}
      //         uniqueValueProposition={editIdeaFormValues.uniqueValueProposition}
      //         submitButtonRef={submitButtonRef}
      //         updateFormValues={updateFormValues}
      //         handleNext={handleNext}
      //         goToNextTab={goToNextTab}
      //       />
      //     ) : (
      //       <IdeaDetailsSectionView
      //         sectionTitle="Problem & Solutions"
      //         data={ideaProblemsSolutions}
      //       />
      //     )
      // },
      // {
      //   key: 'BusinessModelCompetitors',
      //   label: 'Business Model & Competitors',
      //   component:
      //     ideaViewContext === 'editIdea' ? (
      //       <EditBusinessModelCompetitors
      //         majorRevenueStream={editIdeaFormValues.majorRevenueStream}
      //         // scaliblityOfIdea={editIdeaFormValues.scaliblityOfIdea}
      //         competitors={editIdeaFormValues.competitors}
      //         // keyDifferentiatingFactor={editIdeaFormValues.keyDifferentiatingFactor}
      //         submitButtonRef={submitButtonRef}
      //         updateFormValues={updateFormValues}
      //       />
      //     ) : (
      //       <IdeaDetailsSectionView
      //         sectionTitle="Business Model & Competitors"
      //         data={ideaBusinessModelCompetitors}
      //       />
      //     )
      // }
    ];
    if (ideaViewContext !== 'editIdea') {
      if (ideaSharedFor !== 'Evaluation') {
        ideaDetailsSections.push({
          key: 'ideaEvaluation',
          label: 'Idea Evaluation',
          component: <div className="view-ideaEvaluation">
                          <IdeaDetailsSectionView
                          sectionTitle="Validation Report"
                          data={ideaValidationReport}
                        /> 
                        <Button
                              variant="primary"
                              size="small"
                              className="idea-details-actions__actions__finalize-idea"
                              onClick={() => exportReportToPDF(ideaValidationReport)}
                            >
                            Export to pdf
                          </Button>
                      </div>
        });
        ideaSteps.push('ideaEvaluation');
      }
      ideaDetailsSections.push({
        key: 'ideaRating',
        label: 'Idea Rating',
        component: <ViewRatings ratings={ratings} />
      });
      ideaSteps.push('ideaRating');
    }
  const currentIdeaDetailsComponent: ReactNode = useMemo(() => {
    try {
      const section = ideaDetailsSections[currentIdeaDetailsIndex];

      return section?.component;
    } catch {
      return <>-</>;
    }
  }, [currentIdeaDetailsIndex, JSON.stringify(ideaData), JSON.stringify(ratings)]);
  const isValidated = useSelector((state: RootState) => state.validation.isValidated);
  useEffect(() => {
    if (isValidated && ideaViewContext !== 'editIdea') {
      // Find the index of the 'ideaEvaluation' section
      const ideaEvaluationIndex = ideaDetailsSections.findIndex(
        section => section.key === 'ideaEvaluation'
      );

      // If the section exists, set it as the current section
      if (ideaEvaluationIndex !== -1) {
        setCurrentIdeaDetailsIndex(ideaEvaluationIndex);
      }
    }
  }, [isValidated, ideaViewContext]);
  useEffect(() => {
    // Check if the current section is 'ideaEvaluation'
    const currentSection = ideaSteps[currentIdeaDetailsIndex];

    if (currentSection === 'ideaEvaluation') {
      // Dispatch action to set validation to true when on Idea Evaluation page
      dispatch(setValiditedFalse());
    } else {
      // Dispatch action to set validation to false when on other pages
      dispatch(setValiditedFalse());
    }
  }, [currentIdeaDetailsIndex, dispatch, ideaSteps]);

  // Existing effect for automatic navigation when validated

  return (
    <div className="idea-details">
      <div className="idea-details__actions">
        <IdeaDetailsActions
          idea={idea}
          ideaStatus={ideaStatus}
          ideaCollaborationType={ideaCollaborationType}
          currentIdeaDetailsSection={ideaSteps[currentIdeaDetailsIndex]}
          ideaRatedByUser={ideaRatedByUser}
          submitButtonRef={submitButtonRef}
          ideaSharedFor={ideaSharedFor}
          handleNext={handleNext}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
        />
      </div>
      <div className="idea-details__sub-sections">
        <Card className="idea-details__sub-sections__menu">
          {ideaDetailsSections.map(({ label, key }, index) => {
            const isActive: boolean = ideaSteps[currentIdeaDetailsIndex] === key;
            return (
              <LeftMenu
                key={key}
                state={isActive ? 'active' : 'default'}
                onClick={() => {
                  console.log('index', index);
                  setCurrentIdeaDetailsIndex(index);
                }}
                className="idea-details__sub-sections__menu__item"
              >
                <Typography as="p" variant="body-2" fontWeight="medium">
                  {label}
                </Typography>
              </LeftMenu>
            );
          })}
        </Card>
        <div className="idea-details__sub-sections__component">{currentIdeaDetailsComponent}</div>
      </div>
    </div>
  );
};

export default IdeaDetails;
