/* eslint-disable multiline-ternary */
import Header from 'src/components/header/Header';
import { BackNavigation, InfiniteScroll, Loader, Typography } from 'src/components/common';
import cx from './allMarketplaceproduct.module.scss';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from 'src/components/common/common.icons';
import { InputField } from 'src/components/common/formFields';
import { filterIcon, sortIcon } from 'src/assets/images';
import { useUrlParamValue } from 'src/hooks';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { clearTopCompany, getMarketPlace, getMarketPlaceCompaniesByCatService, getMarketPlaceSearch } from 'src/store/marketplace/marketplace.slice';
import EventCard from 'src/components/MarketPlace/EventCard/EventCard';

const AllMarketPlaceProduct = () => {
    // hooks
    const navigate = useNavigate();
    const { paramValue } = useUrlParamValue();
    const dataType: string = paramValue({ paramName: 'dataType' }) as string;
    const id: string = paramValue({ paramName: 'id' }) as string;
    const title: string = paramValue({ paramName: 'title' }) as string;
    console.log(title, 'title');
    
    const dispatch = useAppDispatch()
    const { topCompany, topProduct, topEvent, globalLoading, counter } = useAppSelector((state) => state.marketPlace);

    // state 
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortBy, setSortBy] = useState<boolean>(false);

    // constant
    const typeArr = dataType?.split('-') || []
    const postFix = dataType ? typeArr?.[1] || '' : '';
    const pageSize = 12;
    const maxPage = counter || 0
    const totalPages = Math.ceil(maxPage / 12);
    const pageArr = Array.from({ length: totalPages }, (_, i: number) => i + 1)

    const business = handleFilter(postFix, topCompany)
    const product = dataType !== "Service Category" ? handleFilter(postFix, topProduct) : []
    const event = dataType !== "Service Category" ? handleFilter(postFix, topEvent) : []

    // handler
    function handleGoBack() {
        navigate(-1)
    }
    function handleStep(type?: string, value?: number) {
        let nextPage = 0
        if (type === 'next') {
            setPage((prev: number) => prev + 1);
            nextPage = page + 1
            if (nextPage > maxPage) {
                return
            }
        };
        if (type === 'prev') {
            setPage((prev: number) => prev - 1)
            nextPage = page - 1;
            if (nextPage < 1) {
                return
            }
        } else {
            setPage(value);
            nextPage = value
        }
        if (dataType === 'Service' || dataType === 'Service Category') {
            dispatch(getMarketPlaceCompaniesByCatService({ pageSize, page: nextPage, id, filterString: dataType }))
        } else {
            dispatch(getMarketPlace({ dataType, pageSize, page: nextPage }))
        }
    }

    function handleSearch() {
        if (searchTerm !== '') {
            const type = `top${dataType.split('-')[1]}`
            dispatch(clearTopCompany({ type }))
            dispatch(getMarketPlaceSearch({ dataType, pageSize: totalPages || 100000, page: 1, filterString: searchTerm }))
        }
    }
    function handleQuery(e) {
        const value = e.target.value
        setSearchTerm(value)
        if (!value) {
            dispatch(getMarketPlace({ dataType, pageSize, page: 1 }))
        }
    }

    function handleFilter(type: string, arr: any) {
        const array = JSON.parse(JSON.stringify(arr))
        if (type === 'Company') {
            return array?.sort((a: any, b: any) => {
                if (sortBy) {
                    return a?.name?.localeCompare(b?.name);
                }
                return 0;
            });
        }
        if (type === 'Product' || type === 'Event') {
            return array?.sort((a: any, b: any) => {
                if (sortBy) {
                    return a?.title?.localeCompare(b?.title);
                }
                return 0;
            });
        }

        return array
    }

    // effect
    useEffect(() => {
        if (dataType) {
            console.log(dataType, 'dataType');
            if (dataType === 'Service' || dataType === 'Service Category') {
                const type = dataType.replaceAll("+", " ");

                dispatch(getMarketPlaceCompaniesByCatService({ pageSize, page, id, filterString: dataType }))
            } else {
                dispatch(getMarketPlace({ dataType, pageSize, page }))
            }
        }
        return () => {
            if (dataType === 'Service' || dataType === 'Service Category') {
                dispatch(clearTopCompany({ type: 'topCompany' }))
            } else {
                dispatch(clearTopCompany({ type: dataType }))
            }
        }
    }, [dispatch, dataType])

    return (
        <InfiniteScroll
            className="notifications"
            moreItemsLeft={false}
            onScrollEnd={() => { }}
        >
            <Header />
            <div className="notifications__body">
                <div>
                    <header className={`${cx.topbar} `}>
                        <BackNavigation text='Back' onClick={handleGoBack} />
                        <Typography
                            variant="subHeading-2"
                            fontWeight="semiBold"
                            className="notifications__body__heading"
                        >
                            {postFix ? `All ${postFix}` : `Category : ${title}`}
                        </Typography>
                        {dataType !== 'Service' && dataType !== 'Service Category' && <div className={`${cx.searchfilter} `}>
                            <Row className='w-100'>
                                <Col md={9}>
                                    <InputField
                                        id="filterString"
                                        style={{ borderRadius: '100px' }}
                                        endIcon={<span className={`${cx.actionIcon} `}><SearchIcon /></span>}
                                        placeholder="Search"
                                        onChange={handleQuery}
                                        value={searchTerm}
                                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                            if (e.key === 'Enter') {
                                                handleSearch();
                                            }
                                        }}
                                        endIconClickHandler={handleSearch}
                                    />
                                </Col>
                                <Col md={3}>
                                    <div className={`${cx.filters}`}>
                                        <button className={`${sortBy ? cx.active : ''}`} onClick={() => setSortBy(!sortBy)}><span>Sort by</span> <img src={sortIcon} alt="sort-icon" /> </button>
                                        {/* <button><span>Filter</span> <img src={filterIcon} alt="filter-con" /></button> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>}
                    </header>
                    <section className={`${cx.content} `}>
                        {globalLoading && <Loader />}
                        <Row>
                            {business?.map((business, b: number) => {
                                return (
                                    <Col md={3} key={b}>
                                        <CompanyCard clickable name={business.name} id={business?.id} description={business.introduction} logo={business.logo} />
                                    </Col>
                                )
                            })}
                        </Row>

                        <Row>
                            {product?.map((product, p: number) => {
                                return (
                                    <Col md={3} key={p}>
                                        <CompanyCard dataType="Product" id={product.id} name={product.title} logo={product.thumbnailUrl} description={product?.description} />
                                        {/* // <ProductCard key={p} name={product.title} logo={product.thumbnailUrl} description={product?.description} /> */}
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row>
                            {event?.map((event, e: number) => {
                                return (
                                    <Col md={3} key={e}>
                                        <EventCard
                                            isAction={true}
                                            id={event.id}
                                            organizer={event.organizerName || ''}
                                            logo={event.thumbnailUrl || ''}
                                            name={event.title}
                                            url={event.mediaUrl}
                                            description={event.description}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    </section>
                    <footer className={cx.footer}>
                        {maxPage > 1 && <button className={`${cx.actionButton} ${page === 1 ? cx.disabled : ''} `} disabled={page === 1} onClick={() => handleStep('prev')}>{"<"} Previous</button>}
                        <div className={`${cx.pages} `}>
                            {pageArr.map((number: any) => (<span key={number} className={`${cx.page} ${page === number ? cx.active : ''} `} onClick={() => handleStep("", number)}>{number}</span>))}
                        </div>
                        {maxPage > 1 && <button className={`${cx.actionButton} ${page === pageArr?.[pageArr?.length - 1] ? cx.disabled : ''}`} disabled={page === pageArr?.[pageArr?.length - 1]} onClick={() => handleStep('next')}>Next {">"}</button>}
                    </footer>
                </div>
            </div>
        </InfiniteScroll >
    );
};

export default AllMarketPlaceProduct;
