import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IValidationState {
    isValidated: boolean;
}

const initialState: IValidationState = {
    isValidated: false
};

const validationSlice = createSlice({
    name: 'validation',
    initialState,
    reducers: {
        setValiditedTrue: (state) => {
            state.isValidated = true;
        },
        setValiditedFalse: (state) => {
            state.isValidated = false;
        }
    }
});

export const { setValiditedTrue, setValiditedFalse } = validationSlice.actions;

export default validationSlice.reducer;
