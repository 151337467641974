import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Loader, Modal, Typography } from 'src/components/common';
import './assignServiceModal.scss'
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Select from 'react-select';
import Button from 'src/components/button/Button';
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { addServices, changeCreationModalState, createGlobalItem, getCommonMarketPlaceItems, getMarketPlaceGlobalServices, getMarketPlaceServices } from 'src/store/marketplace/marketplace.slice';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';

export type TFormValues = {
    serviceIds: { lable: string, value: string }[]
};

const initialValues: TFormValues = {
    serviceIds: []
};

const AssignServiceModal = () => {
    const { serviceProviderCommonModal, globalLoading, globalServices, marketPlaceServices } = useAppSelector((state) => state.marketPlace)
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    // states
    const [initialServices, setInitialServices] = useState(initialValues)
    // Hooks
    const dispatch = useAppDispatch();
    const closeModal = () => {
        dispatch(changeCreationModalState({ state: false, dataType: '', id: '', services: [], catId: '' }))
        setInitialServices(initialValues)
    };

    function handleSubmit(values) {
        const payload = {
            companyId: myCompany?.id,
            serviceIds: values.serviceIds?.map((service) => service.value)
        }
        dispatch(addServices(payload)).then((response) => {
            if (response.status) {
                const payload = {
                    companyId: myCompany?.id
                }
                dispatch(getMarketPlaceServices(payload))
                closeModal()
            }
        })
    }
    const allServices = useMemo(() => {
        const allServices = globalServices.filter((serviceCat) => !serviceProviderCommonModal?.catId || serviceCat?.id === serviceProviderCommonModal?.catId)
        return allServices.flatMap((cat) => cat.Services?.map((service) => ({ label: service?.title, value: service?.id })))
    }, [globalServices, serviceProviderCommonModal])
    const modifiedServices = allServices?.filter((service) => !marketPlaceServices?.some((item) => item?.id === service?.value))
    useEffect(() => {
        dispatch(getMarketPlaceGlobalServices())
    }, []);

    useEffect(() => {
        if (allServices?.length > 0 && serviceProviderCommonModal?.id) {
            setInitialServices({ serviceIds: serviceProviderCommonModal.services })
        }
    }, [])

    return (
        <Modal
            isModalOpen={serviceProviderCommonModal.state}
            onModalClose={closeModal}
            title={`${serviceProviderCommonModal?.id ? "Update" : "Add"} ${serviceProviderCommonModal.dataType}`}
            className="add__service-modal"
        >
            {globalLoading !== 'service-loading' && <div className="add__Service">
                {/* Form starts */}
                <Formik
                    initialValues={initialServices}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ setFieldValue, values, errors, touched }) => {
                        return (
                            <Form className="add__Service__form" noValidate>
                                <div className=''>
                                    <Row>
                                        <Col md={12} className='mb-3'>
                                            <Select
                                                isMulti
                                                options={modifiedServices}
                                                value={values.serviceIds}
                                                onChange={(data) => setFieldValue('serviceIds', data)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="add__Service__form__buttons">
                                    <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                                        Cancel
                                    </Button>

                                    <Button variant="primary" type="submit" size="small" disabled={globalLoading === 'create' || values.serviceIds.length === 0}>
                                        {globalLoading === 'service-add' ? <Loader /> : "Submit"}
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik >
            </div >}
            {globalLoading === 'service-loading' && <Loader />}
        </Modal >
    );
};

export default AssignServiceModal;
