import React, { useState } from 'react'
import cx from './CompanyCard.module.scss';
import { playerIcon, test } from 'src/assets/images';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { textEllipsis } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changeCreationModalState, deleteItem, getCommonMarketPlaceItems } from 'src/store/marketplace/marketplace.slice';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { openPopup } from 'src/store/common/common.slice';
import { ConfirmModal } from 'src/components/common';
import CommonMarketplaceModal from '../CommonMarketplaceModal/CommonMarketplaceModal';
import { useNavigate } from 'react-router-dom';
interface TCompanyCardProps {
    id?: string,
    type?: string,
    name?: string,
    description?: string,
    logo?: string | null,
    dataType?: string,
    url?: string | null,
    style?: any;
    imageStyle?: any;
    clickable?: boolean;
    isViewerProgramCreator?: boolean
}
export default function CompanyCard({ id, dataType, type, name = '', clickable = false, description = '', logo = '', url, isViewerProgramCreator = true, style, imageStyle }: TCompanyCardProps) {
    // hooks
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { serviceProviderCommonModal } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);

    // state
    const [openConfirmation, setOpenConfirmationModal] = useState<boolean>(false);
    const [isPlay, setIsPlay] = useState<boolean>(false);

    // derived state 
    const descriptionObj = textEllipsis({ charLimit: 60, text: description });
    const nameObj = textEllipsis({ charLimit: 18, text: name });
    const modifiedUrl = url
        ? url?.includes('autoplay=1')
            ? url
            : `${url}${url?.includes('?') ? '&' : '?'}autoplay=1`
        : '';

    // handler 
    async function handleDelete() {
        if (id) {
            const response = await dispatch(deleteItem({ id }))
            console.log(response, 'responseData');
            if (response.status) {
                dispatch(
                    openPopup({
                        popupMessage: `${dataType} deleted successfully`,
                        popupType: 'success'
                    })
                );
                const payload = {
                    dataType,
                    companyId: myCompany?.id
                }
                dispatch(getCommonMarketPlaceItems(payload))
            } else {
                dispatch(
                    openPopup({
                        popupMessage: `${dataType} is not deleted`,
                        popupType: 'error'
                    })
                );
            }
        }
    }
    const handleCommonItemUpdate = () => {
        dispatch(changeCreationModalState({ state: true, dataType, id }))
    }
    function handlePlay() {
        setIsPlay(!isPlay)
    }
    function handleNavigate() {
        navigate(`/view-company?id=${id}`)
    }

    return (
        <>
            {serviceProviderCommonModal.state && <CommonMarketplaceModal />}
            {openConfirmation && <ConfirmModal
                openConfirmModal={openConfirmation}
                title={`Are you sure you want to remove this ${dataType}?`}
                description=""
                onClose={() => {
                    setOpenConfirmationModal(false);
                }}
                onSubmit={handleDelete}
                submitText="Confirm"
                type="error"
            />}
            <div className='item' onClick={() => clickable && handleNavigate()}>
                <div className={`${cx.outer_box} ${clickable ? cx.clickable : ''}`} style={style || {}}>
                    {type === 'Product' && isViewerProgramCreator && !isPlay && <div className={`${cx.actions}`}>
                        <EditIcon className={cx.icons} onClick={handleCommonItemUpdate} />
                        <TrashIcon className={cx.icons} onClick={() => setOpenConfirmationModal(true)} />
                    </div>}
                    <div className={`${cx.playerBox}`} style={imageStyle || {}}>
                        {!isPlay && (
                            <img
                                src={logo || test}
                                alt="meeting-img"
                            />
                        )}
                        {isPlay && (
                            <iframe
                                className={`${cx.viewFrame}`}
                                src={modifiedUrl}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                allowFullScreen
                            ></iframe>
                        )}
                        {url && (
                            <img
                                src={playerIcon}
                                className={`${cx.playerIcon}`}
                                alt="player-icon"
                                onClick={handlePlay}
                            />
                        )}
                    </div>
                    <div className={`${cx.marketplace_heading}`}>
                        <h4 title={name}>{nameObj.text}</h4>
                        <div className={`${cx.marketplace_content}`}>
                            <p title={description}>{descriptionObj?.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
