import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import cx from './hoverTab.module.scss';
import { TTabMenuItem } from '../tabs/Tabs';
import { useNavigate } from 'react-router-dom';

export default function HoverTabItem({ text, index, item, isLast = false, catId }: { text: any, index: number, item: TTabMenuItem[], isLast?: boolean, catId?: string }) {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const handleMouseEnter = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({ top: rect.bottom, left: rect.left });
        }
        setShow(true);
    };

    const handleMouseLeave = () => setShow(false);
    function handleNavigate(id: string) {
        navigate(`/all?dataType=Service+Category&id=${catId}&title=${text?.replaceAll('&', ' ')}`)
    }
    // const style = isLast 
    // ? { position: 'absolute', top: `${dropdownPosition.top}px`, right: `${dropdownPosition.left}px` }
    //  : { position: 'absolute', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }
    return (
        <li style={{ position: 'relative' }}>
            <div ref={buttonRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Dropdown.Toggle id="dropdown-basic">
                    {text}
                </Dropdown.Toggle>
            </div>

            {show && item?.length > 0 && ReactDOM.createPortal(
                <div
                    className={cx.dropdownMenu}
                    style={{ position: 'absolute', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px` }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Dropdown.Menu show>
                        {item.map((menuItem: TTabMenuItem, i: number) => {
                            return (
                                <Dropdown.Item href="#" key={i} onClick={() => handleNavigate(menuItem.id)}>{menuItem.text}</Dropdown.Item>

                            )
                        })}
                    </Dropdown.Menu>
                </div>,
                document.body // Renders the dropdown at the body level
            )}
        </li>
    );
}
