import { useState } from 'react';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { Modal, SubMenuModal, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import Button from 'src/components/button/Button';
import { SearchIcon } from 'src/components/common/common.icons';
import { useToggleOpenDropdown } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TProgramMember } from 'src/services/apiEndpoint.types.ts';
import { openPopup } from 'src/store/common/common.slice';
import useGetDiscussionGroupUsers from 'src/components/chat/manageIdeaGroupMembers/useGetDiscussionGroupUsers.hook';
import { TCreateEditGroupProps, TCreateGroupInitialValues } from './createEditGroup.types';
import createEditGroupValidationSchema from './createEditGroup.validationSchema';
import './createEditGroup.scss';

import SearchMemberItem from '../searchMemberItem/SearchMembersItem';
import UserInfo from '../userInfo/UserInfo';

const createEditIdeaGroupSearchUsersId = 'createEditIdeaGroupSearchUsers';

const CreateEditGroup = ({ isModalOpen, onModalClose, mode, onSubmit }: TCreateEditGroupProps) => {
  // Hooks
  const toggleSearchUsersDropdown = useToggleOpenDropdown();
  const { dropdownId, loading } = useAppSelector((store) => store.common);
  const program = useAppSelector((store) => store.program);
  const dispatch = useAppDispatch();

  // State
  const [filterString, setFilterString] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const { searchUsersToAddIdeaGroupList, searchUsersToAddIdeaGroupMoreItemsLeft } = useAppSelector(
    (store) => store.chat
  );
  console.log(selectedMembers, 'selectedMembers');

  console.log(searchUsersToAddIdeaGroupList, 'searchUsersToAddIdeaGroupList');
  // Constants
  const openSearchUsersResultDropdownId = dropdownId === createEditIdeaGroupSearchUsersId;
  // let programMembers: TProgramMember[] = program?.programMembers;

  try {
    // if (mode === 'Add') {
    //   programMembers = program?.programMembers?.filter((item) => {
    //     if (item?.User?.name.toUpperCase().includes(filterString.toUpperCase())) {
    //       return true;
    //     }
    //     if (item?.User?.name.toUpperCase().includes(filterString.toUpperCase())) {
    //       return true;
    //     }
    //     return false;
    //   });
    // }
  } catch {}
  const toggleOpenSearchUsersDropdown = () => {
    toggleSearchUsersDropdown({ dropdownId: createEditIdeaGroupSearchUsersId });
  };

  const initialValues: TCreateGroupInitialValues = {
    text: '',
    groupName: ''
  };

  const handleSubmit = (values: TCreateGroupInitialValues) => {
    const usersToAdd: any[] = selectedMembers.map((item) => item);

    if (usersToAdd?.length < 1) {
      dispatch(
        openPopup({ popupMessage: 'Please add atleast one user to the group', popupType: 'error' })
      );
    } else {
      onSubmit({ groupName: values?.groupName, usersToAdd });
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedMembers((prev) => {
      const currentList: any[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex((member: any) => member.id === userId);

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectedUser = searchUsersToAddIdeaGroupList.find(
          (member: any) => member.id === userId
        );
        if (selectedUser) {
          currentList.push(selectedUser);
        }
      }

      return currentList;
    });
  };

  const handleClose = () => {
    setFilterString('');
    setSelectedMembers([]);
    onModalClose();
  };
  useGetDiscussionGroupUsers({ filterString });
  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleClose}
      title={`${mode} Group`}
      className="pd-create-edit-group"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={createEditGroupValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form className="pd-create-edit-group__form">
              <InputField
                label="Enter Group Name"
                id="groupName"
                placeholder="Group Name"
                startIcon={<SearchIcon />}
                variant={componentSizeVariantsEnum.SMALL}
              />
              <div className="pd-create-edit-group__form__search-users">
                <InputField
                  label="Select and search users to add in group"
                  id="text"
                  placeholder="Search user by name, email address"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    const value = e.target.value;

                    setFieldValue('text', value);
                    setFilterString(value);
                  }}
                  startIcon={<SearchIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOpenSearchUsersDropdown();
                  }}
                />
                {openSearchUsersResultDropdownId && (
                  <SubMenuModal>
                    <div
                      className="pd-create-edit-group__form__search-users__container"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography as="p" variant="caption" fontWeight="semiBold">
                        Search for “{filterString}”
                      </Typography>
                      <div className="pd-create-edit-group__form__search-users__container__result">
                        {searchUsersToAddIdeaGroupList?.length > 0 &&
                          searchUsersToAddIdeaGroupList.map((searchUser: any, index) => {
                            const { id, name, role, profilePic } = searchUser;
                            const selectIndex = selectedMembers.findIndex(
                              (user) => user?.id === id
                            );
                            return (
                              <SearchMemberItem
                                key={index}
                                userId={id}
                                profilePic={profilePic}
                                name={name}
                                role={role}
                                updateSelectedUsers={updateSelectedUsers}
                                isUserSelected={selectIndex > -1}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </SubMenuModal>
                )}
              </div>

              {mode === 'Edit' && (
                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="regular"
                  style={{ color: colors?.neutral?.[1100] }}
                >
                  You can manage the group member adding or removing the user.
                </Typography>
              )}
              <div
                className={classNames(
                  'pd-create-edit-group__form__users',
                  `pd-create-edit-group__form__users--${mode}`
                )}
              >
                {/* profilePic?: string;
  name?: string;
  role?: string;
  email?: string;
  id: string; */}
                {selectedMembers.map((member, index) => {
                  const user = {
                    id: member?.id,
                    name: member?.name,
                    email: member?.email,
                    role: member?.role,
                    profilePic: member?.profilePic
                  };
                  return (
                    <UserInfo
                      key={index}
                      {...user}
                      showRemoveButton
                      handleRemoveUser={() => updateSelectedUsers({ userId: member?.id })}
                    />
                  );
                })}
              </div>
              <div className="pd-create-edit-group__form__buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleClose}
                  className="pd-create-edit-group__form__buttons__close"
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  className="pd-create-edit-group__form__buttons__create-group"
                  loading={loading}
                >
                  {mode === 'Add' ? 'Create Group' : 'Save Changes'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateEditGroup;
