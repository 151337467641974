import { Card, Separator, SubMenuModal, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';

import './ideaPeople.scss';

import PersonCard from '../personCard/PersonCard';
import { TIdeaCollaborator } from '../../ideaHub.types';

export type TIdeaPeopleProps = {
  owner: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
  };
  collaborators: TIdeaCollaborator[];
};

const showMorePeopleInDropdownId = 'showMorePeopleInDropdown';

const IdeaPeople = ({ owner, collaborators }: TIdeaPeopleProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const dropdownId = useAppSelector((store) => store.common.dropdownId);

  const handleShowMorePeopleInDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(showMorePeopleInDropdownId));
    }
  };

  const showMorePeople = dropdownId === showMorePeopleInDropdownId;

  const totalPeople = collaborators?.length;

  const peopleToRender: TIdeaCollaborator[] =
    totalPeople > 2 ? collaborators.slice(0, 2) : collaborators;

  const peopleToRenderInDropdown: TIdeaCollaborator[] =
    totalPeople > 2 ? collaborators.slice(2) : [];
    // console.log(collaborators);
  return (
    <div className="idea-people">
      <PersonCard person={{ ...owner, isOwner: true }} showEmail={false} />
      {collaborators?.length > 0 && (
        <>
          <Separator />{' '}
          {peopleToRender.map((person, id) => {
            return <PersonCard key={id} person={{ ...person }} showEmail={false} />;
          })}
        </>
      )}
      {peopleToRenderInDropdown?.length > 0 && (
        <div
          className="idea-people__more-people"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="idea-people__more-people__count" onClick={handleShowMorePeopleInDropdown}>
            <Typography as="p" variant="body-1" fontWeight="semiBold">
              +{peopleToRenderInDropdown?.length}
            </Typography>
          </div>
          <SubMenuModal>
            {showMorePeople && (
              <Card className="idea-people__more-people__dropdown">
                {peopleToRenderInDropdown.map((person, id) => {
                  return <PersonCard key={id} person={{ ...person }} showEmail={false} />;
                })}
              </Card>
            )}
          </SubMenuModal>
        </div>
      )}
    </div>
  );
};

export default IdeaPeople;
