import axios from 'axios';

import {
  TCreateIdeaGroupRequest,
  TCreateProgramGroupRequest,
  TDeleteIdeaGroupRequest,
  TDeleteProgramGroupRequest,
  TCreateGroupRequest,
  TCreateGroupResponse,
  TCreateGroupActionRequest,
  TGetGroupsRequest, 
  TGetGroupsResponse,
  TDislikeConversationMessageRequest,
  TDislikeIdeaConversationMessageRequest,
  TDownloadChatFileRequest,
  TEditIdeaGroupRequest,
  TEditProgramGroupRequest,
  TFindCreateConversationRequest,
  TGetChatFilePresignedUrlRequest,
  TGetIdeaConversationMessagesRequest,
  TGetIdeaConversationParticipantsRequest,
  TGetIdeaGroupParticipantsRequest,
  TGetIdeaGroupsRequest,
  TGetConversationParticipantsRequest,
  TGetConversationParticipants,
  TGetConversationParticipantsResponse,
  TGetConversationParticipantsResponseData,
  TGetOneToOneConversationMessagesRequest,
  TGetProgramConversationMessagesRequest,
  TGetProgramConversationParticipantsRequest,
  TGetProgramGroupParticipantsRequest,
  TGetProgramGroupsRequest,
  TLikeConversationMessageRequest,
  TLikeIdeaConversationMessageRequest,
  TListOneToOneChatsRequest,
  TSearchUsersToAddIdeaGroupRequest,
  TSearchUsersToAddProgramGroupRequest,
  TUploadChatFileToS3Request
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';
import { TListIdeaChatsRequest } from './apiEndpoint.types.ts/chat/listIdeaChats.api.endpoint.types.js';

export class ChatAPIService extends BaseAPIService {
  private listOneToOneChatsPath: string = '/list-chats';
  private listIdeaChatsPath: string = '/list-idea-chats';
  private findCreateConversationPath: string = '/find-or-create-conversation';
  private getOneToOneConversationMessagesPath: string = '/get-conversation-messages';
  private createIdeaGroupPath: string = '/create-idea-group';
  private createGroupPath: string = '/create-group';
  private getIdeaGroupsPath: string = '/get-idea-groups';
  private getGroupsPath: string = '/get-groups';
  private editIdeaGroupPath: string = '/edit-idea-group';
  private deleteIdeaGroupPath: string = '/delete-idea-group';
  private getIdeaGroupParticipantsPath: string = '/get-idea-group-participants';
  private getIdeaConversationParticipantsPath: string = '/get-idea-conversation-participants';
  private getConversationParticipantsPath: string = '/get-conversation-participants';
  private getIdeaConversationMessagesPath: string = '/get-idea-conversation-messages';
  private searchUsersToAddIdeaGroupPath: string = '/search-users-to-add-idea-group';
  private createProgramGroupPath: string = '/create-program-group';
  private getProgramGroupsPath: string = '/get-program-groups';
  private editProgramGroupPath: string = '/edit-program-group';
  private deleteProgramGroupPath: string = '/delete-program-group';
  private getProgramGroupParticipantsPath: string = '/get-program-conversation-participants';
  private getProgramConversationMessagesPath: string = '/get-program-conversation-messages';
  private searchUsersToAddProgramGroupPath: string = '/search-users-to-add-program-group';
  private getProgramConversationParticipantsPath: string = '/get-program-conversation-participants';
  private likeConversationMessagePath: string = '/like-conversation-message';
  private dislikeConversationMessagePath: string = '/dislike-conversation-message';
  private likeIdeaConversationMessagePath: string = '/like-idea-conversation-message';
  private dislikeIdeaConversationMessagePath: string = '/dislike-idea-conversation-message';
  private uploadChatFilePath: string = '/get-presigned-url-upload';
  private downloadChatFilePath: string = '/get-presigned-url-download';

  constructor() {
    super();
    this.axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_CHAT_ENDPOINT });
    this.configureInterceptors();
  }

  public async listOneToOneChats<TListOneToOneChatsResponse>(
    listOneToOneChatsRequest: TListOneToOneChatsRequest
  ): Promise<APIResponse<TListOneToOneChatsResponse>> {
    const listOneToOneChatsResponse: APIResponse<TListOneToOneChatsResponse> = await this.get(
      this.listOneToOneChatsPath,
      true,
      listOneToOneChatsRequest,
      true
    );
    return listOneToOneChatsResponse;
  }

  public async listIdeaChats<TListIdeaChatsResponse>(
    listIdeaChatsRequest: TListIdeaChatsRequest
  ): Promise<APIResponse<TListIdeaChatsResponse>> {
    const listIdeaChatsResponse: APIResponse<TListIdeaChatsResponse> = await this.get(
      this.listIdeaChatsPath,
      true,
      listIdeaChatsRequest,
      true
    );
    return listIdeaChatsResponse;
  }

  public async findCreateConversation<TFindCreateConversationResponse>(
    findCreateConversationRequest: TFindCreateConversationRequest
  ): Promise<APIResponse<TFindCreateConversationResponse>> {
    const findCreateConversationResponse: APIResponse<TFindCreateConversationResponse> =
      await this.post(this.findCreateConversationPath, findCreateConversationRequest, true, true);
    return findCreateConversationResponse;
  };

  public async getGroups<TGetGroupsResponse>(
    getGroupsRequest: TGetGroupsRequest
  ): Promise<APIResponse<TGetGroupsResponse>> {
    const getGroupsResponse: APIResponse<TGetGroupsResponse> = await this.get(
      this.getGroupsPath,
      true,
      getGroupsRequest,
      true
    );
    return getGroupsResponse;
  };

  public async getOneToOneConversationMessages<TGetOneToOneConversationMessagesResponse>(
    getOneToOneConversationMessagesRequest: TGetOneToOneConversationMessagesRequest
  ): Promise<APIResponse<TGetOneToOneConversationMessagesResponse>> {
    const getOneToOneConversationMessagesResponse: APIResponse<TGetOneToOneConversationMessagesResponse> =
      await this.get(
        this.getOneToOneConversationMessagesPath,
        true,
        getOneToOneConversationMessagesRequest,
        true
      );
    return getOneToOneConversationMessagesResponse;
  }

  public async createIdeaGroup<TCreateIdeaGroupResponse>(
    createIdeaGroupRequest: TCreateIdeaGroupRequest
  ): Promise<APIResponse<TCreateIdeaGroupResponse>> {
    const createIdeaGroupResponse: APIResponse<TCreateIdeaGroupResponse> = await this.post(
      this.createIdeaGroupPath,
      createIdeaGroupRequest,
      true,
      true
    );
    return createIdeaGroupResponse;
  }

  public async createGroup<TCreateGroupResponse>(
    createGroupRequest: TCreateGroupRequest
  ): Promise<APIResponse<TCreateGroupResponse>> {
    const createIdeaGroupResponse: APIResponse<TCreateGroupResponse> = await this.post(
      this.createGroupPath,
      createGroupRequest,
      true,
      true
    );
    return createIdeaGroupResponse;
  }

  public async createProgramGroup<TCreateProgramGroupResponse>(
    createProgramGroupRequest: TCreateProgramGroupRequest
  ): Promise<APIResponse<TCreateProgramGroupResponse>> {
    const createProgramGroupResponse: APIResponse<TCreateProgramGroupResponse> = await this.post(
      this.createProgramGroupPath,
      createProgramGroupRequest,
      true,
      true
    );
    return createProgramGroupResponse;
  }

  public async deleteProgramGroup<TDeleteProgramGroupResponse>(
    deleteProgramGroupRequest: TDeleteProgramGroupRequest
  ): Promise<APIResponse<TDeleteProgramGroupResponse>> {
    const deleteProgramGroupResponse: APIResponse<TDeleteProgramGroupResponse> = await this.post(
      this.deleteProgramGroupPath,
      deleteProgramGroupRequest,
      true,
      true
    );
    return deleteProgramGroupResponse;
  }

  public async getIdeaGroups<TGetIdeaGroupsResponse>(
    getIdeaGroupsRequest: TGetIdeaGroupsRequest
  ): Promise<APIResponse<TGetIdeaGroupsResponse>> {
    const getIdeaGroupsResponse: APIResponse<TGetIdeaGroupsResponse> = await this.get(
      this.getIdeaGroupsPath,
      true,
      getIdeaGroupsRequest,
      true
    );
    return getIdeaGroupsResponse;
  }

  public async getProgramGroups<TGetProgramGroupsResponse>(
    getProgramGroupsRequest: TGetProgramGroupsRequest
  ): Promise<APIResponse<TGetProgramGroupsResponse>> {
    const getProgramGroupsResponse: APIResponse<TGetProgramGroupsResponse> = await this.get(
      this.getProgramGroupsPath,
      true,
      getProgramGroupsRequest,
      true
    );
    return getProgramGroupsResponse;
  }

  public async editIdeaGroup<TEditIdeaGroupResponse>(
    editIdeaGroupRequest: TEditIdeaGroupRequest
  ): Promise<APIResponse<TEditIdeaGroupResponse>> {
    const editIdeaGroupResponse: APIResponse<TEditIdeaGroupResponse> = await this.post(
      this.editIdeaGroupPath,
      editIdeaGroupRequest,
      true,
      true
    );
    return editIdeaGroupResponse;
  }

  public async deleteIdeaGroup<TDeleteIdeaGroupResponse>(
    deleteIdeaGroupRequest: TDeleteIdeaGroupRequest
  ): Promise<APIResponse<TDeleteIdeaGroupResponse>> {
    const deleteIdeaGroupResponse: APIResponse<TDeleteIdeaGroupResponse> = await this.post(
      this.deleteIdeaGroupPath,
      deleteIdeaGroupRequest,
      true,
      true
    );
    return deleteIdeaGroupResponse;
  }

  public async editProgramGroup<TEditProgramGroupResponse>(
    editProgramGroupRequest: TEditProgramGroupRequest
  ): Promise<APIResponse<TEditProgramGroupResponse>> {
    const editProgramGroupResponse: APIResponse<TEditProgramGroupResponse> = await this.post(
      this.editProgramGroupPath,
      editProgramGroupRequest,
      true,
      true
    );
    return editProgramGroupResponse;
  }

  public async getIdeaGroupParticipants<TGetIdeaGroupParticipantsResponse>(
    getIdeaGroupParticipantsRequest: TGetIdeaGroupParticipantsRequest
  ): Promise<APIResponse<TGetIdeaGroupParticipantsResponse>> {
    const getIdeaGroupParticipantsResponse: APIResponse<TGetIdeaGroupParticipantsResponse> =
      await this.get(
        this.getIdeaGroupParticipantsPath,
        true,
        getIdeaGroupParticipantsRequest,
        true
      );
    return getIdeaGroupParticipantsResponse;
  }

  public async getProgramGroupParticipants<TGetProgramGroupParticipantsResponse>(
    getProgramGroupParticipantsRequest: TGetProgramGroupParticipantsRequest
  ): Promise<APIResponse<TGetProgramGroupParticipantsResponse>> {
    const getProgramGroupParticipantsResponse: APIResponse<TGetProgramGroupParticipantsResponse> =
      await this.get(
        this.getProgramGroupParticipantsPath,
        true,
        getProgramGroupParticipantsRequest,
        true
      );
    return getProgramGroupParticipantsResponse;
  }

  public async getIdeaConversationMessages<TGetIdeaConversationMessagesResponse>(
    getIdeaConversationMessagesRequest: TGetIdeaConversationMessagesRequest
  ): Promise<APIResponse<TGetIdeaConversationMessagesResponse>> {
    const getIdeaConversationMessagesResponse: APIResponse<TGetIdeaConversationMessagesResponse> =
      await this.get(
        this.getIdeaConversationMessagesPath,
        true,
        getIdeaConversationMessagesRequest,
        true
      );
    return getIdeaConversationMessagesResponse;
  }

  public async getProgramConversationMessages<TGetProgramConversationMessagesResponse>(
    getProgramConversationMessagesRequest: TGetProgramConversationMessagesRequest
  ): Promise<APIResponse<TGetProgramConversationMessagesResponse>> {
    const getProgramConversationMessagesResponse: APIResponse<TGetProgramConversationMessagesResponse> =
      await this.get(
        this.getProgramConversationMessagesPath,
        true,
        getProgramConversationMessagesRequest,
        true
      );
    return getProgramConversationMessagesResponse;
  }

  public async searchUsersToAddIdeaGroup<TSearchUsersToAddIdeaGroupResponse>(
    searchUsersToAddIdeaGroupRequest: TSearchUsersToAddIdeaGroupRequest
  ): Promise<APIResponse<TSearchUsersToAddIdeaGroupResponse>> {
    const searchUsersToAddIdeaGroupResponse: APIResponse<TSearchUsersToAddIdeaGroupResponse> =
      await this.get(
        this.searchUsersToAddIdeaGroupPath,
        true,
        searchUsersToAddIdeaGroupRequest,
        true
      );
    return searchUsersToAddIdeaGroupResponse;
  }

  public async searchUsersToAddProgramGroup<TSearchUsersToAddProgramGroupResponse>(
    searchUsersToAddProgramGroupRequest: TSearchUsersToAddProgramGroupRequest
  ): Promise<APIResponse<TSearchUsersToAddProgramGroupResponse>> {
    const searchUsersToAddProgramGroupResponse: APIResponse<TSearchUsersToAddProgramGroupResponse> =
      await this.get(
        this.searchUsersToAddProgramGroupPath,
        true,
        searchUsersToAddProgramGroupRequest,
        true
      );
    return searchUsersToAddProgramGroupResponse;
  }

  public async getIdeaConversationParticipants<TGetIdeaConversationParticipantsResponse>(
    getIdeaConversationParticipantsRequest: TGetIdeaConversationParticipantsRequest
  ): Promise<APIResponse<TGetIdeaConversationParticipantsResponse>> {
    const getIdeaConversationParticipantsResponse: APIResponse<TGetIdeaConversationParticipantsResponse> =
      await this.get(
        this.getIdeaConversationParticipantsPath,
        true,
        getIdeaConversationParticipantsRequest,
        true
      );
    return getIdeaConversationParticipantsResponse;
  }

  public async getConversationParticipants<TGetConversationParticipantsResponse>(
    getConversationParticipantsRequest: TGetConversationParticipantsRequest
  ): Promise<APIResponse<TGetConversationParticipantsResponse>> {
    const getConversationParticipantsResponse: APIResponse<TGetConversationParticipantsResponse> =
      await this.get(
        this.getConversationParticipantsPath,
        true,
        getConversationParticipantsRequest,
        true
      );
    return getConversationParticipantsResponse;
  }

  public async getProgramConversationParticipants<TGetProgramConversationParticipantsResponse>(
    getProgramConversationParticipantsRequest: TGetProgramConversationParticipantsRequest
  ): Promise<APIResponse<TGetProgramConversationParticipantsResponse>> {
    const getProgramConversationParticipantsResponse: APIResponse<TGetProgramConversationParticipantsResponse> =
      await this.get(
        this.getProgramConversationParticipantsPath,
        true,
        getProgramConversationParticipantsRequest,
        true
      );
    return getProgramConversationParticipantsResponse;
  }

  public async likeConversationMessage<TLikeConversationMessageResponse>(
    likeConversationMessageRequest: TLikeConversationMessageRequest
  ): Promise<APIResponse<TLikeConversationMessageResponse>> {
    const likeConversationMessageResponse: APIResponse<TLikeConversationMessageResponse> =
      await this.post(this.likeConversationMessagePath, likeConversationMessageRequest, true, true);
    return likeConversationMessageResponse;
  }

  public async likeIdeaConversationMessage<TLikeIdeaConversationMessageResponse>(
    likeIdeaConversationMessageRequest: TLikeIdeaConversationMessageRequest
  ): Promise<APIResponse<TLikeIdeaConversationMessageResponse>> {
    const likeIdeaConversationMessageResponse: APIResponse<TLikeIdeaConversationMessageResponse> =
      await this.post(
        this.likeIdeaConversationMessagePath,
        likeIdeaConversationMessageRequest,
        true,
        true
      );
    return likeIdeaConversationMessageResponse;
  }

  public async dislikeConversationMessage<TDislikeConversationMessageResponse>(
    dislikeConversationMessageRequest: TDislikeConversationMessageRequest
  ): Promise<APIResponse<TDislikeConversationMessageResponse>> {
    const dislikeConversationMessageResponse: APIResponse<TDislikeConversationMessageResponse> =
      await this.post(
        this.dislikeConversationMessagePath,
        dislikeConversationMessageRequest,
        true,
        true
      );
    return dislikeConversationMessageResponse;
  }

  public async dislikeIdeaConversationMessage<TDislikeIdeaConversationMessageResponse>(
    dislikeIdeaConversationMessageRequest: TDislikeIdeaConversationMessageRequest
  ): Promise<APIResponse<TDislikeIdeaConversationMessageResponse>> {
    const dislikeIdeaConversationMessageResponse: APIResponse<TDislikeIdeaConversationMessageResponse> =
      await this.post(
        this.dislikeIdeaConversationMessagePath,
        dislikeIdeaConversationMessageRequest,
        true,
        true
      );
    return dislikeIdeaConversationMessageResponse;
  }

  public async uploadChatFile<TUploadChatFileToS3Response>(
    uploadChatFileRequest: TUploadChatFileToS3Request
  ): Promise<APIResponse<TUploadChatFileToS3Response>> {
    const { fileData, signedUrl } = uploadChatFileRequest;
    const uploadChatFileResponse: APIResponse<TUploadChatFileToS3Response> = await this.put(
      signedUrl,
      fileData,
      false
    );
    return uploadChatFileResponse;
  }

  public async getChatFilePresignedUrl<TGetChatFilePresignedUrlResponse>(
    getChatFilePresignedUrlRequest: TGetChatFilePresignedUrlRequest
  ): Promise<APIResponse<TGetChatFilePresignedUrlResponse>> {
    const getChatFilePresignedUrlResponse: APIResponse<TGetChatFilePresignedUrlResponse> =
      await this.get(this.uploadChatFilePath, true, getChatFilePresignedUrlRequest);
    return getChatFilePresignedUrlResponse;
  }

  public async downloadChatFile<TDownloadChatFileResponse>(
    downloadChatFileRequest: TDownloadChatFileRequest
  ): Promise<APIResponse<TDownloadChatFileResponse>> {
    const downloadChatFileResponse: APIResponse<TDownloadChatFileResponse> = await this.get(
      this.downloadChatFilePath,
      true,
      downloadChatFileRequest
    );
    return downloadChatFileResponse;
  }
}
