import Header from 'src/components/header/Header';
import { TUserTypes } from 'src/constants/user.constants';
import { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCalculateElementHeight,
  useGetIdentity,
  useToggleOpenModal,
  useUrlParamValue
} from 'src/hooks';
import { ConfirmModal, TDropdownOptions } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TDiscussionTypes } from 'src/components/chat/chat.type';
import { deleteProgramGroup, getGroups } from 'src/store/chat/chat.slice';
import { TDeleteProgramGroupActionRequest } from 'src/services/apiEndpoint.types.ts';
// import DiscussionList from './discussionList/DiscussionList';
// import { TDiscussionProps } from './discussion.types';
// import useGetProgramGroups from './discussionList/useGetProgramGroups.hook';
// import './discussion.scss';
// import useToggleOpenDiscussionMembersModal from '../../components/chat/discussion/chatMessages/chatMessagesHeader/useToggleOpenDiscussionMembersModal';
// eslint-disable-next-line import/no-duplicates
import {
  ChatList,
  ChatMessages,
  useToggleCreateEditGroupModal,
  useToggleManageGroupModalModal
} from '../../components/chat';
import ChatMessagesGroup from '../../components/chat/discussion/chatMessages/ChatMessages';
// import EditProgramGroup from './editProgramGroup/EditProgramGroup';

import './chat.scss';

const Chat = () => {
  // Hooks
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();

  const selectedUserId: string = paramValue({ paramName: 'userId' }) || '';
  const selectedUserType = paramValue({ paramName: 'userType' }) as unknown as TUserTypes;
  const { loggedInUserId, loggedInUserType, loggedInCompanyId, authenticatedId } = getIdentities();

  const showChatWindowOnMobile: boolean = Boolean(selectedUserId && selectedUserType);
  const navigate = useNavigate();
  const programDiscussionRef = useRef<HTMLDivElement>(null);
  // const { toggleOpenOpenDiscussionMembersModal } = useToggleOpenDiscussionMembersModal();
  // const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
  //   useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { toggleCreateEditGroupModal } = useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { toggleManageGroupModalModal } = useToggleManageGroupModalModal();
  const toggleOpenModal = useToggleOpenModal();
  // Get program groups
  const { Groups, GroupParticipants } = useAppSelector((store) => store.chat);
  const modalId = useAppSelector((store) => store.common.modalId);
  const dispatch = useAppDispatch();
  const [isViewerOwner, setIsViewerOwner] = useState<boolean>(
    GroupParticipants?.[0]?.ownerId === authenticatedId
  );

  const handleGetGroups = () => {
    const payload: any = {
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getGroups(payload));
  };

  useEffect(() => {
    handleGetGroups();
  }, []);
  const discussionType: TDiscussionTypes = paramValue({
    paramName: 'chatType'
  }) as unknown as TDiscussionTypes;
  const groupId: string = paramValue({ paramName: 'groupId' }) || '';
  console.log(
    GroupParticipants?.[0]?.ownerId === authenticatedId,
    GroupParticipants?.[0]?.ownerId,
    authenticatedId,
    'isViewerOwner'
  );
  // Constants

  const deleteProgramGroupConfirmModalId = `deleteProgramGroupConfirmModal-${groupId}`;
  const isDeleteGroupConfirmModalOpen = modalId === deleteProgramGroupConfirmModalId;

  const toggleOpenDeleteGroupModal = () => {
    toggleOpenModal({ modalId: deleteProgramGroupConfirmModalId });
  };

  const headerDropdownOptions: TDropdownOptions[] = [];

  // if (isViewerOwner) {
  //   headerDropdownOptions.push(
  //     // { label: 'Manage Members', onClick: toggleManageGroupModalModal },
  //     { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
  //     { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
  //   );
  // } else {
  //   headerDropdownOptions.push(
  //     // { label: 'Manage Members', onClick: toggleManageGroupModalModal },
  //     // { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
  //     { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
  //   );
  // }

  const { elementHeight } = useCalculateElementHeight({
    ref: chatBodyRef,
    triggers: [window.location.search],
    heightOffset: 64
  });

  return (
    <div className="chat-page">
      <Header />
      <div className="chat-page__body" ref={chatBodyRef} style={{ height: `${elementHeight}px` }}>
        <ChatList
          selectedUserId={selectedUserId}
          selectedUserType={selectedUserType}
          showChatList={!showChatWindowOnMobile}
          loggedInUserId={loggedInUserId}
          loggedInCompanyId={loggedInCompanyId}
          authenticatedId={authenticatedId}
          loggedInUserType={loggedInUserType}
        />
        {groupId ? (
          <ChatMessagesGroup
            discussionType={discussionType}
            groupId={groupId}
            // headerDropdownOptions={headerDropdownOptions}

            // programId={programId}
            // companyId={companyId}
          />
        ) : (
          <ChatMessages
            selectedUserId={selectedUserId}
            selectedUserType={selectedUserType}
            showChatWindowOnMobile={showChatWindowOnMobile}
            directMessageContext="directMessage"
          />
        )}
      </div>
    </div>
  );
};

export default Chat;
