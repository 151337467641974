import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Typography } from 'src/components/common';
import { textEllipsis } from 'src/utils';
import { TTextEllipsisResponse } from 'src/utils/textEllipsis/textEllipsis.types';

import { TDiscussionGroupItemProps } from './discussionGroupItem.types';
import './discussionGroupItem.scss';
import { TDiscussionTypes } from 'src/components/chat/chat.type';

const DiscussionGroupItem = ({
  group,
  groupId,
  discussionType,
  programId
}: TDiscussionGroupItemProps) => {
  // Hooks
  const navigate = useNavigate();

  const groupTitleEllipsis: TTextEllipsisResponse = textEllipsis({
    text: group?.groupName,
    charLimit: 20
  });

  const viewPGroupDiscussion = () => {
    const { pathname } = window.location;
    const chatType: TDiscussionTypes = 'programGroup';
    const path: string =
      pathname + `?id=${programId}&tab=discussion&chatType=${chatType}&groupId=${group?.id}`;

    navigate(path);
  };
  return (
    <Tooltip
      text={group?.groupName}
      conditionToDisplay={groupTitleEllipsis?.hasEllipsis}
      limitWidth
    >
      <Typography
        as="p"
        variant="body-2"
        fontWeight="semiBold"
        className={classNames('discussion-group-item', {
          'discussion-group-item--is-active':
            group?.id === groupId && discussionType === 'programGroup'
        })}
        onClick={viewPGroupDiscussion}
      >
        {groupTitleEllipsis?.text}
      </Typography>
    </Tooltip>
  );
};

export default DiscussionGroupItem;
