import React, { useEffect, useState } from 'react';
import cx from './serviceSection.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getMarketPlaceService, getServiceSuccess } from 'src/store/marketplace/marketplace.slice';
import { Loader } from 'src/components/common';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';

export default function ServiceSection({ gb }: any) {
    const dispatch = useAppDispatch();
    const { topService, serviceLoading } = useAppSelector((state) => state.marketPlace);
    const [active, setActive] = useState<number>(0);
    useEffect(() => {
        dispatch(getMarketPlaceService({ dataType: 'top-Service Text', pageSize: 11, page: 1 }))
        return () => {
            dispatch(getServiceSuccess([]))
        }
    }, [dispatch])
    if (serviceLoading) {
        return <Loader />
    }
    return (
        <div className={`${gb.section}`}>
            <div className={`${gb.headBox}`}>
                <h5 className={`${gb.sectionHeading}`}>Services</h5>
                {/* <span onClick={handleNavigate}>See All</span> */}
            </div>
            <section className={`${cx.serviceSection}`}>
                <div className="insideCard">
                    <Row>
                        <Col md={7} className='px-2'>
                            <div className={`${cx.listBox}`}>
                                <ol className={`${cx.serviceList}`}>
                                    {topService?.map((service, index) => {
                                        return (
                                            <li className={`${active === index ? cx.active : ''}`} key={service.id} onClick={() => setActive(index)}>{service?.title}</li>
                                        )
                                    })}
                                </ol>
                            </div>
                        </Col>
                        <Col md={5}>
                            <Row>
                                {topService?.[active]?.CompanyMappings?.map((company, c: number) => {
                                    const { name, logo } = company?.Company
                                    return (
                                        <Col md={6} className='px-2' key={c}>
                                            <CompanyCard
                                                style={{ minHeight: '195px', maxHeight: '195px' }}
                                                imageStyle={{ minHeight: '145px', maxHeight: '145px' }}
                                                name={name}
                                                clickable
                                                id={company?.companyId}
                                                logo={logo} />
                                        </Col>
                                    )
                                })}
                                {!serviceLoading && topService?.[active]?.CompanyMappings?.length === 0 && (
                                    <Col md={12} className='px-2'>
                                        <div className={`${cx.unknown}`}>
                                            <p>Currently, no businesses are <br /> associated with this service</p>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                {!serviceLoading && topService?.length === 0 && <p className={`${cx.globalUnknonwn}`}>No Service Found</p>}
                </div>
            </section>
        </div>
    )
}
