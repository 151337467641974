import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import cx from './topCompanies.module.scss'
import { optionsSlider1 } from "src/constants/OwlCarouselConstant";
import { test } from 'src/assets/images';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Loader } from 'src/components/common';
import { useEffect } from 'react';
import { clearTopCompany, getMarketPlace } from 'src/store/marketplace/marketplace.slice';
import { useNavigate } from 'react-router-dom';

function TopCompanies({ gb }: any) {
    const { topCompany, globalLoading } = useAppSelector((state) => state.marketPlace);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getMarketPlace({ dataType: 'top-Company', pageSize: 11, page: 1 }))

        return () => {
            dispatch(clearTopCompany({ type: "topCompany" }))
        }
    }, [dispatch])

    const handleNavigate = () => {
        navigate('/all?dataType=top-Company')
    }

    if (globalLoading === 'top-Company') {
        return <Loader />
    }

    return (
        <div className={`${gb.section}`}>
            <div className={`${gb.headBox}`}>
                <h5 className={`${gb.sectionHeading}`}>Top Companies</h5>
                {/* {<span onClick={handleNavigate}>See All</span>} */}
                {topCompany?.length > 10 && <span onClick={handleNavigate}>See All</span>}
            </div>
            <div className={`${cx.topCompanies}`}>
                {!globalLoading && topCompany?.length > 0 && <OwlCarousel className="owl-theme sliderRow" {...optionsSlider1}>
                    {topCompany?.map((business, b: number) => {
                        return (
                            <CompanyCard clickable name={business.name} id={business.id} description={business.introduction} logo={business.logo} key={b} />
                        )
                    })}
                </OwlCarousel>}
                {!globalLoading && topCompany?.length === 0 && <p className={`${cx.globalUnknonwn}`}>No Company Found</p>}
            </div>
        </div>
    )
}

export default TopCompanies
