import React from "react";

import st from "./toggleSwitch.module.scss";
interface GreenSwitchProps {
    checked?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    color?: string;
    backgroundColor?: string;
    id?: any;
    disabled?: boolean;
    className?: any;
}

const ToggleSwitch: React.FC<GreenSwitchProps> = ({
    color,
    backgroundColor,
    checked,
    onChange,
    id,
    disabled,
    className
}) => {
    return (
        <div
            className={st.greenSwitch}
            style={{ background: `${backgroundColor}` }}>
            <input
                type="checkbox"
                id={id}
                className={`${st.toggleInput} ${className}`}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <label className={st.toggleLabel} htmlFor={id}></label>
            <div className={st.toggleThumb} style={{ background: `${color}` }}></div>
        </div>
    );
};

export default ToggleSwitch;
