/* eslint-disable no-unused-vars */

export enum EmploymentType {
  FULL_TIME = 'Full time',
  PART_TIME = 'Part time',
  SELF_EMPLOYED = 'Self employed',
  OTHER = 'Other'
}

export enum Degree {
  BARCH = 'Bachelor of Architecture (BArch)',
  BA = 'Bachelor of Arts (BA, AB, BS, BSc, SB, ScB)',
  BAA = 'Bachelor of Applied Arts (BAA)',
  BAAS = 'Bachelor of Applied Arts and Science (BAAS)',
  BAPPSCIT = 'Bachelor of Applied Science in Information Technology (BAppSc(IT))',
  BDES = 'Bachelor of Design (BDes, or SDes in Indonesia)',
  BENG = 'Bachelor of Engineering (BEng, BE, BSE, BESc, BSEng, BASc, BTech, BSc(Eng), AMIE, GradIETE)',
  BSBBA = 'Bachelor of Science in Business (BSBA)',
  BSET = 'Bachelor of Engineering Technology (BSET)',
  BTECH = 'Bachelor of Technology (B.Tech. or B.Tech.)',
  BIBE = 'International Business Economics (BIBE)',
  BBA = 'Bachelor of Business Administration (BBA)',
  BMS = 'Bachelor of Management Studies (BMS)',
  BAS = 'Bachelor of Administrative Studies',
  BCOM = 'Bachelor of Commerce (BCom, or BComm)',
  BFA = 'Bachelor of Fine Arts (BFA)',
  BBUS = 'Bachelor of Business (BBus or BBus)',
  BMOS = 'Bachelor of Management and Organizational Studies (BMOS)',
  BBUSSC = 'Bachelor of Business Science (BBusSc)',
  BACC = 'Bachelor of Accountancy (B.Acy. or B.Acc. or B. Accty)',
  BCOMPT = 'Bachelor of Comptrolling (B.Acc.Sci. or B.Compt.)',
  BEC = 'Bachelor of Economics (BEc, BEconSc; sometimes BA(Econ) or BSc(Econ))',
  BAOM = 'Bachelor of Arts in Organizational Management (BAOM)',
  BCOMPSC = 'Bachelor of Computer Science (BCompSc)',
  BCOMP = 'Bachelor of Computing (BComp)'
}

export enum Role {
  ASPIRING_ENTREPRENEUR = 'Aspiring Entrepreneur',
  ENTREPRENEUR = 'Entrepreneur',
  MENTOR = 'Mentor',
  INVESTOR = 'Investor',
  SERVICE_PROVIDERS_STARTUP = 'Service providers/Startup',
  MENTOR_INVESTOR = 'Mentor and Investor'
}

export enum InviteMentorInvestorRoles {
  MENTOR = 'Mentor',
  INVESTOR = 'Investor',
  MENTOR_INVESTOR = 'Mentor and Investor'
}

export enum Expertise {
  BUSINESS_OPERATIONS = 'Business Operations',
  SUPPLY_CHAIN = 'Supply Chain',
  STRATEGY = 'Strategy',
  SALES_DISTRIBUTION = 'Sales & Distribution',
  DESIGN_THINKING = 'Design Thinking',
  ENTERPRISE_SALES = 'Enterprise Sales',
  FINANCIAL_MANAGEMENT = 'Financial Management',
  FUND_RAISING = 'Fund Raising',
  GO_TO_MARKET = 'Go-To-Market',
  LEGAL_COMPLIANCE = 'Legal / Compliance',
  MARKETING = 'Marketing',
  RESOURCE_MANAGEMENT = 'Resource Management',
  SAAS_STRATEGY = 'SaaS Strategy',
  LEADERSHIP = 'Leadership',
  TECHNOLOGY = 'Technology'
}

export enum Industries {
  ADDITIVE_MANUFACTURING = 'Additive Manufacturing (3D Printing)',
  ADVANCED_MATERIALS = 'Advanced Materials',
  AGRICULTURE = 'Agriculture',
  AGRITECH = 'Agriculture Technology (AgriTech)',
  AEROSPACE = 'Aerospace',
  ALTERNATIVE_PROTEINS = 'Alternative Proteins and Food Innovation',
  ARTIFICIAL_INTELLIGENCE = 'Artificial Intelligence (AI) and Machine Learning',
  AUGMENTED_AND_VIRTUAL_REALITY = 'Augmented Reality (AR) and Virtual Reality (VR)',
  AUGMENTED_REALITY_SHOPPING = 'Augmented Reality Shopping',
  AUDIO_AND_PODCASTING = 'Audio and Podcasting Platforms',
  AUTOMOTIVE = 'Automotive',
  BIG_DATA = 'Big Data',
  BIOINFORMATICS = 'Bioinformatics',
  BIOTECHNOLOGY = 'Biotechnology and Genetics',
  BLOCKCHAIN = 'Blockchain and Cryptocurrency',
  BLOCKCHAIN_FINANCE = 'Blockchain in Finance (DeFi)',
  CARBON_CAPTURE = 'Carbon Capture and Climate Tech',
  CLEANTECH = 'Cleantech',
  CLOUD_COMPUTING = 'Cloud Computing',
  COLD_CHAIN_SOLUTIONS = 'Cold Chain Solutions',
  CONSUMER_ELECTRONICS = 'Consumer Electronics',
  CORPORATE_TRAINING = 'Corporate Training and eLearning',
  CROWD_FUNDING = 'Crowdfunding Platforms',
  CYBER_SECURITY = 'Cyber Security',
  DEFENSE_SYSTEMS = 'Defense Systems and Equipment',
  DIGITAL_BANKING = 'Digital Banking',
  DIGITAL_HEALTH = 'Digital Health and HealthTech',
  DIRECT_TO_CONSUMER = 'Direct-to-Consumer (DTC) Brands',
  DRONES = 'Drones',
  ECOMMERCE = 'E-commerce and Retail Tech',
  EDTECH = 'Education Technology (EdTech)',
  EDUCATION_AND_SKILLING = 'Education and Skilling',
  EDUCATION_MANAGEMENT = 'Education Management Software',
  ELECTRIC_VEHICLES = 'Electric Vehicles (EV) and Batteries',
  ENERGY = 'Energy',
  ENERGY_STORAGE = 'Energy Storage Solutions',
  ENTERPRISE_SAAS = 'Enterprise SaaS',
  ENTERTAINMENT_AND_MEDIA = 'Entertainment & Media',
  ENVIRONMENTAL_MONITORING = 'Environmental Monitoring and Compliance',
  FARM_MANAGEMENT = 'Farm Management Software',
  FINANCE = 'Finance',
  FINTECH = 'Financial Services and FinTech',
  FLEET_MANAGEMENT = 'Fleet Management',
  FOOD_AND_BEVERAGES = 'Food & Beverages',
  FREIGHT_AND_LOGISTICS = 'Freight and Logistics Tech',
  GAMING = 'Gaming',
  GAMING_AND_ESPORTS = 'Gaming and eSports',
  GENERATIVE_AI = 'Generative AI',
  HEALTH_AND_WELLNESS = 'Health and Wellness Products',
  HEALTHCARE = 'Healthcare',
  HEALTHCARE_SERVICES = 'Healthcare Services and Facilities',
  HOTEL_SOLUTIONS = 'Hotel and Accommodation Solutions',
  INDUSTRIAL_IOT = 'Industrial IoT (IIoT)',
  INFRASTRUCTURE = 'Infrastructure',
  INSURANCE_TECH = 'Insurance Technology (InsurTech)',
  INTERNET_OF_THINGS = 'Internet of Things (IoT)',
  K12_SOLUTIONS = 'K-12 and Higher Education Solutions',
  LAST_MILE_DELIVERY = 'Last-Mile Delivery Solutions',
  LIFE_SCIENCES = 'Life Sciences',
  LOYALTY_PROGRAMS = 'Loyalty Programs',
  MANUFACTURING_TECHNOLOGY = 'Manufacturing & Industry 4.0',
  MATERIAL_SCIENCES = 'Material Sciences',
  MEDIA_AND_ENTERTAINMENT = 'Media and Entertainment',
  MEDICAL_DEVICES = 'Medical Devices',
  OMNICHANNEL_RETAIL = 'Omnichannel Retail Solutions',
  ONLINE_LEARNING = 'Online Learning Platforms',
  ONLINE_TRAVEL = 'Online Travel Agencies (OTAs)',
  PAYMENT_SOLUTIONS = 'Payment Solutions',
  PEER_TO_PEER_LENDING = 'Peer-to-Peer Lending',
  PERSONAL_CARE = 'Personal Care and Wellness',
  PHARMACEUTICALS = 'Pharmaceuticals',
  POINT_OF_SALE = 'Point of Sale (POS) Solutions',
  PRECISION_AGRICULTURE = 'Precision Agriculture',
  PROPERTY_FINANCING = 'Property Financing and Lending',
  PROPERTY_MANAGEMENT = 'Property Management Solutions',
  QUANTUM_COMPUTING = 'Quantum Computing',
  REAL_ESTATE = 'Real Estate and PropTech',
  REAL_ESTATE_ANALYTICS = 'Real Estate Analytics',
  REAL_ESTATE_INVESTMENT = 'Real Estate Investment Platforms',
  REGENERATIVE_AGRICULTURE = 'Sustainable Agriculture',
  REG_TECH = 'RegTech (Regulatory Technology)',
  RENEWABLE_ENERGY = 'Renewable Energy (Solar, Wind, Hydro, etc.)',
  RETAIL = 'Retail',
  ROBOTICS = 'Robotics and Automation',
  SKILL_DEVELOPMENT = 'Skill Development Platforms',
  SMART_FACTORIES = 'Smart Factories',
  SMART_HOMES = 'Smart Homes and Buildings',
  SMART_IRRIGATION = 'Smart Irrigation Systems',
  SMART_MANUFACTURING = 'Smart Manufacturing',
  SOCIAL_MEDIA = 'Social Media and Content Creation Tools',
  SPACE_TECHNOLOGY = 'Space Technology and Satellites',
  SPACETECH = 'Spacetech',
  STREAMING_AND_OTT = 'Streaming and OTT Platforms',
  SUBSCRIPTION_SERVICES = 'Subscription Services',
  SUPPLY_CHAIN = 'Supply Chain & Logistics',
  SUSTAINABLE_AGRICULTURE = 'Sustainable Farming Solutions',
  SUSTAINABILITY_AND_ENVIRONMENT = 'Sustainability & Environment',
  TELECOM = 'Telecom',
  TELECOM_SOFTWARE = 'Telecom Software Solutions',
  TELEMEDICINE = 'Telemedicine',
  TEXTILE = 'Textile',
  TOURISM_PLATFORMS = 'Tourism and Experiences Platforms',
  TRAVEL_AND_LEISURE = 'Travel and Leisure',
  UAVS_AND_DRONES = 'UAVs and Drones',
  VACATION_RENTALS = 'Vacation Rental Platforms',
  VIRTUAL_CLASSROOMS = 'Virtual Classrooms',
  WAREHOUSING = 'Warehousing',
  WASTE_MANAGEMENT = 'Waste Management and Recycling',
  WEB3 = 'Web3',
  WEALTH_MANAGEMENT = 'Wealth Management and Robo-Advisors',
}

export enum Services {
  PRODUCT_DEVELOPMENT = 'Product Development',
  LEGAL_AND_COMPLIANCE = 'Legal & compliance',
  MARKETING_AND_BRANDING = 'Marketing & Branding',
  HUMAN_RESOURCES = 'Human Resoucres',
  ACCOUNTING_AND_FINANCE = 'Accounting & Finance',
  COWORKING_AND_FACILITIES = 'Coworking & Facilities',
  HARDWARE_PROCUREMENT_AND_LEASING = 'Hardware Procurement & Leasing'
}

export enum Profession {
  STUDENT = 'Student',
  WORKING_PROFESSIONAL = 'Working Professional',
  OTHER = 'Other'
}

export type TUserTypes = 'User' | 'Company';
