import React, { useEffect } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap';
import cx from './companyProduct.module.scss';
import { dotMenu } from 'src/assets/images';
import CompanyCard from 'src/components/MarketPlace/CompanyCard/CompanyCard';
import { MdAdd } from 'react-icons/md';
import { Button, Loader } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changeCreationModalState, clearMarketPlaceItems, getCommonMarketPlaceItems } from 'src/store/marketplace/marketplace.slice';
import CommonMarketplaceModal from 'src/components/MarketPlace/CommonMarketplaceModal/CommonMarketplaceModal';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { useUrlParamValue } from 'src/hooks';

export default function CompanyProduct({ isViewerProgramCreator = true }: { isViewerProgramCreator?: boolean }) {
    const dispatch = useAppDispatch();
    const { paramValue } = useUrlParamValue();
    const { serviceProviderCommonModal, Product, globalLoading } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    const viewCompanyId = paramValue({ paramName: 'id' }) as unknown as string;
    const handleProductAdd = () => {
        dispatch(changeCreationModalState({ state: true, dataType: 'Product', id: '' }))
    }
    useEffect(() => {
        if (myCompany?.id && !viewCompanyId) {
            const payload = {
                dataType: 'Product',
                companyId: myCompany?.id
            }
            dispatch(getCommonMarketPlaceItems(payload))
        }
    }, [myCompany?.id, dispatch])

    useEffect(() => {
        if (viewCompanyId) {
            const payload = {
                dataType: 'Product',
                companyId: viewCompanyId
            }
            dispatch(getCommonMarketPlaceItems(payload))
        }
    }, [viewCompanyId, dispatch])
    useEffect(() => {
        return () => {
            dispatch(clearMarketPlaceItems({ type: 'Product' }))
            dispatch(changeCreationModalState({ state: false, dataType: '', id: '' }))
        }
    }, [])
    if (globalLoading === 'Product') {
        return <Loader />
    }

    return (
        <>
            {serviceProviderCommonModal.state && <CommonMarketplaceModal />}
            <div className={`${cx.companyProduct}`}>
                <Row>
                    <Col md={12}>
                        <div className={`${cx.productDetails}`}>
                            <h5 className={`${cx.productName}`}>Product</h5>
                            {isViewerProgramCreator && <Button
                                variant="secondary"
                                size="small"
                                className="create-edit-group__buttons__close"
                                onClick={() => handleProductAdd()}
                            >
                                <MdAdd /> Add Product
                            </Button>}
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className={`${cx.serviceRow}`}>
                            <Row>
                                {Product?.map((product, p: number) => {
                                    const { id, title, thumbnailUrl, description, mediaUrl } = product

                                    return (
                                        <Col md={4} key={p}>
                                            <CompanyCard type='Product' isViewerProgramCreator={isViewerProgramCreator} dataType="Product" id={id} url={mediaUrl} name={title} logo={thumbnailUrl} description={description} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}
