import React, { useEffect, useState } from 'react'
import cx from './serviceSubCategoryCard.module.scss';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import TextArea from 'src/components/common/formFields/textArea/TextArea';
import { deleteMarketPlaceService, getMarketPlaceServices, updateServiceComment } from 'src/store/marketplace/marketplace.slice';
import { openPopup } from 'src/store/common/common.slice';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { Button, ConfirmModal, Loader } from 'src/components/common';
import { textEllipsis } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

const initialComment = {
    state: false,
    value: ''
}
export default function ServiceSubCategoryCard({ title, id, isViewerProgramCreator = true, comment }: { title: string, id: string, isViewerProgramCreator?: boolean, comment?: string }) {
    // hook 
    const dispatch = useAppDispatch();
    const {
        myCompany
    }: {
        myCompany: TGetCompany;
    } = useAppSelector((store) => store.company);
    const { globalLoading } = useAppSelector((state) => state.marketPlace);
    // state 
    const [openConfirmation, setOpenConfirmationModal] = useState<boolean>(false);
    const [commentAction, setCommentAction] = useState<{ state: boolean, value: string }>(initialComment)

    // Utils
    const nameWitEllipsis = textEllipsis({ text: title, charLimit: 18 });

    // handler
    async function handleDelete() {
        if (id) {
            const response = await dispatch(deleteMarketPlaceService({ serviceId: id, companyId: myCompany?.id }))
            if (response.status) {
                dispatch(
                    openPopup({
                        popupMessage: response.message,
                        popupType: 'success'
                    })
                );
                dispatch(getMarketPlaceServices({ companyId: myCompany?.id }))
            } else {
                dispatch(
                    openPopup({
                        popupMessage: `Service is not deleted`,
                        popupType: 'error'
                    })
                );
            }
        }
    }
    function handleComment() {
        setCommentAction({ ...commentAction, state: true })
    }
    function handleCancel() {
        setCommentAction({ state: false, value: comment })
    }
    async function handleSubmit() {
        const payload = {
            companyId: myCompany.id,
            comments: commentAction.value,
            id
        }
        const response = await dispatch(updateServiceComment(payload))
        if (response.status) {
            setCommentAction({ ...commentAction, state: false })
        }
    }
    useEffect(() => {
        if (comment) {
            setCommentAction({ ...commentAction, value: comment })
        }
    }, [comment])
    return (
        <>
            {/* {serviceProviderCommonModal.state && <AssignServiceModal />} */}
            <ConfirmModal
                openConfirmModal={openConfirmation}
                title={'Are you sure you want to remove this Service'}
                description=""
                onClose={() => {
                    setOpenConfirmationModal(false);
                }}
                onSubmit={handleDelete}
                submitText="Confirm"
                type="error"
            />
            <div className={`${cx.subCategoryCard}`}>
                <div className={`${cx.header}`}>
                    <h5 title={title}>{nameWitEllipsis?.text}</h5>
                    { isViewerProgramCreator && <div className={`${cx.actions}`}>
                        <EditIcon className={cx.icons} onClick={handleComment} />
                        <TrashIcon onClick={() => setOpenConfirmationModal(true)} />
                    </div> }
                </div>
                <div className={`${cx.content}`}>
                    <TextArea textAreaStyle={{ width: '100%' }} disabled={!commentAction.state} onChange={(e) => setCommentAction({ ...commentAction, value: e.target.value })} value={commentAction.value} />
                </div>
                {commentAction.state && <div className='d-flex gap-2'>
                    <Button variant='primary' style={{ width: 'auto', fontSize: '12px', height: '34px', marginTop: "6px" }} size='small' onClick={handleSubmit}>
                        { globalLoading === 'update-comment' ? <Loader /> : 'Update' }
                    </Button>
                    <Button variant='primary' style={{ width: 'auto', fontSize: '12px', height: '34px', marginTop: "6px" }} size='small' onClick={handleCancel}>
                        Cancel
                        </Button>
                </div>}
            </div>
        </>
    )
}
