import { HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import './leftMenu.scss';

import Typography from '../typography/Typography';
import { Link } from 'react-router-dom';

export type TLeftMenuState = 'default' | 'active' | 'disabled';

export type TLeftMenu = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  state?: TLeftMenuState;
  notificationCount?: number;
  url?: string;
} & HTMLAttributes<HTMLDivElement>;

const LeftMenu = ({
  startIcon,
  endIcon,
  children,
  state = 'default',
  notificationCount = 0,
  className,
  url,
  ...rest
}: TLeftMenu) => {
  const isActive = state === 'active';
  const isDisabled = state === 'disabled';
  return (
    <>
      {url ? (
        <Link to={url} style={{ color: 'inherit', textDecoration: 'none' }}>
          <div
            className={classNames(
              'left-menu',
              {
                'left-menu--is-active': isActive,
                'left-menu--is-disabled': isDisabled
              },
              className
            )}
            {...rest}
          >
            <div className="left-menu__content">
              {startIcon && (
                <div
                  className={classNames('left-menu__content__icon', {
                    'left-menu__content__icon--is-active': isActive,
                    'left-menu__content__icon--is-disabled': isDisabled
                  })}
                >
                  {startIcon}
                </div>
              )}
              <Typography variant="body-2" fontWeight="regular" className="left-menu__content__title">
                {children}
              </Typography>
              {endIcon && <div className="left-menu__content__end-icon">{endIcon}</div>}
            </div>
            {!isDisabled && notificationCount > 0 && (
              <div className="left-menu__notifications">
                <Typography as="span" variant="caption" fontWeight="semiBold">
                  {notificationCount}
                </Typography>
              </div>
            )}
          </div>
        </Link>

      ) : (
        <div
          className={classNames(
            'left-menu',
            {
              'left-menu--is-active': isActive,
              'left-menu--is-disabled': isDisabled
            },
            className
          )}
          {...rest}
        >
          <div className="left-menu__content">
            {startIcon && (
              <div
                className={classNames('left-menu__content__icon', {
                  'left-menu__content__icon--is-active': isActive,
                  'left-menu__content__icon--is-disabled': isDisabled
                })}
              >
                {startIcon}
              </div>
            )}
            <Typography variant="body-2" fontWeight="regular" className="left-menu__content__title">
              {children}
            </Typography>
            {endIcon && <div className="left-menu__content__end-icon">{endIcon}</div>}
          </div>
          {!isDisabled && notificationCount > 0 && (
            <div className="left-menu__notifications">
              <Typography as="span" variant="caption" fontWeight="semiBold">
                {notificationCount}
              </Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default LeftMenu;
