import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import cx from './toggleBox.module.scss'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import Button from 'src/components/button/Button';
import { toggleBoxModalStateChange } from 'src/store/company/company.slice';
import { TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { changeTabSetting } from 'src/store/marketplace/marketplace.slice';
import Loader from '../loader/Loader';

type TSetting = { setting: string, state: boolean }

const initialState = [
    {
        setting: 'Service',
        state: false

    },
    {
        setting: 'Product',
        state: false
    },
    {
        setting: 'Portfolio',
        state: false
    },
    {
        setting: 'Event',
        state: false
    }

]
export default function ToggleBox() {
    const { settings, globalLoading } = useAppSelector((state) => state.marketPlace);
    const {
        myCompany
    }: {
        myCompany: TGetCompany
    } = useAppSelector((store) => store.company);
    const dispatch = useAppDispatch();

    const [togalBoxState, setTogalBoxState] = useState<TSetting[]>(initialState)

    function handleClose() {
        dispatch(toggleBoxModalStateChange({ state: false, data: null }))
    }
    function handleChangeSetting(e) {
        const checked = e.target.checked;
        const name = e.target.id
        const key = `isMarketPlace${name}TabEnabled`;
        const payload = {
            userId: myCompany.id,
            [key]: checked,
            key: name
        }
        dispatch(changeTabSetting(payload));
    }

    useEffect(() => {
        if (settings) {
            const modifiedSetting: TSetting[] = []
            Object.keys(settings)?.forEach((key: string) => {
                if (typeof settings[key] === 'boolean') {
                    const modifiedKey = key?.replace("isMarketPlace", '').replace('TabEnabled', '');
                    modifiedSetting.push({ setting: modifiedKey, state: settings[key] })
                }
            })
            setTogalBoxState(modifiedSetting);
        }
    }, [settings])
    return (
        <div className={`${cx.toggleBox}`}>
            <ul className={`${cx.itemList}`}>
                {togalBoxState?.map((item) => {
                    const { state, setting } = item
                    return (
                        <li key={item.setting}>
                            <span>{item.setting}</span>
                            {globalLoading === setting && <Loader />}
                            {globalLoading !== setting && <ToggleSwitch id={setting} color={state ? '#fa4616' : '#bbb6b2'} checked={state} onChange={handleChangeSetting} />}
                        </li>
                    )
                })}
            </ul>
            <Button
                variant="secondary"
                size="small"
                onClick={handleClose}
            >
                Close
            </Button>
        </div>
    )
}
