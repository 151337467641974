import axios from 'axios';
import { APIResponse, BaseAPIService } from './base.api.service';

export class MarketPlaceApiServices extends BaseAPIService {
    private marketPlaceItemPath: string = '/marketPlace/item';
    private marketPlaceSearchItemPath: string = '/marketPlace/search';
    private itemPath: string = '/marketPlace/company-profile/item';
    private globalServicePath: string = '/marketPlace/company-profile/global-service';
    private addServicePath: string = '/marketPlace/company-profile/service/add';
    private updateServicePath: string = '/marketPlace/company-profile/service/edit';
    private deleteServicePath: string = '/marketPlace/company-profile/service/remove';
    private getServicePath: string = '/marketPlace/company-profile/service/get';
    private maintainOrderPath: string = '/market-place/maintain-order';
    private uploadMedeaPath: string = '/marketPlace/uploads';
    private settingTabPath: string = '/marketPlace/setting';
    private servicewiseCompanyPath: string = '/marketPlace/companies/list';
    constructor() {
        super();
        this.axiosInstance = axios.create({ baseURL: process.env.REACT_APP_MARKET_PLACE_ENDPOINT });
        this.configureInterceptors();
    }

    public async createItem(
        createItemRequest: any
    ): Promise<APIResponse<any>> {
        const createItemResponse: APIResponse<any> = await this.post(
            this.itemPath,
            createItemRequest,
            true
        );
        return createItemResponse;
    }

    public async getGlobalServices(
        marketglobalServiceRequest: any
    ): Promise<APIResponse<any>> {
        const marketglobalServiceResponse: APIResponse<any> = await this.get(
            this.globalServicePath,
            true,
            marketglobalServiceRequest
        );
        return marketglobalServiceResponse;
    }

    public async addService(
        addServiceRequest: any
    ): Promise<APIResponse<any>> {
        const addServiceResponse: APIResponse<any> = await this.post(
            this.addServicePath,
            addServiceRequest,
            true
        );
        return addServiceResponse;
    }

    public async updateService(
        updateServiceRequest: any
    ): Promise<APIResponse<any>> {
        const updateServiceResponse: APIResponse<any> = await this.patch(
            this.updateServicePath,
            updateServiceRequest,
            true
        );
        return updateServiceResponse;
    }

    public async changeSetting(
        settingRequest: any
    ): Promise<APIResponse<any>> {
        const settingResponse: APIResponse<any> = await this.post(
            this.settingTabPath,
            settingRequest,
            true
        );
        return settingResponse;
    }

    public async getServices(
        getServiceRequest: any
    ): Promise<APIResponse<any>> {
        const getServiceResponse: APIResponse<any> = await this.get(
            this.getServicePath,
            true,
            getServiceRequest
        );
        return getServiceResponse;
    }

    public async getMarketPlaceItems(
        marketPlaceItemsRequest: any
    ): Promise<APIResponse<any>> {
        const marketPlaceItemsResponse: APIResponse<any> = await this.get(
            this.itemPath,
            true,
            marketPlaceItemsRequest
        );
        return marketPlaceItemsResponse;
    }

    public async getMarketPlaceItemsSearch(
        marketPlaceItemsSearchRequest: any
    ): Promise<APIResponse<any>> {
        const marketPlaceItemsSearchResponse: APIResponse<any> = await this.get(
            this.marketPlaceSearchItemPath,
            true,
            marketPlaceItemsSearchRequest
        );
        return marketPlaceItemsSearchResponse;
    }

    public async maintainOrder(
        orderMaintainRequest: any
    ): Promise<APIResponse<any>> {
        const orderMaintainResponse: APIResponse<any> = await this.patch(
            this.maintainOrderPath,
            orderMaintainRequest,
            true
        );
        return orderMaintainResponse;
    }

    public async getMarketPlace(
        marketPlaceRequest: any
    ): Promise<APIResponse<any>> {
        const marketPlaceResponse: APIResponse<any> = await this.get(
            this.marketPlaceItemPath,
            true,
            marketPlaceRequest
        );
        return marketPlaceResponse;
    }
    
    public async getCompanyViaCatService(
        companiesRequest: any
    ): Promise<APIResponse<any>> {
        const companiesResponse: APIResponse<any> = await this.get(
            this.servicewiseCompanyPath,
            true,
            companiesRequest
        );
        return companiesResponse;
    }

    public async deleteMarketPlaceItem(
        deleteMarketPlaceRequest: any
    ): Promise<APIResponse<any>> {
        const deleteMarketPlaceResponse: APIResponse<any> = await this.deleteWithParams(
            this.itemPath,
            true,
            deleteMarketPlaceRequest
        );
        return deleteMarketPlaceResponse;
    }

    public async deleteService(
        deleteServiceRequest: any
    ): Promise<APIResponse<any>> {
        const deleteServiceResponse: APIResponse<any> = await this.deleteWithParams(
            this.deleteServicePath,
            true,
            deleteServiceRequest
        );
        return deleteServiceResponse;
    }

    public async updateMarketPlaceItem(
        updateMarketPlaceItemRequest: any
    ): Promise<APIResponse<any>> {
        const updateMarketPlaceItemResponse: APIResponse<any> = await this.patch(
            this.itemPath,
            updateMarketPlaceItemRequest,
            true
        );
        return updateMarketPlaceItemResponse;
    }

    public async getPresignedUrl(
        presignedRequest: any
    ): Promise<APIResponse<any>> {
        const presignedResponse: APIResponse<any> = await this.get(
            this.uploadMedeaPath,
            true,
            presignedRequest
        );
        return presignedResponse;
    }
}
