import React, { useEffect, useState } from 'react';
import cx from './teams.module.scss';
import { Col, Row, Dropdown } from 'react-bootstrap';
import { dotMenu } from 'src/assets/images';
import { Loader, ProfilePicture, Typography, ConfirmModal } from 'src/components/common';
import { useUrlParamValue, useToggleOpenModal } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  cohortTeamList,
  removeTeam,
  resetMilestoneDataSuccess
} from 'src/store/cohort/cohort.slice';
import { AppDispatch } from 'src/store/store';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TrashIcon } from 'src/components/common/common.icons';

const confirmDeleteServiceModalId = 'confirmTaskModal';

export default function Teams({ handleClick }: any) {
  const { paramValue } = useUrlParamValue();
  const cohortId: string = paramValue({ paramName: 'id' }) as string;
  const incubatorId: string = paramValue({ paramName: 'companyId' }) as string;
  const { cohortTeam, globalLoading } = useSelector((state: any) => state.cohort);
  const [teamId, setTeamId] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const modalId = useAppSelector((store: any) => store.common.modalId);
  const isConfirmDeleteServiceModalOpen = modalId === confirmDeleteServiceModalId;
  const toggleOpenModal = useToggleOpenModal();

  const handleDeleteService = () => {
    dispatch(
      removeTeam({
        cohortId,
        incubatorId,
        teamIds: [teamId],
        status: 'Deactivated'
      })
    );
  };

  const toggleOpenDeleteServiceModal = () => {
    toggleOpenModal({ modalId: confirmDeleteServiceModalId });
  };

  useEffect(() => {
    if (cohortId && cohortTeamList.length < 1) {
      dispatch(resetMilestoneDataSuccess());
      dispatch(cohortTeamList({ cohortId, incubatorId }));
    }
  }, [cohortId]);

  if (globalLoading === 'mentor-team') {
    return <Loader />;
  }

  return (
    <div className={cx.teams}>
      <Row>
        <Col md={12}>
          <div className="insideCard transparent">
            <div className={cx.chatBox}>
              {/* <h5 className={`${cx.chatHeading}`}>Teams / Startups</h5> */}
              <Row>
                {cohortTeam?.map((team: any) => {
                  const isDeactivated = team.status === 'Deactivated'; // Check if the team is deactivated

                  return (
                    <Col md={6} key={team.id} className={cx.TeamColumn}>
                      <div
                        className={`${cx.teamCard} ${isDeactivated ? cx.deactivatedTeam : ''}`} // Apply class for deactivated status
                        onClick={() => handleClick('Relationship', { key: 'teamId', id: team?.id })}
                      >
                        <ProfilePicture
                          key={team.id}
                          url={team?.profilePic}
                          fallback={team.cohortTeamsTitle?.[0]?.toLocaleUpperCase()}
                        />
                        <div>
                          <Typography as="p" variant="body-2" fontWeight="semiBold">
                            {team.cohortTeamsTitle}
                          </Typography>
                          <Typography as="p" variant="caption" fontWeight="regular">
                            {team.onboardingTo}
                          </Typography>
                          {isDeactivated && (
                            <Typography
                              as="p"
                              variant="caption"
                              fontWeight="regular"
                              className={cx.deactivatedText}
                            >
                              Deactivated
                            </Typography>
                          )}
                        </div>
                      </div>
                      {!isDeactivated && (
                        <div className={cx.TrashIcon}>
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic-6"
                              style={{
                                background: 'none',
                                border: 'none',
                                height: '10px',
                                width: '10px'
                              }}
                            >
                              <img src={dotMenu} className={cx.dots} alt="dot" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* <Dropdown.Item href="#">
                <p className={cx.dotDropInside}>
                  <span>Delete</span>
                </p>
              </Dropdown.Item> */}
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  toggleOpenDeleteServiceModal(); // Open the delete modal
                                  setTeamId(team.id); // Set the team ID for deletion
                                }}
                              >
                                <p className={cx.dotDropInside} style={{ margin: '0' }}>
                                  <span style={{ margin: '0' }}>deactivate</span>
                                </p>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </Col>
                  );
                })}
                {cohortTeam?.length === 0 && (
                  <div>
                    <p className={cx.emptyBox}>No Team Added Yet</p>
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>{/* Group Chat and Upcoming Events columns */}</Col>
      </Row>

      {isConfirmDeleteServiceModalOpen && (
        <ConfirmModal
          openConfirmModal={isConfirmDeleteServiceModalOpen}
          onClose={toggleOpenDeleteServiceModal}
          title="Are you sure you want to Remove this Team From Cohort?"
          type="error"
          onSubmit={handleDeleteService}
          submitText="Remove"
        />
      )}
    </div>
  );
}
