import ServiceSection from './ServiceSection/ServiceSection';
import cx from './marketplace.module.scss';
import TopCompanies from './TopCompanies/TopCompanies';
import UpcomingEvents from './UpcomingEvents/UpcomingEvents';
import TopProduct from './TopProduct/TopProduct';

export default function MarketPlaceSection() {
  return (
    <div className={`${cx.sections}`}>
      <TopCompanies gb={cx} />
      <ServiceSection gb={cx} />
      <TopProduct gb={cx} />
      <UpcomingEvents gb={cx}/>
    </div>
  )
}
