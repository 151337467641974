import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Card, Loader, Typography } from 'src/components/common';
import { PlusIcon, SingleChevronIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import { useToggleCreateEditGroupModal, useToggleManageGroupModalModal } from 'src/components/chat';
import { TCreateIdeaGroupActionRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { createIdeaGroup, listIdeaChats } from 'src/store/chat/chat.slice';
import { useGetIdentity } from 'src/hooks';
import ManageIdeaGroupMembers from 'src/components/chat/manageIdeaGroupMembers/ManageIdeaGroupMembers';
import { TListIdeaChatsRequest } from 'src/services/apiEndpoint.types.ts/chat/listIdeaChats.api.endpoint.types';
import { TDiscussionTypes } from 'src/components/chat/chat.type';

import { TDiscussionListProps } from './discussionList.types';
import './discussionList.scss';

import DiscussionGroupItem from '../discussionGroupItem/DiscussionListGroupItem';
import DiscussionUserItem from '../discussionUserItem/DiscussionUserItem';
import CreateEditGroup from '../createEditGroup/CreateEditGroup';
const DiscussionList = ({
  discussionType,
  groupId,
  groups,
  ideaId,
  users,
  selectedUserId,
  ideaOwnerId,
  ideaCoFounderIds
}: TDiscussionListProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getIdentities } = useGetIdentity();
  const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
    useToggleCreateEditGroupModal({ mode: 'Add' });
  const { toggleManageGroupModalModal, isToggleManageGroupModalModalOpen } =
    useToggleManageGroupModalModal();
  const { getChatGroupsLoader, ideaGroupParticipants } = useAppSelector((store) => store.chat);

  const [expandChatsType, setExpandChatsType] = useState<'groups' | 'directMessages' | ''>(
    'groups'
  );

  // Constants
  const expandGroups: boolean = expandChatsType === 'groups';
  const expandDirectMessages: boolean = expandChatsType === 'directMessages';
  const { loggedInUserId, loggedInUserType, authenticatedId } = getIdentities();
  const isViewOwner = loggedInUserId === ideaOwnerId;

  const handleCreateGroup = (formValues: { groupName: string; usersToAdd: string[] }) => {
    const usersToAdd: string[] = formValues?.usersToAdd;

    const ownerIndex = usersToAdd?.findIndex((user) => user === loggedInUserId);

    if (ownerIndex > -1) {
      // Remove the owner from the array
      usersToAdd.splice(ownerIndex, 1);
    }
    // Adding the owner as the first item in the array.
    usersToAdd.unshift(loggedInUserId);

    const payload: TCreateIdeaGroupActionRequest = {
      ideaId,
      groupName: formValues?.groupName,
      usersToAdd: usersToAdd.map((userId) => ({ userId, userType: 'User' })),
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(createIdeaGroup(payload));
  };

  const redirectToFirstGroup = () => {
    const chatType: TDiscussionTypes = 'ideaGroup';
    try {
      const firstGroup = groups[0];
      navigate(
        `/view-idea?id=${firstGroup?.ideaId}&tab=discussion&chatType=${chatType}&groupId=${firstGroup?.id}&groupName=${firstGroup?.groupName}`
      );
    } catch {}
  };

  const fetchListIdeaChats = () => {
    const payload: TListIdeaChatsRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      ideaId
    };

    dispatch(listIdeaChats(payload));
  };

  useEffect(() => {
    if (!groupId && groups?.length > 0) {
      redirectToFirstGroup();
    }
  }, [groups]);

  useEffect(() => {
    if (ideaId) {
      fetchListIdeaChats();
    }
  }, [ideaId]);

  return (
    <Card className="discussion-list">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        Discussion
      </Typography>
      {isViewOwner && (
        <Button
          variant="secondary"
          size="small"
          startIcon={<PlusIcon />}
          className="discussion-list__groups__body__create-group"
          onClick={toggleCreateEditGroupModal}
        >
          Create Group
        </Button>
      )}

      {/* chat */}
      <div className="discussion-list__groups">
        <div
          className="discussion-list__groups__header"
          onClick={() => setExpandChatsType((prev) => (prev !== 'groups' ? 'groups' : ''))}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            My Groups
          </Typography>
          <ActionIcon>
            <SingleChevronIcon direction={expandGroups ? 'up' : 'down'} />
          </ActionIcon>
        </div>
        <div className="discussion-list__groups__body">
          {getChatGroupsLoader && <Loader />}
          {expandGroups && (
            <div className="discussion-list__groups__body__list">
              {groups.map((group, index) => (
                <DiscussionGroupItem
                  key={index}
                  group={group}
                  groupId={groupId}
                  discussionType={discussionType}
                  ideaId={ideaId}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="discussion-list__groups">
        {/* <div
          className="discussion-list__groups__header"
          onClick={() =>
            setExpandChatsType((prev) => (prev !== 'directMessages' ? 'directMessages' : ''))
          }
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Direct Messages
          </Typography>
          <ActionIcon>
            <SingleChevronIcon direction={expandDirectMessages ? 'up' : 'down'} />
          </ActionIcon>
        </div> */}
        <div className="discussion-list__groups__body">
          {expandDirectMessages && (
            <div className="discussion-list__groups__body__list">
              {users.map((user, index) => (
                <DiscussionUserItem
                  key={index}
                  user={user}
                  selectedUserId={selectedUserId}
                  discussionType={discussionType}
                  ideaId={ideaId}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {isToggleCreateEditGroupModalOpen && (
        <CreateEditGroup
          mode="Add"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          selectedCollaborators={[]}
          onSubmit={handleCreateGroup}
        />
      )}
      {isToggleManageGroupModalModalOpen && (
        <ManageIdeaGroupMembers
          userId={authenticatedId}
          userType={loggedInUserType}
          ideaId={ideaId}
          groupId={groupId}
          isModalOpen={isToggleManageGroupModalModalOpen}
          onModalClose={toggleManageGroupModalModal}
          groupMembers={ideaGroupParticipants?.[0]}
        />
      )}
    </Card>
  );
};

export default DiscussionList;
